import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useClickAway, useSetState } from 'react-use';
import { format } from 'date-fns';

import {
  selectCurrentAlbum,
  selectAlbumData,
  selectIsAlbumEditable,
} from '../../../../selectors/albums';
import { setAlbumData } from '../../../../modules/albums';
import useApi from '../../../../hooks/useApi';
import ButtonWithTooltip from '../../../menu/ButtonWithTooltip';
import { snakeify } from '../../../../util';
import useNotifications from '../../../../hooks/useNotifications';
import useAnalytics from '../../../../containers/app/useAnalytics';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import SyncStatusIndicator from './SyncStatusIndicator';
import useLocale from '../../../../hooks/localization/useLocale';

const editableFields = {
  title: 'title',
  releaseDate: 'releaseDate',
};

function AlbumTitle() {
  const { t } = useLocale();
  const [editing, setEditing] = useState(undefined);
  const { title, releaseDate } = useSelector(selectAlbumData);
  const [localFields, setLocalFields] = useSetState({ title, releaseDate });
  const remoteFields = { title, releaseDate };
  const titleNotValid = localFields.title.length === 0;
  const { loadWhile } = useLoading(commonTypes.patchTitleReleaseDate);
  const { createError } = useNotifications();

  const titleInputRef = useRef();
  const releaseDateInputRef = useRef();
  const albumId = useSelector(selectCurrentAlbum);
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  const dispatch = useDispatch();
  const api = useApi();
  const analytics = useAnalytics();

  function handleClick(field, callback) {
    setEditing(editableFields[field]);
    setTimeout(() => {
      return callback && callback();
    }, 0);
  }

  function handleSubmit() {
    if (titleNotValid) {
      return;
    }

    setEditing(undefined);

    const changes = Object.entries(localFields).filter(
      ([key, value]) => remoteFields[key] !== value
    );

    if (changes.length === 0) {
      return;
    }

    dispatch(setAlbumData(localFields));

    loadWhile(async () => {
      try {
        await api.patch(`/albums/${albumId}`, snakeify(localFields));
      } catch (error) {
        createError(t('genericError'));
      }
    });

    analytics.track('Album Updated', localFields);
  }

  useClickAway(titleInputRef, handleSubmit);
  useClickAway(releaseDateInputRef, handleSubmit);

  useEffect(() => {
    setLocalFields({ title, releaseDate });
  }, [title, releaseDate, setLocalFields]);

  return (
    <>
      <div className="album-title qa-album-title">
        {editing === editableFields.title && (
          <Form onSubmit={handleSubmit} className="w-100">
            <Form.Control
              isInvalid={titleNotValid}
              required
              ref={titleInputRef}
              value={localFields.title}
              onChange={({ target: { value } }) =>
                setLocalFields({ title: value })
              }
              placeholder={t('editor.albumTitle.titlePlaceholder')}
              type="text"
              name="album_title_input"
              disabled={!isAlbumEditable}
            />
          </Form>
        )}
        {editing === editableFields.releaseDate && (
          <Form onSubmit={handleSubmit} className="w-100">
            <Form.Control
              ref={releaseDateInputRef}
              value={localFields.releaseDate || ''}
              onChange={({ target: { value } }) =>
                setLocalFields({ releaseDate: value })
              }
              placeholder={t('editor.albumTitle.releaseDate')}
              type="date"
              name="album_release_date_input"
              className="qa-album-release-date-input"
              disabled={!isAlbumEditable}
            />
          </Form>
        )}
        {!editing && (
          <>
            <Button
              onClick={() =>
                handleClick(editableFields.title, () =>
                  titleInputRef.current.focus()
                )
              }
              id="albumTitleEditButton"
              variant="link"
              size="lg"
              className="p-0 d-flex align-items-center text-dark qa-album-title-btn"
            >
              {localFields.title?.substring(0, 20)}
              {localFields.title?.length > 20 && '...'}
              <SyncStatusIndicator />
            </Button>
            <ButtonWithTooltip
              className="bg-white qa-album-release-date-btn"
              tooltip={t('editor.albumTitle.releaseDateTooltip')}
              icon={!localFields.releaseDate ? 'calendar' : null}
              onClick={() =>
                handleClick(editableFields.releaseDate, () =>
                  releaseDateInputRef.current.focus()
                )
              }
            >
              {localFields.releaseDate &&
                format(new Date(localFields.releaseDate), 'd.M.yyyy')}
            </ButtonWithTooltip>
          </>
        )}
      </div>
    </>
  );
}

AlbumTitle.defaultProps = {};

AlbumTitle.propTypes = {};

export default AlbumTitle;
