import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import { getSelectedBlocksMap } from 'draftjs-utils';
import Immutable from 'immutable';
import { batch } from 'react-redux';

import { zoomToSelection, exitZoomToSelection } from '../actions/viewport';
import { itemTypes } from '../constants';
import {
  getLockedElementIds,
  getSelectedTextElements,
  getSingleSelectedElement,
  getTargetNodeId,
  getWorkspace,
} from '../selectors/legacy';
import { getEditorState, getSelectInside } from '../selectors/selection';
import { createSelectionStateWithAllTextSelected } from '../util/draftjs';
import { historyAnchor } from './history';

export const REPLACE = 'selection/REPLACE';
export const CLEAR = 'selection/CLEAR';
export const SELECT_INSIDE = 'selection/SELECT_INSIDE';
export const SELECT = 'selection/SELECT';
export const EDITOR_CHANGE = 'selection/EDITOR_CHANGE';
export const UPDATE = 'selection/UPDATE';

/* This action should naturally be in 'actions/workspace'. But since importing from there introduces a circular
dependency (actions/workspace.js > modules/selection.js > modules/draft.js), we create the action here. */
const updateElementText = (id, text) => ({
  type: 'workspace/UPDATE_ELEMENTS',
  payload: {
    propsDeltaMap: { [id]: { text } },
  },
});

export const initialState = Object.keys(itemTypes).reduce(
  (acc, cur) => {
    acc[itemTypes[cur]] = [];
    return acc;
  },
  {
    selectInside: false,
    editorState: null,
    lassoSpreadIndex: null,
    textEditActive: false,
  }
);

function buildEditorStateFromNode(node) {
  const {
    props: { text },
  } = node;

  const contentState = convertFromRaw(text);

  return EditorState.acceptSelection(
    EditorState.createWithContent(contentState),
    createSelectionStateWithAllTextSelected(contentState)
  );
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REPLACE:
      return action.payload;
    case CLEAR:
      return {
        ...state,
        ...initialState,
        editorState: null,
        textEditActive: false,
      };
    case SELECT_INSIDE: {
      const { selectInside, editorState } = action.payload;
      return { ...state, selectInside, editorState };
    }
    case SELECT: {
      const { what, ids, selectInside = false } = action;
      let { editorState } = action;
      return {
        ...state,
        [what]: ids,
        selectInside,
        editorState,
        textEditActive: false,
      };
    }
    case EDITOR_CHANGE: {
      return { ...state, editorState: action.payload };
    }
    case UPDATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const setSelectInside = selectInside => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);

  const { [itemTypes.element]: ids } = state.selection;
  const singleNode = ids.length === 1 && nodes[ids[0]];
  const singleTextNode = singleNode?.type === 'Text' && singleNode;
  const editorState =
    singleTextNode && selectInside
      ? buildEditorStateFromNode(singleTextNode)
      : null;

  dispatch({ type: SELECT_INSIDE, payload: { selectInside, editorState } });
};

export const setTextEditActive = textEditActive => ({
  type: UPDATE,
  payload: { textEditActive },
});

export const elementSelect = ids => (dispatch, getState) => {
  const state = getState();
  const lockedIds = getLockedElementIds(state);
  const { nodes } = getWorkspace(state);
  const singleNode = ids.length === 1 && nodes[ids[0]];
  const singleTextNode = singleNode?.type === 'Text' && singleNode;

  // Keep the selectInside-flag if a single element is selected.
  // This allows quicker switching between text-elements:
  const { selectInside: lastSelectInside } = state.selection;
  const selectInside =
    lastSelectInside && !!singleTextNode && !singleTextNode.props.symbol;

  const editorState =
    singleTextNode && selectInside
      ? buildEditorStateFromNode(singleTextNode)
      : null;

  batch(() => {
    dispatch(exitZoomToSelection());
    dispatch({
      type: SELECT,
      what: itemTypes.element,
      ids: ids.filter(id => lockedIds.indexOf(id) === -1),
      selectInside,
      editorState,
    });
  });
};

export const spreadSelect = ids => dispatch => {
  dispatch({ type: SELECT, what: itemTypes.spread, ids });
};

function limitToExistingIds(selection, { nodes }) {
  const availiableNodeIds = Object.keys(nodes);
  const filterIds = ids => ids.filter(id => availiableNodeIds.includes(id));
  return {
    ...selection,
    [itemTypes.element]: filterIds(selection[itemTypes.element]),
    [itemTypes.spread]: filterIds(selection[itemTypes.spread]),
  };
}

const replace = payload => ({ type: REPLACE, payload });

export const withSelection = (tempSelection, restoreSelection, callback) => (
  dispatch,
  getState
) => {
  const { selection: currentSelection } = getState();
  dispatch(replace(tempSelection));
  callback();
  if (restoreSelection) {
    const newWorkspace = getWorkspace(getState());
    dispatch(replace(limitToExistingIds(currentSelection, newWorkspace)));
  }
};

export const clearSelection = () => dispatch =>
  batch(() => {
    dispatch(exitZoomToSelection());
    dispatch({ type: CLEAR });
  });

export const selectAll = () => (dispatch, getState) => {
  const state = getState();

  const { nodes } = getWorkspace(state);
  const ids = nodes[getTargetNodeId(state)].children;
  dispatch(elementSelect(ids));
};

export const setEditorState = editorState => (dispatch, getState) => {
  const state = getState();
  const element = getSingleSelectedElement(state);
  const text = convertToRaw(editorState.getCurrentContent());
  batch(() => {
    dispatch({
      type: EDITOR_CHANGE,
      payload: editorState,
    });
    dispatch(updateElementText(element.props.id, text));
  });
};

const mapAllEditorStates = callback => (dispatch, getState) => {
  const state = getState();
  const editorState = getEditorState(state);
  if (editorState) {
    // when editing a single element, always keep the focus
    const focusedEditorState = EditorState.acceptSelection(
      editorState,
      editorState.getSelection().merge({ hasFocus: true })
    );
    const nextState = callback(focusedEditorState);
    dispatch(setEditorState(nextState));
  } else {
    const selectedElements = getSelectedTextElements(state);
    selectedElements.forEach(element => {
      const contentState = convertFromRaw(element.props.text);
      const elementEditorState = EditorState.createWithContent(contentState);
      const selectionState = createSelectionStateWithAllTextSelected(
        contentState
      );
      const elementEditorStateWithSelection = EditorState.acceptSelection(
        elementEditorState,
        selectionState
      );
      const nextEditorState = callback(elementEditorStateWithSelection);
      const text = convertToRaw(nextEditorState.getCurrentContent());
      dispatch(updateElementText(element.props.id, text));
    });
  }
};

export const setInlineStyle = inlineStyle => (dispatch, getState) => {
  dispatch(
    mapAllEditorStates(originalEditorState => {
      let editorState = originalEditorState;

      const selection = editorState.getSelection();

      // set inline styles
      const currentStyle = editorState.getCurrentInlineStyle();
      const inlineStyleList = inlineStyle.split('-');
      if (inlineStyleList.length >= 2) {
        const prefix = `${inlineStyleList[0]}-`;
        currentStyle.forEach(style => {
          if (style.indexOf(prefix) === 0) {
            editorState = RichUtils.toggleInlineStyle(editorState, style);
          }
        });
      }
      editorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

      // save styles relevant for line height calculation also in block styles:
      const key = inlineStyleList[0];

      if (['SIZE', 'LINEHEIGHT', 'FONT', 'ALIGN'].includes(key)) {
        // first merge all selected block styles...
        const allSelectedBlockStyles = getSelectedBlocksMap(editorState).reduce(
          (acc, cur) => acc.merge(cur.getData()),
          Immutable.Map()
        );

        // ... limit them to line height relevant styles
        const relevantSelectedStyles = allSelectedBlockStyles.filter(
          (_, styleKey) => ['SIZE', 'LINEHEIGHT', 'FONT'].includes(styleKey)
        );

        // ... and set the current one
        const mergedStyles = relevantSelectedStyles.set(key, inlineStyle);

        let contentState = editorState.getCurrentContent();

        contentState = Modifier.mergeBlockData(
          contentState,
          selection,
          mergedStyles
        );

        // revert all block types to "unstyled" (block types were used for aligning in older versions)
        contentState = Modifier.setBlockType(
          contentState,
          selection,
          'unstyled'
        );

        editorState = EditorState.push(
          editorState,
          contentState,
          'add-block-data'
        );
      }

      return editorState;
    })
  );

  /**
   * We only set a history anchor if the styling was applied to
   * the text area as a whole. Otherwise, draftjs' undo history
   * will pick it up.
   */
  const selectInside = getSelectInside(getState());
  if (!selectInside) {
    dispatch(historyAnchor());
  }
};

export const setLassoSpreadIndex = lassoSpreadIndex => ({
  type: UPDATE,
  payload: { lassoSpreadIndex },
});

export const editText = () => dispatch =>
  batch(() => {
    dispatch(setSelectInside(true));
    dispatch(setTextEditActive(true));
    dispatch(zoomToSelection());
  });

export const exitEditText = () => dispatch =>
  batch(() => {
    dispatch(setSelectInside(false));
    dispatch(setTextEditActive(false));
    dispatch(exitZoomToSelection());
  });

export const editImage = () => dispatch =>
  batch(() => {
    dispatch(setSelectInside(true));
    dispatch(zoomToSelection());
  });

export const exitEditImage = () => dispatch =>
  batch(() => {
    dispatch(setSelectInside(false));
    dispatch(exitZoomToSelection());
  });
