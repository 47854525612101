import { getViewportClientRect, setPanAndZoom } from '../actions/viewport';
import { deleteElements, updateElements } from '../actions/workspace';
import { dimensions } from '../constants';
import exportPresets from '../exportPresets';
import { calculateIsSpreadVisible } from '../selectors/controls';
import {
  getIsolation,
  getSelectedSpreadIds,
  getSpreadIds,
  getSpreadsCount,
  getTargetSpreadIndex,
  getWorkspace,
} from '../selectors/legacy';
import { getPan, getViewportHeight, getZoom } from '../selectors/viewport';
import { getSpreadNodes } from '../selectors/workspace';
import {
  applyPaddingToRectOrBounds,
  elementToElementMatrix,
  fitRectIntoArea,
  getAxisAlignedBounds,
} from '../util/geometry';
import { elementSelect, setSelectInside, spreadSelect } from './selection';

export const UPDATE = 'controls/UPDATE';
export const TOGGLE = 'controls/TOGGLE';
export const RESET = 'controls/RESET';

const initialState = {
  hoveredInstance: null,
  showStickers: true,
  gridEnabled: false,
  cropPreview: false,

  /**
   * `operationActive` will be set
   * - while performing <Handles />-operation
   * - while drag-and-drop reordering stickers or sections in the sidebar
   * We're using it to e. g. hide the <Lasso /> or ignore autosave-updates.
   */
  operationActive: false,
  lassoActive: false,

  imageListPreview: true,
  viewCenter: { x: 0, y: 0 },
  autoFit: true,

  alignTo: 'page',
  alignUseMargins: false,
  pageMargins: { left: 0, top: 0, bottom: 0, right: 0 },

  isolation: null,

  filterPresets: [],
  sectionDragEnabled: true,
  enableFilter: true,
  showElementsTree: false,

  jobSettings: exportPresets.album_softcover_inside,

  searchSettings: { search: '', replace: '', scope: 'spreads' },
  renderAllSpreads: false,

  imageTags: [],

  shouldHideHandles: false,

  sidebarMinimized: false,
  editingStickerId: null,
  activeAlbumPreviewSpreadIndex: 0,
  webFontsLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TOGGLE: {
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export const resetControls = () => ({ type: RESET });

export const updateControls = data => dispatch => {
  dispatch({
    type: UPDATE,
    payload: data,
  });
};

export const toggleControl = key => dispatch => {
  dispatch({
    type: TOGGLE,
    payload: key,
  });
};

export const exitIsolation = () => (dispatch, getState) => {
  const state = getState();
  const isolation = getIsolation(state);
  if (!isolation) {
    return;
  }
  const { nodes } = getWorkspace(state);

  const group = nodes[isolation];
  const parent = nodes[group.parent];
  const children = group.children.map(id => nodes[id]);
  if (children.length === 0) {
    // all group contens have been deleted
    dispatch(deleteElements([isolation]));
  } else {
    const box = getAxisAlignedBounds(children, `[data-id='${isolation}']`);
    if (box) {
      const { x, y, width, height } = box;
      // move all elements to origin inside group
      const deltas = children.reduce((acc, cur) => {
        acc[cur.props.id] = {
          x: cur.props.x - x,
          y: cur.props.y - y,
        };
        return acc;
      }, {});

      // move group origin accordingly
      const origin = elementToElementMatrix(
        group.props.id,
        parent.props.id
      ).transformPoint({ x, y });
      dispatch(
        updateElements({
          ...deltas,
          [isolation]: {
            x: origin.x,
            y: origin.y,
            width,
            height,
          },
        })
      );
    }
  }
  dispatch(updateControls({ isolation: null }));

  // This fixes a bug when exiting isolation but the user is currently editing a text-frame
  dispatch(setSelectInside(false));
};

export const setIsolation = (isolation = null) => dispatch => {
  if (!isolation) {
    dispatch(exitIsolation());
  }
  dispatch(updateControls({ isolation }));
};

/**
 * Set pan/zoom and optionally clear selection, if the selected contents are
 * not not visible anymore.
 */
const setPanAndZoomAndCheckSelection = (pan, zoom) => (dispatch, getState) => {
  const state = getState();
  const viewportHeight = getViewportHeight(state);

  /**
   * Determine index of the spread containing the element selection (targetSpreadIndex)
   * and the explicitly selected spread (if any), to check if these spreads are still
   * visible. If they are not visible, the regarged selection (element or spread) needs
   * to be cleared.
   */
  const isSpreadVisible = spreadIndex =>
    calculateIsSpreadVisible(zoom, pan, viewportHeight, spreadIndex);

  const [firstSelectedSpreadId] = getSelectedSpreadIds(state);
  const spreadIds = getSpreadIds(state);
  const selectedSpreadIndex = spreadIds.indexOf(firstSelectedSpreadId);
  const targetSpreadIndex = getTargetSpreadIndex(state);
  if (!isSpreadVisible(selectedSpreadIndex)) {
    dispatch(spreadSelect([]));
  }
  if (!isSpreadVisible(targetSpreadIndex)) {
    dispatch(elementSelect([]));
    /**
     * If isolation is active, exit isolation if the spread containing the isolated
     * element is panned out of view
     */
    dispatch(exitIsolation());
  }

  // Finally update the store with the new pan/zoom
  dispatch(setPanAndZoom(pan, zoom));
};

const setPanOrZoom = (key, value) => (dispatch, getState) => {
  const state = getState();
  const pan = getPan(state);
  const zoom = getZoom(state);

  // This is to get the pan/zoom pair after the desired update
  const payload = { pan, zoom, [key]: value };
  dispatch(setPanAndZoomAndCheckSelection(payload.pan, payload.zoom));
};

export const setZoom = newZoom => setPanOrZoom('zoom', newZoom);

export const setPan = newPan => setPanOrZoom('pan', newPan);

// Reset the viewport to show all existing spreads
export const resetView = () => (dispatch, getState) => {
  const viewportClientRect = getViewportClientRect();
  const spreadsCount = getSpreadsCount(getState());
  const contentArea = {
    x: 0,
    y: 0,
    width: dimensions.pageWidth * 2,
    height:
      dimensions.pageHeight * spreadsCount +
      dimensions.pagePadding * (spreadsCount - 1),
  };
  const paddedContentArea = applyPaddingToRectOrBounds(contentArea, 50);
  const { scale, x, y } = fitRectIntoArea(
    paddedContentArea,
    viewportClientRect
  );
  dispatch(setPan({ x, y }));
  dispatch(setZoom(scale));
};

// Reset the viewport to show one single spread
export const resetViewToSpreadIndex = (spreadIndex = -1) => (
  dispatch,
  getState
) => {
  dispatch(updateControls({ activeAlbumPreviewSpreadIndex: spreadIndex }));
  const viewportClientRect = getViewportClientRect();

  if (!viewportClientRect || viewportClientRect.width === 0) {
    return;
  }

  const targetSpreadIndex = getTargetSpreadIndex(getState());
  const resetIndex = spreadIndex === -1 ? targetSpreadIndex : spreadIndex;
  const contentArea = {
    x: 0,
    y: (dimensions.pageHeight + dimensions.pagePadding) * resetIndex,
    width: dimensions.pageWidth * 2,
    height: dimensions.pageHeight,
  };
  const paddedContentArea = applyPaddingToRectOrBounds(contentArea, 50);
  const { scale, x, y } = fitRectIntoArea(
    paddedContentArea,
    viewportClientRect
  );
  dispatch(setPan({ x, y }));
  dispatch(setZoom(scale));
};

export const resetViewToSectionId = sectionId => (dispatch, getState) => {
  const spreadNodes = getSpreadNodes(getState());
  const spreadIndex = spreadNodes.findIndex(
    spreadNode => spreadNode.parent === sectionId
  );
  dispatch(resetViewToSpreadIndex(spreadIndex));
};

export const resetViewToSpreadOfSelection = () => (dispatch, getState) => {
  const spreadIndex = getTargetSpreadIndex(getState()); // Spread with current selection
  dispatch(resetViewToSpreadIndex(spreadIndex));
};

export const toggleEnableFilter = () => toggleControl('enableFilter');
export const toggleCropPreview = () => toggleControl('cropPreview');
export const toggleGridEnabled = () => toggleControl('gridEnabled');
export const toggleAutoFit = () => toggleControl('autoFit');
export const toggleShowElementsTree = () => toggleControl('showElementsTree');
export const toggleShowStickers = () => toggleControl('showStickers');

export const setEditingStickerId = editingStickerId =>
  updateControls({ editingStickerId });
