import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import flow from 'lodash/flow';

import {
  createError,
  createInfo,
  createWarning,
} from '../actions/notifications';

function useNotifications() {
  const dispatch = useDispatch();

  const notificationCreators = useMemo(() => {
    const createDispatcher = fn =>
      flow((message, props) => ({ message, ...props }), fn, dispatch);

    return {
      createWarning: createDispatcher(createWarning),
      createError: createDispatcher(createError),
      createInfo: createDispatcher(createInfo),
    };
  }, [dispatch]);

  return notificationCreators;
}

export default useNotifications;
