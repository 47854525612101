import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

import { fetchPexelsImagesForWorkspace } from '../../actions/pexels';
import { loadWorkspace } from '../../actions/workspace';
import { useConfirmation } from '../../components/ui/ConfirmModal/ConfirmationService';
import { setAlbumData, unsetCurrentAlbum } from '../../modules/albums';
import {
  selectBlueprint,
  setBlueprintCategory,
} from '../../modules/blueprints';
import { replaceColors, replaceFonts } from '../../modules/colorsAndFonts';
import { selectWizardState } from '../../selectors/wizard';
import useQueryParams from '../../hooks/useQueryParams';
import useLocale from '../../hooks/localization/useLocale';

export const emailFromQueryParamsKey = 'email';

function useWizardAutosave({ setCategoryFilter } = {}) {
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const [hydrationPending, setHydrationPending] = useState(true);
  const { t } = useLocale();
  const [
    wizardAutosave,
    setWizardAutosave,
    removeWizardAutosave,
  ] = useLocalStorage('wizardAutosave');

  const {
    blueprintId,
    blueprintCategory,
    workspace,
    colors,
    fonts,
    albumData,
  } = useSelector(selectWizardState);

  const email = useQueryParams().get(emailFromQueryParamsKey);

  const hydrateStore = useCallback(() => {
    const dispatchMap = {
      fonts: replaceFonts,
      colors: replaceColors,
      workspace: loadWorkspace,
      blueprintId: selectBlueprint,
      albumData: setAlbumData,
      blueprintCategory: setBlueprintCategory,
    };

    batch(async () => {
      Object.entries(dispatchMap).forEach(([key, action]) => {
        const { [key]: value } = wizardAutosave;

        if (!value) {
          return;
        }
        dispatch(action(value));
      });

      const {
        workspace: workspaceFromAutosave,
        blueprintCategory: blueprintCategoryFromAutosave,
      } = wizardAutosave;

      // Select dropdown value in category filter (triggering blueprint filtering)
      if (blueprintCategoryFromAutosave) {
        setCategoryFilter(blueprintCategoryFromAutosave);
      }

      if (workspace) {
        await dispatch(fetchPexelsImagesForWorkspace(workspaceFromAutosave));
      }
    });
  }, [dispatch, wizardAutosave, workspace, setCategoryFilter]);

  async function showModal() {
    if (!Object.values(wizardAutosave || {}).some(Boolean)) {
      setHydrationPending(false);
      return;
    }

    try {
      await confirm({
        closeButton: false,
        headerTitle: t('wizard.autosaveModal.headline'),
        body: t('wizard.autosaveModal.text'),
        confirmText: t('wizard.autosaveModal.confirm'),
        declineText: t('wizard.autosaveModal.decline'),
        backdrop: 'static',
        keyboard: false,
      });
      hydrateStore();
    } catch {
      dispatch(unsetCurrentAlbum());
    } finally {
      removeWizardAutosave();
      setHydrationPending(false);
    }
  }

  function save() {
    if (hydrationPending) {
      return;
    }

    const storeData = {
      blueprintId,
      blueprintCategory,
      workspace,
      colors,
      fonts,
      albumData,
    };

    setWizardAutosave(prevState => ({ ...prevState, ...storeData }));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(save, [workspace, colors, fonts, albumData]);

  useEffect(() => {
    if (!email) {
      return;
    }

    localStorage.setItem(emailFromQueryParamsKey, email);
  }, [email]);

  return {
    removeWizardAutosave,
    showModal,
  };
}

export default useWizardAutosave;
