import fromPairs from 'lodash/fromPairs';
import { useSelector } from 'react-redux';

import useAuth from '../../hooks/useAuth';
import useQueryParams from '../../hooks/useQueryParams';
import { selectCookieConsent, selectUserLocale } from '../../selectors/user';
import useAppEnvironment from '../../hooks/useAppEnvironment';
import useScreenSize from '../../hooks/useScreenSize';

const acquisitionParams = [
  'entrypoint',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
  'ad-set',
];

function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));

  if (match) {
    return match[2];
  }

  return null;
}

function useAnalytics() {
  const { level } = useSelector(selectCookieConsent) || { level: [] };
  const userLocale = useSelector(selectUserLocale);

  const acceptedNecessary = level.includes('necessary');
  const acceptedTargeting = level.includes('targeting');

  const { user } = useAuth();
  const { email, sub, created_at: createdAt } = user || {};
  const queryParams = Array.from(useQueryParams().entries());
  const { environment } = useAppEnvironment();
  const { isMobile } = useScreenSize();

  const filteredQueryParams = fromPairs(
    queryParams.filter(([key]) => acquisitionParams.includes(key))
  );

  const cookieParams = acquisitionParams.reduce((acc, curr) => {
    const cookieValue = getCookie(curr);

    return {
      ...acc,
      ...(cookieValue ? { [curr]: cookieValue } : {}),
    };
  }, {});

  // We're overriding existing cookie values with fresh URL params here (last-click attribution)
  const params = {
    ...cookieParams,
    ...filteredQueryParams,
    locale: userLocale,
  };

  function track(name, properties = {}) {
    if (!acceptedTargeting) {
      return;
    }

    const enhancedProps = {
      blueprintCategory: environment,
      mobile: isMobile,
      locale: userLocale,
      ...properties,
    };

    /**
     * PostHog's JS client won't expose capture/identify functions
     * until it is `init`ialized (thats different from Segment). We're thus safe navgiating here.
     */
    window.posthog?.capture?.(name, enhancedProps);

    if (!email) {
      window.analytics.track(name, enhancedProps);
      return;
    }

    window.analytics.track(name, {
      email,
      ...enhancedProps,
    });
  }

  function identify(properties) {
    if (!acceptedTargeting) {
      return;
    }

    const nextProperties = { source: 'designer', ...params, ...properties };

    if (!sub) {
      window.analytics.identify(nextProperties);
      return;
    }

    const nextPropertiesWithIdentity = {
      ...nextProperties,
      email,
      created_at: Math.floor(new Date(createdAt).getTime() / 1000),
    };

    window.posthog?.identify?.(sub, nextPropertiesWithIdentity);
    window.analytics.identify(sub, nextPropertiesWithIdentity);
  }

  function page() {
    if (!acceptedTargeting) {
      return;
    }

    window.posthog?.capture?.('$pageview');
    window.analytics.page();
  }

  function setCookies() {
    if (!acceptedNecessary) {
      return;
    }

    const d = new Date();
    d.setTime(d.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 days

    Object.keys(params).forEach(key => {
      document.cookie = `${key}=${
        params[key]
      }; expires=${d.toUTCString()}; path=/; domain=stickerstars.de`;
    });
  }

  return {
    identify,
    page,
    track,
    setCookies,
    params,
    acceptedNecessary,
    acceptedTargeting,
  };
}

export default useAnalytics;
