import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import useApi from '../../hooks/useApi';
import useLocale from '../../hooks/localization/useLocale';

function AccountActivation() {
  const { t } = useLocale();
  const api = useApi();
  const [errors, setErrors] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await api.patch(`/users/activate`);
      } catch (err) {
        setErrors(err);
      }

      setShowAlert(true);
    })();
  }, [api, setErrors]);

  if (!showAlert) {
    return null;
  }

  return errors ? (
    <Alert variant="danger" className="border mt-2 qa-error-alert">
      {t('genericError')}
    </Alert>
  ) : (
    <Alert variant="success" className="border mt-2 qa-success-alert">
      {t('start.accountActivatedNotification')}
    </Alert>
  );
}

export default AccountActivation;
