import React from 'react';
import { func, node } from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import useAuth from '../../hooks/useAuth';
import useLocale from '../../hooks/localization/useLocale';

const DropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

DropdownToggle.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
};

function UserMenu() {
  const { t } = useLocale();
  const { user, logout } = useAuth();
  const {
    url,
    params: { albumId },
  } = useRouteMatch();

  return (
    <div className="user-menu qa-user-menu">
      <Dropdown alignRight>
        <Dropdown.Toggle as={DropdownToggle} variant="light">
          <div
            className="avatar"
            style={{ backgroundImage: `url('${user.picture}')` }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/profile">
            {t('editor.meta.profile')}
          </Dropdown.Item>
          {albumId && (
            <Dropdown.Item as={Link} to={`${url}/settings`}>
              {t('settings')}
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout} className="qa-logout">
            {t('editor.meta.logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default UserMenu;
