import React from 'react';
import { string } from 'prop-types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { batch, useDispatch, useSelector } from 'react-redux';

import StickerCell from './StickerCell';
import { updateElements } from '../../../../actions/workspace';
import {
  createSticker,
  moveSticker,
  unlinkStickerCell,
} from '../../../../actions/stickers';
import { createWarning } from '../../../../actions/notifications';
import { historyAnchor } from '../../../../modules/history';
import { itemTypes } from '../../../../constants';
import { SpreadPropsShape } from '../../../shapes';
import { getStickerCellIdsByStickerId } from '../../../../selectors/stickers';
import { useConfirmation } from '../../../ui/ConfirmModal/ConfirmationService';
import { fileNameToStickerName, generateId } from '../../../../util';
import { getImage, getImages } from '../../../../selectors/images';
import { updateImage } from '../../../../modules/images';
import useApi from '../../../../hooks/useApi';
import useImageUpload from '../../../../hooks/useImageUpload';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import useAnalytics from '../../../../containers/app/useAnalytics';
import useLocale from '../../../../hooks/localization/useLocale';
import { selectCurrentAlbum } from '../../../../selectors/albums';

function StickerCellWithDnd(props) {
  const { t } = useLocale();
  const images = useSelector(getImages);
  const api = useApi();
  const { createStickerImage } = useImageUpload();
  const { startLoading, stopLoading } = useLoading(commonTypes.uploadingFiles);
  const analytics = useAnalytics();

  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const stickerCellIdsByStickerId = useSelector(getStickerCellIdsByStickerId);
  const albumId = useSelector(selectCurrentAlbum);

  function linkExistingSticker(stickerItem) {
    const { sectionId: nextSectionId } = props.spreadProps;

    const { id: stickerId, sectionId } = stickerItem;
    const prevStickerCellId = stickerCellIdsByStickerId[stickerId];

    // Unlink previous sticker cell if sticker had already been placed
    if (prevStickerCellId) {
      dispatch(unlinkStickerCell(prevStickerCellId));
    }

    // Link new sticker cell
    dispatch(updateElements({ [props.id]: { stickerId } }));

    // move sticker to new section if changed
    if (sectionId !== nextSectionId) {
      dispatch(moveSticker(stickerId, nextSectionId));
    }

    analytics.track('Sticker Placed', {
      id: stickerId,
      sectionId: nextSectionId,
      drop: true,
      albumId,
    });

    dispatch(historyAnchor());
  }

  function createAndPlaceSticker(image) {
    const { sectionId } = props.spreadProps;
    const stickerId = generateId();
    const {
      blob: { filename },
      id: imageId,
    } = image;

    const sticker = {
      id: stickerId,
      sectionId,
      image: imageId,
      name: fileNameToStickerName(filename),
    };

    batch(() => {
      dispatch(createSticker(sticker));
      dispatch(updateElements({ [props.id]: { stickerId } }));
      dispatch(historyAnchor());
    });

    analytics.track('Sticker Created', {
      fromFile: false,
      drop: true,
      albumId,
    });

    analytics.track('Sticker Placed', {
      sectionId,
      drop: true,
      albumId,
    });
  }

  /**
   * Used when dropping images from the images tab onto a sticker cell.
   * Patches an `image` image to `sticker` image in the db, updates and returns
   * the image in the store.
   */
  function imageFromSidebar(item) {
    const imageId = item.data.props.image;
    const image = getImage({ images }, imageId);

    api.patch(`/images/${imageId}`, { model: 'sticker' });
    dispatch(updateImage(imageId, { ...image, model: 'sticker' }));

    return image;
  }

  /**
   * Used when dropping a file from native file system onto a sticker cell.
   * Creates and returns a `sticker` image.
   */
  function imageFromFile(item) {
    const { files } = item;

    if (files.length > 1) {
      dispatch(createWarning(t('editor.meta.stickerCellDropError')));

      return false;
    }

    const [file] = Array.from(files);

    return createStickerImage(file);
  }

  /**
   * When the dropped item is a sticker, we unlink its (potential) current
   * sticker cell, link it to the drop target, and update the section if changed.
   */
  function stickerDropHandler(item) {
    linkExistingSticker(item);
  }

  /**
   * When the dropped item is an image from the images tab (`model: image`),
   * we (after confirmation) patch the record and update the element to `model: sticker`,
   * then create and place the sticker.
   */
  function imageDropHandler(item) {
    confirm({
      body: t('editor.sidebar.images.stickerDropWarning'),
    })
      .then(() => {
        const image = imageFromSidebar(item);
        if (!image) {
          dispatch(createWarning(t('genericError')));

          return;
        }

        createAndPlaceSticker(image);
      })
      .catch(() => {});
  }

  /**
   * When the dropped item is a file from the local file system, we create an image
   * (`model: sticker`), then create and place the sticker.
   */
  async function fileDropHandler(item) {
    startLoading();
    const image = await imageFromFile(item);
    stopLoading();

    if (!image) {
      dispatch(createWarning(t('genericError')));

      return;
    }

    createAndPlaceSticker(image);
  }

  const itemDropHandler = {
    [itemTypes.sticker]: item => stickerDropHandler(item),
    [itemTypes.image]: item => imageDropHandler(item),
    [NativeTypes.FILE]: item => fileDropHandler(item),
  };

  const [collectedProps, drop] = useDrop({
    accept: [itemTypes.sticker, itemTypes.image, NativeTypes.FILE],
    collect(monitor) {
      return {
        dropIndication: monitor.isOver(),
      };
    },
    async drop(item, monitor) {
      const itemType = monitor.getItemType();
      itemDropHandler[itemType](item);
    },
  });

  const { dropIndication } = collectedProps;
  return (
    <g ref={drop}>
      <StickerCell {...props} dropIndication={dropIndication} />
    </g>
  );
}

StickerCellWithDnd.propTypes = {
  id: string.isRequired,
  spreadProps: SpreadPropsShape.isRequired,
};

export default StickerCellWithDnd;
