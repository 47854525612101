import React from 'react';
import { number } from 'prop-types';

/**
 * This is a tiny visual offset to prevent the shadow "div" from
 * showing through.
 */
const shadowOffset = 0.1;

function SpreadShadow({ x, y, width, height }) {
  return (
    <foreignObject
      x={x + shadowOffset}
      y={y + shadowOffset}
      width={width - shadowOffset * 2}
      height={height - shadowOffset * 2}
      style={{ overflow: 'visible' }}
    >
      <div className="w-100 h-100 shadow spread-shadow qa-spread-shadow" />
    </foreignObject>
  );
}

SpreadShadow.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
};

export default SpreadShadow;
