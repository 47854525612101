import React, { useRef } from 'react';
import { shape, string, object } from 'prop-types';
import { useDrag } from 'react-dnd';

import { ItemTypes } from './constants';

export const generatePreview = ({ item, style }) => {
  if (!item || item.type !== ItemTypes.IMAGE) {
    return null;
  }

  return (
    <div
      className="custom-drag-preview"
      style={{
        ...style,
      }}
    >
      <img src={item.preview} alt="Dragged item" />
    </div>
  );
};

function ImageThumbnail({ image, sourceArea }) {
  const imgRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => ({
      id: image.id,
      sourceArea,
      preview: image.preview,
      type: ItemTypes.IMAGE,
      imageData: {
        id: image.id,
        preview: image.preview,
        name: image.name,
      },
    }),
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`image-thumbnail ${
        isDragging ? 'dragging' : ''
      } qa-image-thumbnail`}
    >
      <img
        ref={imgRef}
        src={image.preview}
        alt={image.name}
        className="w-100 h-100 rounded-2 border shadow-sm"
      />
    </div>
  );
}

ImageThumbnail.propTypes = {
  image: shape({
    id: string.isRequired,
    preview: string.isRequired,
    name: string.isRequired,
    file: object.isRequired,
  }).isRequired,
  sourceArea: string.isRequired,
};

export default ImageThumbnail;
