import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { CheckoutFormDataShape } from '../../components/shapes';
import { CheckoutShape } from './shapes';
import useLocale from '../../hooks/localization/useLocale';

function CheckoutVoucherInput({
  formData: { voucherCode },
  setFormData,
  isFetchingCheckout,
  checkout,
}) {
  const { t } = useLocale();
  const [localVoucherCode, setLocalVoucherCode] = useState('');
  const {
    voucher: { status, error_message: errorMessage },
  } = checkout;

  const setVoucherCode = value =>
    setFormData(prevFormData => ({ ...prevFormData, voucherCode: value }));

  const handleSubmit = () => {
    setVoucherCode(localVoucherCode);
  };

  const handleReset = () => {
    setLocalVoucherCode('');
    setVoucherCode('');
  };

  const invalidCodeSupplied = [
    'invalid',
    'inactive',
    'taken',
    'below_minimum_amount',
  ].includes(status);
  const validCodeSupplied = status === 'valid';

  if (validCodeSupplied) {
    return (
      <Alert
        className="mt-3 qa-voucher-success"
        variant="success"
        onClose={handleReset}
        dismissible
      >
        {t('checkout.voucherApplied', { code: voucherCode })}
      </Alert>
    );
  }

  return (
    <Alert className="mt-3 border" variant={invalidCodeSupplied && 'secondary'}>
      <Form.Label>{t('checkout.haveVoucher')}</Form.Label>
      <InputGroup className="mb-2" hasValidation>
        <Form.Control
          className="qa-voucher-code-input"
          name="voucher_code"
          placeholder={t('checkout.voucherCodePlaceholder')}
          value={localVoucherCode}
          onChange={({ target: { value } }) => setLocalVoucherCode(value)}
          isInvalid={invalidCodeSupplied}
        />
        <Form.Control.Feedback type="invalid" tooltip>
          {errorMessage}
        </Form.Control.Feedback>
        <Button
          className="qa-voucher-submit-btn"
          variant="outline-primary"
          disabled={localVoucherCode.length === 0 || isFetchingCheckout}
          onClick={handleSubmit}
        >
          OK
        </Button>
      </InputGroup>
    </Alert>
  );
}

CheckoutVoucherInput.propTypes = {
  formData: CheckoutFormDataShape.isRequired,
  setFormData: func.isRequired,
  isFetchingCheckout: bool.isRequired,
  checkout: CheckoutShape.isRequired,
};

export default CheckoutVoucherInput;
