import React from 'react';
import { number } from 'prop-types';
import ContentLoader from 'react-content-loader';

const TableLoader = props => {
  const { rows } = props;
  const rowHeight = 90;

  return (
    <ContentLoader
      width="100%"
      height="700"
      viewBox={`0 0 1500 ${rowHeight * rows}`}
      {...props}
    >
      {new Array(rows).fill(' ').map((_el, index) => {
        const contentVerticalPosition = contentHeight =>
          rows > 1 ? contentHeight + rowHeight * index : contentHeight;

        return (
          <>
            <rect
              x="20"
              y={`${contentVerticalPosition(20)}`}
              rx="4"
              ry="4"
              width="40"
              height="20"
            />
            <rect
              x="100"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
            <rect
              x="750"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
            <rect
              x="1450"
              y={`${contentVerticalPosition(20)}`}
              rx="4"
              ry="4"
              width="20"
              height="20"
            />
            <rect
              y={`${contentVerticalPosition(59)}`}
              x="10"
              ry="10"
              width="1500"
              height="1"
            />
          </>
        );
      })}
    </ContentLoader>
  );
};

TableLoader.defaultProps = {
  rows: 8,
};

TableLoader.propTypes = {
  rows: number,
};

export default TableLoader;
