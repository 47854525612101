import React, { useState } from 'react';
import { number, func, bool, string } from 'prop-types';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { LineItemShape, OrderShape } from '../../../components/shapes';
import Icon from '../../../components/Icon';
import { useConfirmation } from '../../../components/ui/ConfirmModal/ConfirmationService';
import useApi from '../../../hooks/useApi';
import useEditRecord from './useEditRecord';
import AdminOnly from '../../../components/generic/AdminOnly';
import useNotifications from '../../../hooks/useNotifications';
import useLocale from '../../../hooks/localization/useLocale';

function LineItemForm({
  lineItem,
  refetchOrder,
  setEditing,
  newRecord,
  orderId,
}) {
  const confirm = useConfirmation();
  const api = useApi();
  const {
    handleSubmit: handleEditSubmit,
    createHandler,
    editedRecord,
  } = useEditRecord({
    initialRecord: lineItem,
    url: `/line_items/${lineItem.id}`,
    pickKeys: ['description', 'quantity', 'product'],
    onSuccess: () => {
      setEditing(false);
      refetchOrder();
    },
  });
  const { createError } = useNotifications();

  const { description, quantity, product } = editedRecord;

  async function handleTrash() {
    try {
      await confirm({
        body: <>Wirklich löschen?</>,
      });
    } catch (_err) {
      return;
    }

    try {
      await api.delete(`/line_items/${lineItem.id}`);
      refetchOrder();
    } catch (err) {
      createError(`Fehler beim Löschen: ${err.toString()}`);
    }
  }

  async function handleSubmit(event) {
    try {
      if (newRecord) {
        await api.post(`/orders/${orderId}/line_items`, {
          description,
          quantity,
          product,
        });
        setEditing(false);
        refetchOrder();
      } else {
        await handleEditSubmit(event);
      }
    } catch (err) {
      createError(`Fehler beim Speichern: ${err.toString()}`);
    }
  }

  return (
    <tr className={`qa-line-item-${lineItem.id}`}>
      <td> </td>
      <td>
        <Form.Control
          type="text"
          value={product}
          onChange={createHandler('product')}
          size="sm"
          className="mb-2"
          placeholder="Produkt"
          name="line_item[product]"
        />
        <Form.Control
          type="text"
          value={description}
          onChange={createHandler('description')}
          size="sm"
          placeholder="Beschreibung"
          name="line_item[description]"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          value={quantity}
          onChange={createHandler('quantity')}
          size="sm"
          placeholder="Menge"
          name="line_item[quantity]"
        />
      </td>
      <td>
        <ButtonGroup size="sm">
          <Button onClick={handleSubmit} className="qa-submit-line-item-btn">
            <Icon name="checkmark" />
          </Button>
          {!newRecord && (
            <Button
              variant="danger"
              onClick={handleTrash}
              className="qa-destroy-line-item-btn"
            >
              <Icon name="trash" />
            </Button>
          )}
          <Button variant="link" onClick={() => setEditing(false)}>
            <Icon name="closeThin" />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
}

LineItemForm.defaultProps = {
  orderId: null,
  newRecord: false,
};

LineItemForm.propTypes = {
  lineItem: LineItemShape.isRequired,
  refetchOrder: func.isRequired,
  setEditing: func.isRequired,
  newRecord: bool,
  orderId: string,
};

function LineItemRow({ lineItem, index, refetchOrder }) {
  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <LineItemForm
        lineItem={lineItem}
        refetchOrder={refetchOrder}
        setEditing={setEditing}
      />
    );
  }

  return (
    <tr
      className={`qa-line-item-${lineItem.id} qa-line-item-row`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td>{index + 1}</td>
      <td>
        <b>{lineItem.title}</b>
        <br />
        {lineItem.description}
      </td>
      <td>{lineItem.quantity}</td>
      <td>
        {hovered && (
          <AdminOnly>
            <Icon
              onClick={() => setEditing(true)}
              className="cursor-pointer qa-edit-line-item-btn"
              name="edit"
            />
          </AdminOnly>
        )}
      </td>
    </tr>
  );
}

LineItemRow.propTypes = {
  lineItem: LineItemShape.isRequired,
  index: number.isRequired,
  refetchOrder: func.isRequired,
};

function LineItemsTable({ order, refetchOrder }) {
  const { t } = useLocale();

  const [showAddLineItem, setShowAddLineItem] = useState(false);
  return (
    <Table responsive>
      <thead>
        <tr>
          <th width="10%">#</th>
          <th width="55%">{t('models.title')}</th>
          <th>{t('models.lineItem.quantity')}</th>
          <th width="10%"> </th>
        </tr>
      </thead>
      <tbody>
        {order.line_items.map((item, index) => (
          <LineItemRow
            key={item.id}
            lineItem={item}
            index={index}
            refetchOrder={refetchOrder}
          />
        ))}
        <AdminOnly>
          {showAddLineItem ? (
            <LineItemForm
              lineItem={{}}
              refetchOrder={refetchOrder}
              setEditing={setShowAddLineItem}
              orderId={order.id}
              newRecord
            />
          ) : (
            <tr>
              <td>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => setShowAddLineItem(true)}
                  className="qa-add-line-item-btn"
                >
                  <Icon name="plus" />
                </Button>
              </td>
              <td colSpan="3"> </td>
            </tr>
          )}
        </AdminOnly>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-right">
            {order.amount_net_human} (netto)
            <br />
            <b>{order.amount_gross_human}</b>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

LineItemsTable.propTypes = {
  order: OrderShape.isRequired,
  refetchOrder: func.isRequired,
};

export default LineItemsTable;
