import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

import useQuery from '../../../hooks/useQuery';
import TableLoader from '../../admin_dashboard/TableLoader';
import useApi from '../../../hooks/useApi';
import useLoading from '../../../hooks/useLoading';
import { selectAlbumData, selectCurrentAlbum } from '../../../selectors/albums';
import useAuth from '../../../hooks/useAuth';
import useLocale from '../../../hooks/localization/useLocale';
import useAnalytics from '../../app/useAnalytics';

function UsersManagement() {
  const { t, fd } = useLocale();
  const api = useApi();
  const { loadWhile, isLoading: loadingCreateInvitation } = useLoading(
    'create-invitation'
  );

  const albumId = useSelector(selectCurrentAlbum);

  const {
    user: { sub },
  } = useAuth();
  const { ownerIdentifier } = useSelector(selectAlbumData);
  const isOwner = sub === ownerIdentifier;

  const {
    data: invitations,
    error: invitationsError,
    isLoading: loadingInvitations,
    refetch: refetchInvitations,
  } = useQuery(`/albums/${albumId}/invitations`, [albumId]);

  const [requestError, setRequestError] = useState(null);

  const {
    data: associations,
    error: associationsError,
    isLoading: loadingAssociations,
    refetch: refetchAssociations,
  } = useQuery(`/albums/${albumId}/associations`, [albumId], {
    requiredDependencies: [albumId],
  });

  const [newInviteEmail, setNewInviteEmail] = useState('');
  const [isInviteFormVisible, setInviteFormVisible] = useState(false);

  const analytics = useAnalytics();

  async function handleRemoveAssociation(associationId) {
    try {
      await api.delete(`/associations/${associationId}`);
      refetchAssociations();
    } catch (error) {
      setRequestError(error.message);
    }
  }

  async function handleAddInvitation(e) {
    e.preventDefault();

    loadWhile(async () => {
      try {
        await api.post(`/albums/${albumId}/invitations`, {
          email: newInviteEmail,
        });
        setNewInviteEmail('');
        refetchInvitations();
        setRequestError(null);
        analytics.track('Invitation Created', {
          albumId,
        });
      } catch (error) {
        setRequestError(error.message);
      }
    });
  }

  async function handleDeleteInvitation(invitationId) {
    try {
      await api.delete(`/invitations/${invitationId}`);
      refetchInvitations();
      setRequestError(null);
    } catch (error) {
      setRequestError(error.message);
    }
  }

  const newInviteEmailexists =
    invitations && associations
      ? [
          ...invitations.map(invitation => invitation.email),
          ...associations.map(association => association.user.email),
        ].includes(newInviteEmail)
      : true;

  if (loadingInvitations || loadingAssociations) {
    return <TableLoader rows={5} />;
  }

  return (
    <div>
      {(requestError || associationsError) && (
        <Alert variant="danger">{t('settingsModal.users.error')}</Alert>
      )}
      {isOwner && (
        <div className="mb-3 d-flex justify-content-end">
          {!isInviteFormVisible ? (
            <Button
              variant="primary"
              onClick={() => setInviteFormVisible(true)}
              className="d-flex align-items-center qa-invite-user-button"
            >
              {t('settingsModal.users.inviteUser')}
            </Button>
          ) : (
            <Card className="shadow-sm p-3 w-100">
              <Form onSubmit={handleAddInvitation}>
                <InputGroup className="mb-3 invite-user-group">
                  <Form.Control
                    type="email"
                    required
                    placeholder={t('settingsModal.users.emailPlaceholder')}
                    value={newInviteEmail}
                    onChange={e => setNewInviteEmail(e.target.value)}
                    name="invitation[email]"
                  />
                  <Button
                    variant="primary"
                    className="mx-2 qa-submit-invitation-button"
                    disabled={
                      loadingCreateInvitation ||
                      !newInviteEmail.trim() ||
                      newInviteEmailexists
                    }
                    type="submit"
                  >
                    {t('settingsModal.users.sendInvitation')}
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setInviteFormVisible(false)}
                    disabled={loadingCreateInvitation}
                  >
                    {t('settingsModal.users.cancel')}
                  </Button>
                </InputGroup>
              </Form>
            </Card>
          )}
        </div>
      )}
      <Card className="mb-4 shadow-sm">
        <Card.Header className="bg-light">
          {t('settingsModal.users.usersHeader')}
        </Card.Header>
        <ListGroup variant="flush">
          {associations?.map(association => (
            <ListGroup.Item
              key={association.id}
              className="users-list-item qa-user"
            >
              <div>
                <Image
                  src={association.user.picture}
                  roundedCircle
                  width={30}
                  className="mr-2"
                />
                {association.user.email}
                {association.owner && (
                  <span className="badge badge-light ml-2">
                    {t('settingsModal.users.ownerBadge')}
                  </span>
                )}
              </div>
              {isOwner && !association.owner && (
                <Button
                  variant="light"
                  size="sm"
                  className="d-flex align-items-center qa-remove-user-button"
                  onClick={() => handleRemoveAssociation(association.id)}
                >
                  {t('settingsModal.users.removeUser')}
                </Button>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
      <Card className="mb-4 shadow-sm">
        <Card.Header className="bg-light">
          {t('settingsModal.users.invitationsHeader')}
        </Card.Header>
        <ListGroup variant="flush">
          {invitations?.map(invitation => (
            <ListGroup.Item
              key={invitation.id}
              className="users-list-item qa-invitation"
            >
              <div>
                {invitation.email}{' '}
                <small>{fd(new Date(invitation.created_at), new Date())}</small>
              </div>
              {isOwner && (
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => handleDeleteInvitation(invitation.id)}
                  className="d-flex align-items-center qa-cancel-invitation"
                >
                  {t('settingsModal.users.cancelInvitation')}
                </Button>
              )}
            </ListGroup.Item>
          ))}
          {invitations?.length === 0 && (
            <ListGroup.Item className="text-muted text-center">
              {t('settingsModal.users.noInvitations')}
            </ListGroup.Item>
          )}
          {invitationsError && (
            <ListGroup.Item className="text-danger text-center">
              {t('settingsModal.users.invitationsError')}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    </div>
  );
}

export default UsersManagement;
