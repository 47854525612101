import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import flow from 'lodash/flow';
import { useLocalStorage } from 'react-use';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Content from '../../components/ui/AlbumWizard/Content/index.container';
import SvgDefs from '../editor/SvgDefs';
import {
  selectAlbumData,
  selectStickerTemplateSettings,
} from '../../selectors/albums';
import { getWorkspace } from '../../selectors/legacy';
import { getCurrentBlueprintId } from '../../selectors/blueprints';
import { resetStickerCellStickerIds, uniquifyIds } from '../../util/spreads';
import {
  denormalizeElement,
  normalizeElement,
  setRoot,
} from '../../util/workspace';
import { snakeify } from '../../util';
import WizardSidebar from '../../components/ui/Sidebar/WizardSidebar';
import useScopedSidebarTabs from '../../components/ui/Sidebar/useScopedSidebarTabs';
import wizardTabs from '../../components/ui/Sidebar/WizardSidebar/wizardTabs';
import RedirectWithSearchParams from '../app/withSearchParams';

function AlbumWizard(props) {
  const [submitting, setSubmitting] = useState(false);
  const { tabs, activeTab } = useScopedSidebarTabs(wizardTabs);
  const { path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeTab]);

  /**
   * We write the album state to local storage on wizard submit, primarily
   * to send it to the server only after a successful auth redirect.
   * We might use this later on to store wizard state between sessions or
   * in general to prevent data loss (on browser back, f. e.).
   */
  const [, setLocalStorageAlbum] = useLocalStorage('wizardAlbumState');

  const { colors, fonts } = useSelector(state => state.colorsAndFonts);
  const workspace = useSelector(getWorkspace);
  const { title, releaseDate, organization } = useSelector(selectAlbumData);
  const blueprintAlbumId = useSelector(getCurrentBlueprintId);
  const { id: stickerTemplateId } =
    useSelector(selectStickerTemplateSettings) || {};

  const handleSubmit = async () => {
    const clone = flow(
      denormalizeElement,
      uniquifyIds,
      resetStickerCellStickerIds,
      normalizeElement,
      setRoot
    );
    setSubmitting(true);

    const album = snakeify({
      workspace: clone(workspace),
      colors,
      fonts,
      title,
      releaseDate,
      blueprintAlbumId,
      stickerTemplateId,
      organization: snakeify(organization),
    });

    setLocalStorageAlbum(album);
  };

  return (
    <div className="album-wizard qa-album-wizard d-flex vw-100 vh-100">
      <Switch>
        {tabs.map(tab => (
          <Route key={tab.key} path={`${path}/:key(${tab.key})`}>
            <WizardSidebar {...props} />
            <Content onSubmit={handleSubmit} submitting={submitting} />
          </Route>
        ))}
        <RedirectWithSearchParams
          from={`${path}/:key?`}
          to={`${path}/${tabs[0].key}`}
        />
      </Switch>
      <SvgDefs />
    </div>
  );
}

export default AlbumWizard;
