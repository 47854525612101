import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { useFormState } from '../FormStateProvider';
import useLocale from '../../../hooks/localization/useLocale';

function Result() {
  const { t } = useLocale();
  const { error, reset } = useFormState();

  return (
    <>
      {error ? (
        <Alert variant="danger">{t('genericError')}</Alert>
      ) : (
        <div>
          <h3 className="font-weight-bold">
            {t('stickerUploadForm.successHeading')}
          </h3>
          <p>{t('stickerUploadForm.successMessage')}</p>
        </div>
      )}
      <div className="py-3">
        <Button
          className="qa-reset"
          onClick={reset}
          variant="outline-primary"
          block
        >
          {t('stickerUploadForm.restartButton')}
        </Button>
      </div>
    </>
  );
}

export default Result;
