import React, { useRef } from 'react';
import { func, string, number } from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import ConnectedTreeNode from './index';
import { itemTypes } from '../../../../constants';
import { IdListShape } from '../../../shapes';

function TreeNodeWithDnd({
  id,
  index,
  targetNodeId,
  siblingIds,
  boundSwapSpreadElements,
}) {
  const elementRef = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: itemTypes.element,
    hover(item, monitor) {
      if (!elementRef.current) {
        return;
      }

      const dragIndex = index;
      const hoverIndex = item.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = elementRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (hoverIndex === undefined || dragIndex === undefined) {
        return;
      }

      boundSwapSpreadElements(
        targetNodeId,
        siblingIds[dragIndex],
        siblingIds[hoverIndex]
      );

      /**
       * We're mutating the item for performance reasons - see:
       * https://react-dnd.github.io/react-dnd/examples/sortable/simple
       */
      item.index = dragIndex; // eslint-disable-line no-param-reassign
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: itemTypes.element,
    item: () => ({
      type: itemTypes.element,
      id,
      index,
    }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(elementRef));

  return (
    <div
      className={`draggable-element${isOver ? ' dnd-hover' : ''}`}
      ref={elementRef}
    >
      <ConnectedTreeNode
        id={id}
        targetNodeId={targetNodeId}
        isDragging={isDragging}
      />
    </div>
  );
}

TreeNodeWithDnd.propTypes = {
  id: string.isRequired,
  targetNodeId: string.isRequired,
  index: number.isRequired,
  boundSwapSpreadElements: func.isRequired,
  siblingIds: IdListShape.isRequired,
};

export default TreeNodeWithDnd;
