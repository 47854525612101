import React from 'react';
import { useSelector } from 'react-redux';

import { actions } from '../../../../constants';
import { getSingleSelectedElement } from '../../../../selectors/legacy';
import { getSelectInside } from '../../../../selectors/selection';
import ActionButton from '../ActionButton';
import useScreenSize from '../../../../hooks/useScreenSize';

function ImageEditButton(props) {
  const imageEditActive = useSelector(getSelectInside);
  const singleSelectedElement = useSelector(getSingleSelectedElement);
  const { isMobile } = useScreenSize();

  // Do not render button in multi-selection or on mobile
  if (!singleSelectedElement || isMobile) {
    return null;
  }

  return (
    <ActionButton
      {...props}
      actionName={imageEditActive ? actions.exitEditImage : actions.editImage}
      tooltip={
        imageEditActive
          ? 'editor.toolbar.finishEditing'
          : 'editor.toolbar.editImage'
      }
      icon={imageEditActive ? 'close' : 'edit'}
      disabled={!!singleSelectedElement?.props?.symbol}
    />
  );
}

export default ImageEditButton;
