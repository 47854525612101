import React from 'react';
import { func, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../../Icon';
import { lineHeights } from '../../../elements/Text/TextConstants';
import useLocale from '../../../../../hooks/localization/useLocale';

function LineHeightButtons({ makeHandleChange, dropDirection, lineHeight }) {
  const { t } = useLocale();
  const placement = dropDirection === 'up' ? 'bottom' : 'top';

  return lineHeights.map(({ value, icon, label, name }) => (
    <OverlayTrigger
      placement={placement}
      key={value}
      overlay={
        <Tooltip>{`${t('editor.toolbar.lineHeight')} ${label}`}</Tooltip>
      }
    >
      <Button
        active={value === lineHeight}
        onClick={makeHandleChange('lineHeight', value)}
        className={`rounded-0 qa-lineheight-${name}`}
      >
        <Icon name={icon} />
      </Button>
    </OverlayTrigger>
  ));
}

LineHeightButtons.propTypes = {
  lineHeight: string.isRequired,
  dropDirection: string.isRequired,
  makeHandleChange: func.isRequired,
};

export default LineHeightButtons;
