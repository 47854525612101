import { dimensions } from '../constants';

/**
 * Fetch an image and run face detection
 * @param {} face result of `detectFaceFromImage`: { x, y, width, height } in relative values to the image size
 * @param {} size result of `imageSize`: { width, height } in mm
 */
export function getStickerPositionFromFace(face, size) {
  if (!face) {
    return null;
  }

  // Percentage values where to fit the face-area inside the sticker-area
  const targetWidth = 0.47;
  const targetTop = 0.36;

  // Simplified calculation, derived from the interal-editors function of the same name
  const cscale =
    (targetWidth * dimensions.stickerWidth) / (face.width * size.width);
  const px = (face.x + face.width / 2) * size.width * cscale;
  const py = (face.y + face.height / 2) * size.height * cscale;
  const cx = dimensions.stickerWidth * 0.5 - px;
  const cy = dimensions.stickerHeight * targetTop - py;
  const crotation = 0;

  return { cx, cy, cscale, crotation };
}

export default getStickerPositionFromFace;
