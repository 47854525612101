import React from 'react';
import { useSelector } from 'react-redux';

import { actions } from '../../../../constants';
import { getSelectedElementTypes } from '../../../../selectors/selection';
import ActionButton from '../ActionButton';

function GroupButtons(props) {
  const types = useSelector(getSelectedElementTypes);
  const canUngroup = types.includes('Group');
  const canGroup = types.length >= 2;

  return (
    <>
      {canGroup && (
        <ActionButton
          {...props}
          actionName={actions.groupItems}
          tooltip="editor.toolbar.group"
          icon="group"
        />
      )}
      {canUngroup && (
        <ActionButton
          {...props}
          actionName={actions.ungroupItems}
          tooltip="editor.toolbar.ungroup"
          icon="ungroup"
        />
      )}
    </>
  );
}

export default GroupButtons;
