import { createAction } from 'redux-actions';

import { CLOSE, PUSH, types } from '../modules/notifications';
import { generateId } from '../util/index';

function createNotification(props, type) {
  return {
    id: generateId(),
    type,
    timestamp: Date.now(),
    ...props,
  };
}

/**
 * Helper function to create notification action creators
 */
const createNotificationAction = type =>
  createAction(PUSH, payload =>
    createNotification(
      {
        message: typeof payload === 'string' ? payload : payload.message,
        ...(typeof payload === 'object' ? payload : {}),
      },
      type
    )
  );

export const createError = createNotificationAction(types.error);

export const createWarning = createNotificationAction(types.warning);

export const createInfo = createNotificationAction(types.info);

export const closeNotification = createAction(CLOSE);
