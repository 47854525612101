import { useSelector } from 'react-redux';

import useAuth from '../../../hooks/useAuth';
import { selectImageIdsFromLockedNodesWithChildren } from '../../../selectors/workspace';
import { selectBlueprintImageIds } from '../../../selectors/images';

function useScopedImages(images) {
  const { isAdmin } = useAuth();

  const lockedImageIds = useSelector(selectImageIdsFromLockedNodesWithChildren);
  const blueprintImageIds = useSelector(selectBlueprintImageIds);

  const availableImages = images.filter(
    image =>
      (!lockedImageIds.includes(image.id) &&
        !blueprintImageIds.includes(image.id)) ||
      isAdmin
  );

  return {
    images: availableImages,
  };
}

export default useScopedImages;
