import React, { useState, useCallback } from 'react';
import { bool, func, string, number } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import useLocale from '../../../hooks/localization/useLocale';
import useImageUpload from '../../../hooks/useImageUpload';
import useAnalytics from '../../../containers/app/useAnalytics';
import { generateId, fileNameToStickerName } from '../../../util';
import {
  applyBlueprintSpread,
  insertElements,
  updateElements,
} from '../../../actions/workspace';
import { createSticker } from '../../../actions/stickers';
import { historyAnchor } from '../../../modules/history';
import { selectCurrentAlbum } from '../../../selectors/albums';
import { getWorkspace } from '../../../selectors/legacy';
import LayoutSelectionStep from './LayoutSelectionStep';
import ImageUploadStep from './ImageUploadStep/ImageUploadStep';
import { generateSpread } from '../../../util/generators';
import { selectLastNonStaticSection } from '../../../selectors/workspace';
import { findNodesByType } from '../../../util/workspace';

function sortNodesByPosition(nodes) {
  return nodes
    .sort((a, b) => (a.y !== b.y ? a.y - b.y : a.x - b.x))
    .map(node => node.id);
}

const SpreadCreationModal = ({
  open,
  onClose,
  targetSectionId,
  insertionIndex,
}) => {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1); // Step 1: Layout selection, Step 2: Image upload
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const { createStickerImage, createWorkspaceImage } = useImageUpload();
  const { nodes } = useSelector(getWorkspace);
  const analytics = useAnalytics();
  const albumId = useSelector(selectCurrentAlbum);
  const lastNonStaticSection = useSelector(selectLastNonStaticSection);

  function handleLayoutSelect(layout) {
    setSelectedLayout(layout);
  }

  function handleImageUpload(images) {
    setUploadedImages(images);
  }

  const resetModalState = useCallback(function resetModalState() {
    setStep(1);
    setSelectedLayout(null);
    setUploadedImages([]);
  }, []);

  const handleClose = useCallback(
    function handleClose() {
      onClose();
      resetModalState();
    },
    [onClose, resetModalState]
  );

  async function processStickers(stickerImages, stickerCellIds, sectionId) {
    const createdStickerIds = [];

    for (let i = 0; i < stickerImages.length; i += 1) {
      const image = stickerImages[i];
      try {
        const uploadedImage = await createStickerImage(image.file);
        if (!uploadedImage) {
          // Skip this iteration if upload failed
          // eslint-disable-next-line no-continue
          continue;
        }

        const stickerId = `Sticker-${generateId()}`;
        createdStickerIds.push(stickerId);

        const sticker = {
          id: stickerId,
          sectionId,
          image: uploadedImage.id,
          name: fileNameToStickerName(image.name),
        };

        dispatch(createSticker(sticker));
        dispatch(updateElements({ [stickerCellIds[i]]: { stickerId } }));
        analytics.track('Sticker Created', {
          fromFile: true,
          albumId,
          spreadCreationModal: true,
        });
        analytics.track('Sticker Placed', {
          albumId,
          spreadCreationModal: true,
        });
      } catch (error) {
        console.error('Failed to process sticker image:', error);
      }
    }

    return createdStickerIds;
  }

  async function processBackgrounds(
    backgroundImages,
    backgroundImageIds,
    sectionId
  ) {
    for (let i = 0; i < backgroundImages.length; i += 1) {
      const image = backgroundImages[i];
      try {
        const uploadedImage = await createWorkspaceImage(image.file);
        if (!uploadedImage) {
          // eslint-disable-next-line no-continue
          continue;
        }

        dispatch(
          updateElements({
            [backgroundImageIds[i]]: {
              image: uploadedImage.id,
              pexelsId: undefined,
            },
          })
        );

        analytics.track('Background Image Placed', {
          sectionId,
          albumId,
          spreadCreationModal: true,
        });
      } catch (error) {
        console.error('Failed to process background image:', error);
      }
    }
  }

  async function handleSubmit() {
    const sectionId = targetSectionId || lastNonStaticSection.id;

    // Generate a new spread
    const spread = generateSpread();
    const {
      props: { id: spreadId },
    } = spread;

    // Determine insertion position
    const sectionSpreadsCount = nodes[sectionId]?.children?.length || 0;
    const insertAt =
      typeof insertionIndex === 'number' ? insertionIndex : sectionSpreadsCount;

    dispatch(async function dispatchSpreadCreation(dispatchAction, getState) {
      try {
        dispatchAction(insertElements([spread], sectionId, insertAt));
        dispatchAction(applyBlueprintSpread(selectedLayout.id, spreadId));

        const currentState = getState();
        const { nodes: currentNodes } = getWorkspace(currentState);

        if (!currentNodes[spreadId]) {
          console.error(
            'Could not find the spread after blueprint application'
          );
          return;
        }

        // 4. Find all available slots for stickers and background images
        const stickerCellNodes = findNodesByType(
          currentNodes,
          spreadId,
          'StickerCell'
        );

        const backgroundImageNodes = findNodesByType(
          currentNodes,
          spreadId,
          'Image'
        );

        // Sort by position (top-to-bottom, left-to-right)
        const stickerCellIds = sortNodesByPosition(stickerCellNodes);
        const backgroundImageIds = sortNodesByPosition(backgroundImageNodes);

        const stickerImages = uploadedImages.filter(
          img => img.category === 'stickers'
        );
        const backgroundImages = uploadedImages.filter(
          img => img.category === 'backgrounds'
        );

        await processBackgrounds(
          backgroundImages,
          backgroundImageIds,
          sectionId
        );

        await processStickers(stickerImages, stickerCellIds, sectionId);

        dispatchAction(historyAnchor());
        analytics.track('Spread Created', {
          albumId,
          spreadCreationModal: true,
        });
      } catch (error) {
        console.error('Error in spread creation process:', error);
      }
    });

    handleClose();
  }

  return (
    <Modal
      className="qa-spread-creation-modal"
      show={open}
      onHide={handleClose}
      keyboard={false}
      size="lg"
      centered
    >
      {lastNonStaticSection ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {step === 1
                ? t('editor.spreadCreationModal.selectLayout')
                : t('editor.spreadCreationModal.uploadImages')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 overflow-auto spread-creation-modal-body">
            {step === 1 ? (
              <LayoutSelectionStep
                onLayoutSelect={handleLayoutSelect}
                selectedLayout={selectedLayout}
                className="qa-layout-selection-step"
              />
            ) : (
              <ImageUploadStep
                onImagesUpload={handleImageUpload}
                layoutId={selectedLayout?.id}
                selectedLayout={selectedLayout}
                uploadedImages={uploadedImages}
                className="qa-image-upload-step"
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {step === 1 ? (
              <Button
                variant="primary"
                onClick={() => setStep(2)}
                disabled={!selectedLayout}
                block
                className="qa-next-step-button"
              >
                {t('next')}
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={handleSubmit}
                block
                className="qa-create-spread-button"
              >
                {t('editor.spreadCreationModal.create')}
              </Button>
            )}
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body>
          {t('editor.spreadCreationModal.noNonStaticSection')}
        </Modal.Body>
      )}
    </Modal>
  );
};

SpreadCreationModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  targetSectionId: string,
  insertionIndex: number,
};

SpreadCreationModal.defaultProps = {
  targetSectionId: null,
  insertionIndex: null,
};

export default SpreadCreationModal;
