import React, { useState } from 'react';

import { dimensions, colorValues } from '../../../../constants';
import SpreadShadow from './SpreadShadow';
import SpreadCreationModal from '../../../ui/SpreadCreationModal';

/**
 * DummySpread component renders a placeholder spread with a plus button
 * to be displayed on mobile when an album only has cover and backcover
 */
function DummySpread() {
  const [showModal, setShowModal] = useState(false);

  // Use provided dimensions or calculate defaults
  const spreadWidth = dimensions.pageWidth * 2;
  const spreadHeight = dimensions.pageHeight;

  // Add some offset to match the regular spread appearance
  const offset = {
    x: -dimensions.pageBleed,
    y: -dimensions.pageBleed,
  };

  // Calculate center position for the plus button
  const centerX = spreadWidth / 2;
  const centerY = spreadHeight / 2;

  const handleClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <g
        transform={`translate(${offset.x}, ${offset.y})`}
        onClick={handleClick}
        className="dummy-spread qa-dummy-spread"
      >
        <SpreadShadow
          x={offset.x}
          y={offset.y}
          width={spreadWidth}
          height={spreadHeight}
        />
        <rect
          className="qa-dummy-spread-rect"
          x={offset.x}
          y={offset.y}
          width={spreadWidth}
          height={spreadHeight}
          fill={colorValues.spread}
        />
        <rect
          className="spread-grid"
          x={offset.x}
          y={offset.y}
          width={spreadWidth}
          height={spreadHeight}
          fill="url(#grid)"
        />

        {/* Static border for base layer */}
        <rect
          x={offset.x}
          y={offset.y}
          width={spreadWidth}
          height={spreadHeight}
          fill="none"
          stroke={colorValues.selection}
          strokeWidth="2"
          strokeOpacity="0.3"
        />

        {/* Pulsating border effect */}
        <rect
          className="pulsating-border"
          fill="none"
          stroke={colorValues.selection}
          strokeWidth="3"
        >
          <animate
            attributeName="x"
            from={offset.x - 2}
            to={offset.x - 8}
            dur="2.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            from={offset.y - 2}
            to={offset.y - 8}
            dur="2.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="width"
            from={spreadWidth + 4}
            to={spreadWidth + 16}
            dur="2.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            from={spreadHeight + 4}
            to={spreadHeight + 16}
            dur="2.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            from="0.9"
            to="0.1"
            dur="2.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </rect>

        {/* Plus button - much larger size */}
        <g transform={`translate(${centerX}, ${centerY})`}>
          {/* Just the plus icon, no circle or text */}
          <g transform="translate(-80, -80) scale(8)">
            <path
              className="icon"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 9.88916H13.5C13.7761 9.88916 14 10.113 14 10.3892V11.3892C14 11.6653 13.7761 11.8892 13.5 11.8892H11V14.3892C11 14.6653 10.7761 14.8892 10.5 14.8892H9.5C9.22386 14.8892 9 14.6653 9 14.3892V11.8892H6.5C6.22386 11.8892 6 11.6653 6 11.3892V10.3892C6 10.113 6.22386 9.88916 6.5 9.88916H9V7.38916C9 7.11302 9.22386 6.88916 9.5 6.88916H10.5C10.7761 6.88916 11 7.11302 11 7.38916V9.88916Z"
              fill={colorValues.selection}
            />
          </g>
        </g>
      </g>

      <SpreadCreationModal open={showModal} onClose={handleModalClose} />
    </>
  );
}

export default DummySpread;
