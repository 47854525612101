import { string } from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useLocale from '../../../../hooks/localization/useLocale';
import { setEditingStickerId } from '../../../../modules/controls';
import { selectAlbumData } from '../../../../selectors/albums';
import { selectIsEditingSticker } from '../../../../selectors/controls';
import { getSingleSelectedElement } from '../../../../selectors/legacy';
import Icon from '../../../Icon';

function StickerEditButton({ dropDirection }) {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: albumId } = useSelector(selectAlbumData);
  const isEditing = useSelector(selectIsEditingSticker);
  const { stickerId } = useSelector(getSingleSelectedElement)?.props || {};
  const placement = dropDirection === 'up' ? 'bottom' : 'top';

  const openStickerForm = () => {
    history.push(`/album/${albumId}/stickers`);
    dispatch(setEditingStickerId(stickerId));
  };

  if (!stickerId) {
    return null;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{t('editor.toolbar.editSticker')}</Tooltip>}
    >
      <Button
        disabled={isEditing}
        onClick={openStickerForm}
        className="qa-toolbar-edit-sticker-btn"
      >
        <Icon name="edit" />
      </Button>
    </OverlayTrigger>
  );
}

StickerEditButton.propTypes = {
  dropDirection: string.isRequired,
};

export default StickerEditButton;
