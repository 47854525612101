import stickerpack from '../../assets/images/checkout/stickerpack.jpg';
import stickerboxSingleVariety from '../../assets/images/checkout/stickerboxSingleVariety.jpg';
import customStickerpack from '../../assets/images/checkout/customStickerpack.jpg';
import designCheck from '../../assets/images/checkout/designCheck.jpg';
import premiumLamination from '../../assets/images/checkout/premiumLamination.jpg';

export default {
  custom_stickerpack: customStickerpack,
  design_check: designCheck,
  premium_lamination: premiumLamination,
  stickerpack,
  stickerbox_single_variety: stickerboxSingleVariety,
};
