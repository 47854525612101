import { useDispatch, useSelector } from 'react-redux';

import cc from '../../util/cc';
import useFirstTime from '../../hooks/useFirstTime';
import { setCookieConsent } from '../../modules/user';
import { selectCookieConsent } from '../../selectors/user';
import useLocale from '../../hooks/localization/useLocale';

function CookieConsent() {
  const { t } = useLocale();
  const { acceptedNecessary } = useSelector(selectCookieConsent);
  const dispatch = useDispatch();

  useFirstTime(() => {
    if (window.location.pathname.indexOf('render') > -1) {
      return;
    }

    cc.run({
      revision: 1,
      gui_options: {
        consent_modal: {
          layout: 'cloud',
        },
      },
      delay: 500,
      autorun: true,
      page_scripts: true,
      current_lang: 'de',
      force_consent: true,
      cookie_name: 'sstcookieconsentv2',
      cookie_domain: '.stickerstars.de',
      languages: {
        de: {
          consent_modal: {
            title: t('cookies.title'),
            description: t('cookies.description'),
            primary_btn: {
              text: t('cookies.acceptAll'),
              role: 'accept_all',
            },
            secondary_btn: {
              text: t('cookies.settings'),
              role: 'settings',
            },
          },
          settings_modal: {
            title: t('cookies.settingsModal.title'),
            save_settings_btn: t('cookies.settingsModal.saveSettings'),
            accept_all_btn: t('cookies.settingsModal.acceptAll'),
            reject_all_btn: t('cookies.settingsModal.rejectAll'),
            close_btn_label: t('cookies.settingsModal.close'),
            blocks: [
              {
                description: t('cookies.settingsModal.intro'),
              },
              {
                title: t('cookies.settingsModal.necessary.title'),
                description: t('cookies.settingsModal.necessary.description'),
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: t('cookies.settingsModal.analytics.title'),
                description: t('cookies.settingsModal.analytics.description'),
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: t('cookies.settingsModal.moreInfo.title'),
                description: t('cookies.settingsModal.moreInfo.description'),
              },
            ],
          },
        },
      },
      onAccept(cookie) {
        dispatch(setCookieConsent(cookie));
      },
    });
  }, !acceptedNecessary);

  return null;
}

export default CookieConsent;
