import React from 'react';
import { arrayOf, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StickerFormProvider } from '../StickerForm/useStickerForm';
import { StickerShape } from '../../../shapes';
import StickerUploadsModal from '../../StickerUploadsModal';
import {
  deleteSticker as deleteStoreSticker,
  updateSticker as updateStoreSticker,
} from '../../../../actions/stickers';
import { getStickers } from '../../../../selectors/legacy';

/**
 * Modal for editing stickers that have been created from file uploads.
 * This component works directly with the Redux store:
 * - It retrieves sticker data using IDs passed from the parent
 * - It fetches associated image data from the Redux store
 * - All changes are made directly to stickers in the store
 */
function LocalStickerUploadsModal({ onClose, onSubmit, initialStickers }) {
  const dispatch = useDispatch();
  const allStickers = useSelector(getStickers);

  // Filter and retrieve stickers from the store
  const stickers = initialStickers
    .map(initialSticker => allStickers.find(s => s.id === initialSticker.id))
    .filter(Boolean);

  // Update sticker directly in the Redux store
  const updateSticker = nextSticker => {
    const { id, ...updates } = nextSticker;
    dispatch(updateStoreSticker(id, updates));
  };

  const handleDelete = sticker => {
    dispatch(deleteStoreSticker(sticker.id));
  };

  return (
    <StickerFormProvider>
      <StickerUploadsModal
        onSubmit={() => onSubmit(stickers)}
        onClose={() => onClose(stickers)}
        onDelete={handleDelete}
        updateSticker={updateSticker}
        stickers={stickers}
      />
    </StickerFormProvider>
  );
}

LocalStickerUploadsModal.propTypes = {
  initialStickers: arrayOf(StickerShape).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};

export default LocalStickerUploadsModal;
