import React, { memo } from 'react';
import { bool, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch } from 'react-redux';

import executeActionByName from '../../../actions/executeActionByName';
import { historyAnchor } from '../../../modules/history';
import Icon from '../../Icon';
import useLocale from '../../../hooks/localization/useLocale';

function ActionButton({ actionName, tooltip, icon, disabled, dropDirection }) {
  const { t } = useLocale();
  const dispatch = useDispatch();

  function handleClick(event) {
    event.stopPropagation();
    dispatch(executeActionByName(actionName));
    dispatch(historyAnchor());
  }

  return (
    <OverlayTrigger
      placement={dropDirection === 'up' ? 'bottom' : 'top'}
      overlay={<Tooltip>{t(tooltip)}</Tooltip>}
    >
      <Button
        onClick={handleClick}
        className={`qa-toolbar-${actionName}-btn`}
        disabled={disabled}
      >
        <Icon name={icon} />
      </Button>
    </OverlayTrigger>
  );
}

ActionButton.defaultProps = {
  disabled: false,
};

ActionButton.propTypes = {
  actionName: string.isRequired,
  tooltip: string.isRequired,
  icon: string.isRequired,
  disabled: bool,
  dropDirection: string.isRequired,
};

export default memo(ActionButton);
