import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import de from 'date-fns/locale/de';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import TableLoader from './TableLoader';
import { DashboardAlbumShape } from './shapes';
import { renderCountryIcon } from './OrdersTable';
import { categoryToBootstrapVariant } from '../orders/OrderDetails/OrderContent';

const albumLanguageToCountryCode = {
  de: 'DE',
  fr: 'FR',
  it: 'IT',
  en: 'GB',
  pl: 'PL',
};

const renderLanguageIcon = language => {
  const countryCode = albumLanguageToCountryCode[language];
  return renderCountryIcon(countryCode);
};

function AlbumsTable({ data, isLoading }) {
  if (isLoading || !data || !data.albums) {
    return <TableLoader />;
  }

  const { albums } = data;

  return (
    <Table hover size="sm" className="small qa-albums-table">
      <thead>
        <tr>
          <th width="8%">Cover</th>
          <th width="18%">Title</th>
          <th width="12%">Erstellt</th>
          <th width="12%">Aktualisiert</th>
          <th>Event</th>
        </tr>
      </thead>
      <tbody>
        {albums.map(album => (
          <tr key={album.id}>
            <td width="7%">
              <Link to={`/album/${album.id}`}>
                <img
                  src={album.cover_preview_url}
                  alt={album.title}
                  className="img-fluid"
                />
              </Link>
            </td>
            <td className="align-middle qa-album-title">
              <Link to={`/album/${album.id}`}>{album.title}</Link>{' '}
              {renderLanguageIcon(album.language)}
              <br />
              {album.identifier}
              <br />
              <Badge
                className="qa-category-badge"
                variant={categoryToBootstrapVariant[album.category]}
              >
                {album.category}
              </Badge>
            </td>
            <td className="align-middle qa-album-created-at">
              {format(new Date(album.created_at), 'dd.MM.yyyy')}
              <br />
              {formatDistance(new Date(album.created_at), new Date(), {
                addSuffix: true,
                locale: de,
              })}
              <br />
            </td>
            <td className="align-middle qa-album-updated-at">
              {format(new Date(album.updated_at), 'dd.MM.yyyy')}
              <br />
              {formatDistance(new Date(album.updated_at), new Date(), {
                addSuffix: true,
                locale: de,
              })}
              <br />
            </td>
            <td className="align-middle qa-album-release-date">
              {album.release_date ? (
                <>
                  {format(new Date(album.release_date), 'dd.MM.yyyy')}
                  <br />
                  {formatDistance(new Date(album.release_date), new Date(), {
                    addSuffix: true,
                    locale: de,
                  })}
                </>
              ) : (
                '-'
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

AlbumsTable.defaultProps = {
  data: null,
};

AlbumsTable.propTypes = {
  data: shape({
    orders: arrayOf(DashboardAlbumShape),
  }),
  isLoading: bool.isRequired,
};

export default AlbumsTable;
