import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useAppEnvironment, { validEnvironments } from '../useAppEnvironment';
import { detectLocale } from '../../modules/user';
import { translate, formatDate, formatDistance } from './helpers';
import { selectUserLocale } from '../../selectors/user';

/**
 * Global I18n object that looks up strings based on
 * the default app environment (`wedding`).
 */
export const I18n = {
  t: (id, values) => {
    return translate({
      id,
      values,
      locale: detectLocale(),
      environment: validEnvironments[0],
    });
  },
  f: (date, dateFormat = 'PPpp') => {
    return formatDate(date, dateFormat, detectLocale());
  },
};

/**
 * Localization hook that looks up strings based on
 *
 * 1. app environment (`wedding` | `business` | ...)
 * 2. browser language
 */
function useLocale() {
  const { environment } = useAppEnvironment();
  const userLocale = useSelector(selectUserLocale);

  const t = useCallback(
    (id, values) => {
      return translate({
        id,
        values,
        environment,
        locale: userLocale,
      });
    },
    [environment, userLocale]
  );

  const f = useCallback(
    (date, dateFormat = 'PPpp') => {
      return formatDate(date, dateFormat, userLocale);
    },
    [userLocale]
  );

  const fd = useCallback(
    (startDate, endDate) => {
      return formatDistance(startDate, endDate, userLocale);
    },
    [userLocale]
  );

  return {
    t,
    f,
    fd,
  };
}

export default useLocale;
