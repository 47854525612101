import { string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { unlinkStickerCell } from '../../../../actions/stickers';
import { historyAnchor } from '../../../../modules/history';
import { getSelectedElements } from '../../../../selectors/legacy';
import Icon from '../../../Icon';
import useLocale from '../../../../hooks/localization/useLocale';

function UnlinkStickerButton({ dropDirection }) {
  const dispatch = useDispatch();
  const { t } = useLocale();
  const selectedElements = useSelector(getSelectedElements);

  function handleClick(event) {
    event.stopPropagation();
    selectedElements.forEach(element =>
      dispatch(unlinkStickerCell(element.props.id))
    );
    dispatch(historyAnchor());
  }

  const disabled = selectedElements.every(element => !element.props.stickerId);

  return (
    <OverlayTrigger
      placement={dropDirection === 'up' ? 'bottom' : 'top'}
      overlay={<Tooltip>{t('editor.toolbar.unlinkSticker')}</Tooltip>}
    >
      <Button
        onClick={handleClick}
        className="unlink-sticker qa-toolbar-unlink-sticker-btn"
        disabled={disabled}
      >
        <Icon name="peel_off" />
      </Button>
    </OverlayTrigger>
  );
}

UnlinkStickerButton.propTypes = {
  dropDirection: string.isRequired,
};

export default UnlinkStickerButton;
