import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { format } from 'date-fns';
import chunk from 'lodash/chunk';

import { CheckoutFormDataShape } from '../../components/shapes';
import { albumRanges } from './config';
import FeatureCheckbox from './FeatureCheckbox';
import { AvailableFeatureShape } from './shapes';
import useLocale from '../../hooks/localization/useLocale';

function CheckoutProductForm({
  availableFeatures,
  blueprintCategory,
  onCheckoutAlbumCountChange,
  setFormData,
  formData,
  productsFromLineItems,
}) {
  const { t } = useLocale();
  const range = albumRanges[blueprintCategory];
  const {
    noAlbums,
    stickerPackagingType,
    releaseDate,
    orderFeatures,
  } = formData;

  const stickerPackagingOptions = [
    {
      product: 'stickerpack',
      title: t('checkout.stickerPackagingOptions.stickerpack.title'),
      text: t('checkout.stickerPackagingOptions.stickerpack.text'),
    },
    {
      product: 'stickerbox_single_variety',
      title: t(
        'checkout.stickerPackagingOptions.stickerboxSingleVariety.title'
      ),
      text: t('checkout.stickerPackagingOptions.stickerboxSingleVariety.text'),
    },
  ];

  const isChecked = feature => {
    return productsFromLineItems.indexOf(feature.product) > -1;
  };

  const createHandler = key => ({ target: { value } }) =>
    setFormData(nextFormData => ({ ...nextFormData, [key]: value }));

  const handleOrderFeatureChange = product => {
    const index = orderFeatures.indexOf(product);
    const nextOrderFeatures =
      index > -1
        ? orderFeatures.filter(item => item !== product)
        : [...orderFeatures, product];
    setFormData({
      ...formData,
      orderFeatures: nextOrderFeatures,
    });
  };

  const availableFeatureChunks = chunk(availableFeatures, 2);

  return (
    <>
      <Form.Group className="d-flex flex-column mb-4 checkout-form__range p-4">
        <h6 className="mb-4 checkout-form__range__headline">
          {t('checkout.albumsLabel')}
        </h6>
        <Form.Control
          required
          className="qa-people-count-input mb-4"
          type="range"
          min={range.min}
          max={range.max}
          name="noAlbums"
          value={noAlbums}
          onChange={createHandler('noAlbums')}
          onMouseUp={onCheckoutAlbumCountChange}
          onTouchEnd={onCheckoutAlbumCountChange}
        />
        <Form.Label className="checkout-form__range__label qa-range-label text-center m-0">
          <div
            dangerouslySetInnerHTML={{
              __html: t('checkout.albumsNeeded', { count: noAlbums }),
            }}
          />
        </Form.Label>
      </Form.Group>
      <Form.Group className="d-flex flex-column mb-4 checkout-form__range p-4">
        <h6 className="mb-4 checkout-form__range__headline">
          {t('checkout.stickerPackagingOptionsLabel')}
        </h6>
        <Row>
          {stickerPackagingOptions.map(feature => (
            <FeatureCheckbox
              key={feature.product}
              className={`qa-sticker-packaging-type-radio qa-${feature.product}-radio`}
              type="radio"
              feature={feature}
              onChange={createHandler('stickerPackagingType')}
              isChecked={stickerPackagingType === feature.product}
            />
          ))}
        </Row>
      </Form.Group>
      <Row className="my-4">
        <Col md={12}>
          <Form.Group>
            <Form.Label className="h6">
              <b>{t('checkout.eventLabel')}</b>
            </Form.Label>
            <Form.Control
              value={
                releaseDate ? format(new Date(releaseDate), 'yyyy-MM-dd') : ''
              }
              onChange={createHandler('releaseDate')}
              required
              type="date"
              name="releaseDate"
              className="checkout-form-release-date-input qa-album-release-date-input mt-2"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>
          <b>{t('checkout.chooseAdditionalOptions')}</b>
        </Form.Label>
        {availableFeatureChunks.map(features => (
          <Row key={features[0].product}>
            {features.map(feature => (
              <FeatureCheckbox
                key={feature.product}
                className="qa-order-feature-checkbox"
                feature={feature}
                onChange={() => handleOrderFeatureChange(feature.product)}
                isChecked={isChecked(feature)}
              />
            ))}
          </Row>
        ))}
      </Form.Group>
    </>
  );
}

CheckoutProductForm.defaultProps = {
  availableFeatures: [],
  productsFromLineItems: [],
};

CheckoutProductForm.propTypes = {
  availableFeatures: arrayOf(AvailableFeatureShape),
  blueprintCategory: string.isRequired,
  onCheckoutAlbumCountChange: func.isRequired,
  setFormData: func.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  productsFromLineItems: arrayOf(string),
};

export default CheckoutProductForm;
