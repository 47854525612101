import React from 'react';
import { bool, func, string } from 'prop-types';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import { AvailableFeatureShape } from './shapes';
import checkoutImages from './checkoutImages';

function FeatureCheckbox({
  feature,
  onChange,
  isChecked,
  isDisabled,
  type,
  className,
}) {
  const { product, title, amount_gross_human: amountGross, text } = feature;

  return (
    <Col md={12} lg={6} className={`mb-4 ${className}`}>
      <label className="h-100" htmlFor={product}>
        <Card
          key={product}
          className={`enhanced-checkbox${
            isChecked
              ? ' enhanced-checkbox--checked qa-active-order-feature'
              : ''
          } h-100`}
        >
          <Card.Body>
            <div className="d-flex align-items-start mb-2">
              <input
                className={`mt-1 qa-feature-checkbox qa-feature-checkbox-${product}`}
                type={type}
                name={product}
                id={product}
                value={product}
                checked={isChecked}
                onChange={onChange}
                disabled={isDisabled}
              />
              <div className="pl-2 m-0 d-flex flex-column w-100">
                <span>{title}</span>
                <span className={`text-primary qa-amount-${product}`}>
                  {amountGross}
                </span>
              </div>
            </div>
            <img
              className="w-100 enhanced-checkbox__image"
              src={checkoutImages[product]}
              alt={title}
            />
            <Card.Text className="pt-4">{text}</Card.Text>
          </Card.Body>
        </Card>
      </label>
    </Col>
  );
}

FeatureCheckbox.defaultProps = {
  isDisabled: false,
  type: 'checkbox',
  className: '',
};

FeatureCheckbox.propTypes = {
  feature: AvailableFeatureShape.isRequired,
  onChange: func.isRequired,
  isChecked: bool.isRequired,
  isDisabled: bool,
  type: string,
  className: string,
};

export default FeatureCheckbox;
