import { arrayOf, number, oneOf, shape, string } from 'prop-types';

export const BlueprintCategoryShape = oneOf(['wedding', 'business', 'sports']);

export const CheckoutAlbumShape = shape({
  title: string,
  blueprint_category: string,
  id: string,
  errors: arrayOf(string),
  total_sticker_sorts: number,
});

export const LineItemsShape = arrayOf(
  shape({
    product: string,
    title: string,
    description: string,
    quantity: number,
  })
);

export const CheckoutShape = shape({
  line_items: LineItemsShape.isRequired,
  album: CheckoutAlbumShape.isRequired,
  amount: number.isRequired,
  amount_gross: number.isRequired,
});

export const AvailableFeatureShape = shape({
  product: string.isRequired,
  title: string.isRequired,
  description: string,
  amount_net_human: string,
  text: string.isRequired,
  quantity: number,
});
