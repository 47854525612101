import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocale from '../../../../hooks/localization/useLocale';
import { setInlineStyle } from '../../../../modules/selection';
import { getSelectedTextProps } from '../../../../selectors/selection';
import { makeGetColorByIndexOrValue } from '../../../../selectors/colorsAndFonts';
import ColorDropdown from '../ColorDropdown';

function TextColorDropdown(props) {
  const { t } = useLocale();
  const dispatch = useDispatch();
  const { color: colorFromProps } = useSelector(getSelectedTextProps);
  const colorNumber = parseInt(colorFromProps, 10);
  const color = Number.isNaN(colorNumber) ? colorFromProps : colorNumber;
  const getColorByIndexOrValue = makeGetColorByIndexOrValue();
  const colorValue = useSelector(state => getColorByIndexOrValue(state, color));

  const handleColorChange = nextColor => {
    dispatch(setInlineStyle(`COLOR-${nextColor}`));
  };

  return (
    <ColorDropdown
      {...props}
      className="font-color qa-font-color"
      tooltip={t('editor.toolbar.textColor')}
      fieldName="text-color"
      onChange={handleColorChange}
      color={color}
      allowNull={false}
    >
      <svg width="22" height="20">
        <rect x="1" y="16" width="21" height="6" fill={colorValue} />
        <path
          d="M13.86 11.6h-5l-.95 2.85H4.88L10.03.6h2.64l5.18 13.84h-3.03l-.96-2.85ZM9.63 9.29h3.46L11.35 4.1 9.63 9.29Z"
          fill="black"
        />
      </svg>
    </ColorDropdown>
  );
}

export default TextColorDropdown;
