import React from 'react';
import { node, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import Icon from '../Icon';

function ButtonWithTooltip(props) {
  const { tooltip, icon, children, variant, placement, size, ...rest } = props;

  return (
    <OverlayTrigger
      overlay={<Tooltip>{tooltip}</Tooltip>}
      placement={placement}
    >
      <Button size={size} variant={variant} {...rest}>
        {icon && <Icon name={icon} />}
        {children}
      </Button>
    </OverlayTrigger>
  );
}

ButtonWithTooltip.defaultProps = {
  children: undefined,
  icon: undefined,
  placement: 'bottom',
  variant: 'light',
  size: 'sm',
};

ButtonWithTooltip.propTypes = {
  icon: string,
  tooltip: string.isRequired,
  children: node,
  placement: string,
  variant: string,
  size: string,
};

export default ButtonWithTooltip;
