/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  number,
  bool,
  object,
  func,
  oneOfType,
  elementType,
  string,
} from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { Flipped } from 'react-flip-toolkit';

import { generateId } from '../../../util/index';
import StickersList from './StickersList';
import SectionPlaceholder from './SectionPlaceholder';
import { SectionShape } from '../../shapes';
import { makeGetStickersForSection } from '../../../selectors/stickers';
import Icon from '../../Icon';
import { useConfirmation } from '../ConfirmModal/ConfirmationService';
import DropdownItemWithIcon from '../../generic/DropdownItemWithIcon';
import useTutorial from '../../../hooks/useTutorial';
import useAnalytics from '../../../containers/app/useAnalytics';
import useLocale from '../../../hooks/localization/useLocale';

function SectionOperationsDropdown({
  onRename,
  onMove,
  onDestroy,
  onGoTo,
  index,
  sectionsCount,
  isWizard,
}) {
  const confirm = useConfirmation();
  const { linkTo } = useTutorial();
  const { t } = useLocale();

  const canMoveUp = index > 1;
  const canMoveDown = index < sectionsCount - 2;

  const handleDestroy = isWizard
    ? onDestroy
    : () => {
        confirm({
          body: t('editor.sidebar.stickers.sectionDeleteWarning'),
        })
          .then(onDestroy)
          .catch(() => {});
      };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        className="no-caret qa-section-operations"
      >
        <Icon name="dots" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <DropdownItemWithIcon
          onClick={onGoTo}
          iconName="goto"
          className="qa-goto-section-btn"
        >
          {t('editor.sidebar.stickers.goToSection')}
        </DropdownItemWithIcon>
        <DropdownItemWithIcon
          onClick={onRename}
          iconName="edit"
          className="qa-section-rename-btn"
        >
          {t('rename')}
        </DropdownItemWithIcon>
        {canMoveUp && (
          <DropdownItemWithIcon
            onClick={() => onMove(index - 1)}
            iconName="chevron_up"
            className="qa-move-up-section-btn"
          >
            {t('moveUp')}
          </DropdownItemWithIcon>
        )}
        {canMoveDown && (
          <DropdownItemWithIcon
            onClick={() => onMove(index + 1)}
            iconName="chevron_down"
            className="qa-move-down-section-btn"
          >
            {t('moveDown')}
          </DropdownItemWithIcon>
        )}
        <Dropdown.Divider />
        <DropdownItemWithIcon
          onClick={handleDestroy}
          iconName="trash"
          className="qa-destroy-section-btn"
        >
          {t('delete')}
        </DropdownItemWithIcon>
        <Dropdown.Divider />
        <DropdownItemWithIcon
          iconName="help"
          className="text-muted"
          href={linkTo('sections')}
          target="_BLANK"
        >
          {t('editor.sidebar.stickers.sectionHelp')}
        </DropdownItemWithIcon>
      </Dropdown.Menu>
    </Dropdown>
  );
}

SectionOperationsDropdown.defaultProps = {
  isWizard: false,
};

SectionOperationsDropdown.propTypes = {
  onRename: func.isRequired,
  onMove: func.isRequired,
  onDestroy: func.isRequired,
  index: number.isRequired,
  sectionsCount: number.isRequired,
  onGoTo: func.isRequired,
  isWizard: bool,
};

function SectionInput({
  placeholder,
  section,
  sectionsCount,
  updateControls,
  createSection,
  historyAnchor,
  updateSection,
  isOver,
  onCollapseClick,
  open,
  dragRef,
  isWizard,
  moveSection,
  deleteSection,
  index,
  filter,
  deleteStickersForSection,
  resetViewToSectionId,
}) {
  const analytics = useAnalytics();
  const getStickersForSection = useMemo(makeGetStickersForSection, []);
  const stickersCount = useSelector(state =>
    placeholder ? 0 : getStickersForSection(state, section.id).length
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [nameValue, setNameValue] = useState(placeholder ? '' : section.name);
  const titleInputRef = useRef();
  const allowOperations = !(placeholder || section.static);

  useEffect(() => {
    if (isEditing) {
      titleInputRef.current.focus();
      titleInputRef.current.select();
    }
  }, [isEditing]);

  const handleRename = event => {
    if (isEditing || section?.static) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    setIsEditing(true);
    updateControls({ sectionDragEnabled: false });
  };

  const handleNameChange = ({ target: { value } }) => {
    setNameValue(value);
  };

  const handleNameBlur = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsEditing(false);
    updateControls({ sectionDragEnabled: true });

    if (placeholder) {
      if (nameValue !== '') {
        const newId = generateId();

        const newSection = {
          id: newId,
          name: nameValue,
        };

        createSection(newSection);
        historyAnchor();
        setNameValue('');

        analytics.track('Section Created', {
          name: nameValue,
          id: newId,
        });
      }
    } else {
      updateSection(section?.id, { name: nameValue });
      historyAnchor();

      analytics.track('Section Updated', {
        name: nameValue,
        id: section.id,
      });
    }
  };

  const handleNameKeyDown = event => {
    if (event.key === 'Enter') {
      titleInputRef.current.blur();
    }
  };

  const handleMove = nextIndex => {
    moveSection(section.id, nextIndex);
    historyAnchor();
  };

  const handleDestroy = () => {
    deleteStickersForSection(section.id);
    deleteSection(section.id);
    historyAnchor();
  };

  const handleGoTo = () => {
    resetViewToSectionId(section.id);
  };

  /**
   * `SectionPlaceholder`s are a special kind of section input. We render
   * them inside the album sidebar only, always at the bottom, to allow users
   * to add extra sections.
   */
  if (placeholder) {
    return (
      <SectionPlaceholder
        onHeaderClick={handleRename}
        onNameChange={handleNameChange}
        onNameBlur={handleNameBlur}
        handleNameKeyDown={handleNameKeyDown}
        editing={isEditing}
        name={nameValue}
        titleInputRef={titleInputRef}
        createSection={createSection}
        isWizard={isWizard}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        index={index}
      />
    );
  }

  /**
   * Static cover sections are hidden by default and should only be
   * rendered if they contain at least one sticker.
   */
  if (section.static && stickersCount === 0) {
    return null;
  }

  /**
   * We need the slug for our feature tests for now.
   * TODO: Drop this madness, it's unnecessary and cluttering the html.
   */
  const slug = placeholder
    ? 'new'
    : section.name?.replace(/ /g, '-').toLowerCase() || '';

  /**
   * We render the number of stickers per section into the respective
   * section's title.
   */
  const parsedSectionTitle = `${placeholder ? '' : section.name}${
    stickersCount ? ` (${stickersCount})` : ''
  }`;

  /**
   * We only display buttons on section hover. The only difference
   * between editor and wizard here is the toggle open/close button
   * which is not rendered in the wizard (because there are no stickers).
   */
  const hideButtons = isEditing || !isHovered || section.static;

  return (
    <Flipped flipId={section.id}>
      <div
        ref={allowOperations ? dragRef : null}
        className={`border-bottom qa-section-item qa-section-${slug} qa-section-${section?.id}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`sections d-flex align-items-center px-1 ${
            isOver ? ' dnd-hover' : ''
          }`}
          key={section?.id}
        >
          <Button
            className="text-dark"
            style={{
              visibility: (hideButtons || !allowOperations) && 'hidden',
            }}
            variant="link"
          >
            <Icon name="drag" />
          </Button>
          {!isWizard && (
            <Icon
              style={{ lineHeight: 1, opacity: 0.3 }}
              className="qa-toggle-section-btn mr-2"
              name={open ? 'chevron_up' : 'chevron_down'}
              onClick={onCollapseClick}
            />
          )}
          <div className="w-100">
            <div
              className={`w-100 d-flex justify-content-between align-items-center qa-section-header-${slug} qa-section-header-${section?.id}`}
            >
              <span
                className="m-0 py-4 w-100 edit-section-title qa-edit-section-title"
                onClick={onCollapseClick}
                role="button"
                style={{ cursor: !allowOperations && 'default' }}
                tabIndex={0}
              >
                {isEditing ? (
                  <Form.Control
                    type="text"
                    name="section_name"
                    value={nameValue}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    onKeyDown={handleNameKeyDown}
                    ref={titleInputRef}
                    placeholder="Name..."
                    maxLength={50}
                  />
                ) : (
                  <h6
                    variant="link"
                    className={`m-0 ${isHovered && 'text-primary'}`}
                    style={{ overflow: 'hidden' }}
                  >
                    {parsedSectionTitle}
                  </h6>
                )}
              </span>
              {!hideButtons && (
                <SectionOperationsDropdown
                  onRename={handleRename}
                  index={index}
                  sectionsCount={sectionsCount}
                  onMove={handleMove}
                  onDestroy={handleDestroy}
                  onGoTo={handleGoTo}
                  isWizard={isWizard}
                />
              )}
            </div>
          </div>
        </div>
        {open && !placeholder && (
          <StickersList filter={filter} sectionId={section?.id} />
        )}
      </div>
    </Flipped>
  );
}

SectionInput.defaultProps = {
  section: null,
  placeholder: false,
  onCollapseClick: null,
  open: false,
  isWizard: false,
  index: null,
  moveSection: () => {},
  sectionsCount: 0,
  filter: '',
};

SectionInput.propTypes = {
  section: SectionShape,
  isOver: bool.isRequired,
  placeholder: bool,
  dragRef: oneOfType([elementType, object]).isRequired,
  updateSection: func.isRequired,
  updateControls: func.isRequired,
  createSection: func.isRequired,
  historyAnchor: func.isRequired,
  open: bool,
  onCollapseClick: func,
  isWizard: bool,
  index: number,
  moveSection: func,
  deleteSection: func.isRequired,
  sectionsCount: number,
  filter: string,
  deleteStickersForSection: func.isRequired,
  resetViewToSectionId: func.isRequired,
};

export default SectionInput;
