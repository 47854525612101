import React from 'react';
import { func, shape, string, number } from 'prop-types';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { getCurrentBlueprintData } from '../../../selectors/blueprints';
import { resolutions } from '../../../constants';
import useLocale from '../../../hooks/localization/useLocale';
import SpreadPreview from '../LayoutsPanel/SpreadPreview';
import { ImageContext } from '../../ImageContext';
import { findNodesByType } from '../../../util/workspace';

function LayoutSelectionStep({ onLayoutSelect, selectedLayout }) {
  const { t } = useLocale();

  const { workspace, images } = useSelector(getCurrentBlueprintData) || {};

  if (!workspace) {
    return <div>{t('editor.spreadCreationModal.loading')}</div>;
  }

  const IMAGE_CONTEXT = {
    resolution: resolutions.small,
    showStickers: true,
    cropPreview: true,
    showGuides: false,
    rendering: false,
    stickerRendering: false,
    images,
  };

  const { nodes } = workspace;
  const sectionIds = nodes.root.children;
  const spreadIds = flatten(
    sectionIds.map(sectionId => nodes[sectionId].children)
  );

  const handleLayoutClick = spreadId => {
    // Check if the spread node exists and has children
    const spreadNode = nodes[spreadId];
    if (!spreadNode?.children) {
      return;
    }

    // Find sticker cells and image nodes, and count them using .length
    const stickerSlots = Math.max(
      1,
      findNodesByType(nodes, spreadId, 'StickerCell').length
    );

    const backgroundSlots = Math.max(
      0,
      findNodesByType(nodes, spreadId, 'Image').length
    );

    const layoutInfo = {
      id: spreadId,
      stickerSlots,
      backgroundSlots,
    };

    onLayoutSelect(layoutInfo);
  };

  return (
    <div className="layout-selection-step qa-layout-selection-step">
      <p className="mb-3">
        {t('editor.spreadCreationModal.layoutInstructions')}
      </p>

      <ImageContext.Provider value={IMAGE_CONTEXT}>
        <Row className="layout-grid px-3">
          {spreadIds
            .map((spreadId, spreadIndex) => {
              const isCover =
                spreadIndex === 0 || spreadIndex === spreadIds.length - 1;

              if (isCover) {
                return null;
              }

              const isSelected = selectedLayout?.id === spreadId;

              return (
                <Col xs={12} sm={6} md={4} key={spreadId} className="mb-2 px-0">
                  <Card
                    className={`layout-card qa-layout-card ${
                      isSelected ? 'selected' : ''
                    } p-3`}
                    onClick={() => handleLayoutClick(spreadId)}
                    bg={isSelected ? 'secondary-subtle' : ''}
                  >
                    <div className="d-flex justify-content-center qa-layout-option">
                      <SpreadPreview
                        spreadId={spreadId}
                        spreadIndex={spreadIndex}
                        workspace={workspace}
                        spreadsCount={spreadIds.length}
                        sectionNode={nodes[nodes[spreadId].parent]}
                      />
                    </div>
                  </Card>
                </Col>
              );
            })
            .filter(Boolean)}
        </Row>
      </ImageContext.Provider>
    </div>
  );
}

LayoutSelectionStep.propTypes = {
  onLayoutSelect: func.isRequired,
  selectedLayout: shape({
    id: string.isRequired,
    name: string,
    stickerSlots: number,
    backgroundSlots: number,
  }),
};

LayoutSelectionStep.defaultProps = {
  selectedLayout: null,
};

export default LayoutSelectionStep;
