/*

  # blockData
 "blockData" is a way to store custom data related to a block.

 we use it to store style-information at block-level, since draft does not support block-styling out of the box.
 this is used for two seperate purposes:
 - allow empty blocks to have a consistent line-height (relevant for the line-height are SIZE, FONT, LINEHEIGHT)
 - allow block text-alignment (ALIGN)

 # <BlockWrapper/>
 during rendering, the <BlockWrapper/> component converts this info to a <div/> with inline-css, which all
 unstyles <span/>-nodes within that block inherit from.

 # handleBlockSplit
 when return is pressed, the current block is split into to two new blocks. the new block needs inherits
 blockData from the current inline-style (the styling at the current cursor position, for SIZE, FONT, LINEHEIGHT)
 and also the source block (for ALIGN).

*/

import { EditorState, Modifier } from 'draft-js';
import { getSelectedBlocksMap } from 'draftjs-utils';
import Immutable from 'immutable';

export const HANDLED = 'handled';
export const NOT_HANDLED = 'not-handled';

export const defaultBlockStyle = Immutable.Map({
  FONT: 'FONT-RUBIK',
  SIZE: 'SIZE-11',
  LINEHEIGHT: 'LINEHEIGHT-100%',
  ALIGN: 'ALIGN-LEFT',
});

export const fontSizes = [
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  36,
  48,
  72,
];

export const textAligns = [
  { value: 'LEFT', icon: 'alignleft', label: 'editor.toolbar.alignLeft' },
  { value: 'CENTER', icon: 'aligncenter', label: 'editor.toolbar.alignCenter' },
  { value: 'RIGHT', icon: 'alignright', label: 'editor.toolbar.alignRight' },
  {
    value: 'JUSTIFY',
    icon: 'alignjustify',
    label: 'editor.toolbar.alignJustify',
  },
];

export const lineHeights = [
  {
    value: '100%',
    icon: 'lineheight100',
    label: '100%',
    name: '100',
  },
  {
    value: '120%',
    icon: 'lineheight120',
    label: '120%',
    name: '120',
  },
  {
    value: '150%',
    icon: 'lineheight150',
    label: '150%',
    name: '150',
  },
  {
    value: '200%',
    icon: 'lineheight200',
    label: '200%',
    name: '200',
  },
];

export const blockAlignMap = textAligns.reduce(
  (acc, cur) => ({
    ...acc,
    [`ALIGN-${cur.value}`.toUpperCase()]: { textAlign: cur.value },
  }),
  {}
);

export const inlineStyleListToMap = list =>
  list.reduce((acc, style) => {
    const [key] = style.split('-');
    return acc.set(key, style);
  }, Immutable.Map());

export const handleBlockSplit = editorState => {
  // from https://github.com/facebook/draft-js/issues/723#issuecomment-367918580
  const newContentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );

  let splitState = EditorState.push(
    editorState,
    newContentState,
    'split-block'
  );

  // first merge all selected block styles...
  const allSelectedBlockStyles = getSelectedBlocksMap(editorState).reduce(
    (acc, cur) => acc.merge(cur.getData()),
    defaultBlockStyle
  );

  // ...fill up with current inline style
  const allSelectedStyles = allSelectedBlockStyles.merge(
    inlineStyleListToMap(editorState.getCurrentInlineStyle())
  );

  // ... limit them to line height and block relevant styles
  const blockData = allSelectedStyles.filter((_, key) =>
    ['SIZE', 'LINEHEIGHT', 'FONT', 'ALIGN'].includes(key)
  );

  const afterMergeStylesContentState = Modifier.mergeBlockData(
    splitState.getCurrentContent(),
    splitState.getSelection(),
    blockData
  );

  splitState = EditorState.push(
    editorState,
    afterMergeStylesContentState,
    'split-block'
  );

  return splitState;
};
