import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { func, node } from 'prop-types';

import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import { getSectionsAvailiable } from '../../../../selectors/stickers';
import FileInputButton from '../../../generic/FileInputButton';

function LocalStickerUploadsButton({ onChange, children }) {
  const sectionsAvailiable = useSelector(getSectionsAvailiable);

  const { isLoading } = useLoading(commonTypes.uploadingFiles);

  /**
   * If there are no non-static sections, we disable the button and show a tooltip.
   * The button wrapped in a div because a disabled button can not trigger an overlay.
   */
  if (!sectionsAvailiable) {
    return (
      <OverlayTrigger
        overlay={<Tooltip>Bitte lege vorher ein Kapitel an</Tooltip>}
        placement="bottom"
      >
        <div className="d-inline m-0 w-30">
          <Button
            variant="outline-secondary"
            disabled
            className="qa-sticker-upload-button w-100"
          >
            Upload
          </Button>
        </div>
      </OverlayTrigger>
    );
  }

  return (
    <FileInputButton
      variant="outline-primary"
      name="qa-sticker-image-upload"
      className="qa-sticker-upload-button m-0 w-30"
      onChange={onChange}
      disabled={isLoading}
      accept="image/jpeg, image/png"
      multiple
    >
      {isLoading ? <Spinner size="sm" animation="border" /> : children}
    </FileInputButton>
  );
}

LocalStickerUploadsButton.propTypes = {
  onChange: func.isRequired,
  children: node.isRequired,
};

export default LocalStickerUploadsButton;
