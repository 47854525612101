import React from 'react';
import { number, arrayOf, shape, string } from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Icon from '../../../Icon';
import useScreenSize from '../../../../hooks/useScreenSize';
import useLocale from '../../../../hooks/localization/useLocale';

function StepHeader({ activeIndex, tabs }) {
  const visibleSteps = tabs.filter(({ ignoreInCount }) => !ignoreInCount);
  const { isMobile } = useScreenSize();
  const { t } = useLocale();

  return (
    <div className="wizard-content-head mt-4">
      <Accordion defaultActiveKey={isMobile ? '' : '0'}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="d-flex bg-transparent border-bottom-0 justify-content-center p-0"
        >
          <h4 className="m-0">
            {visibleSteps.length > 1 &&
              `${activeIndex + 1} / ${visibleSteps.length}`}{' '}
            {t(tabs[activeIndex].title)}
          </h4>
          <Icon name="infoCircle" className="pl-2" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="w-100 mb-3 mt-0 mx-auto text-secondary font-weight-light step-content">
            {t(tabs[activeIndex].content)}
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}

StepHeader.propTypes = {
  tabs: arrayOf(
    shape({
      title: string,
      content: string,
    })
  ).isRequired,
  activeIndex: number.isRequired,
};

export default StepHeader;
