import { number, string, func } from 'prop-types';
import React, { useRef, useLayoutEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { CheckoutFormDataShape } from '../../components/shapes';
import { countryOptions } from '../../constants';
import useScreenSize from '../../hooks/useScreenSize';
import useLocale from '../../hooks/localization/useLocale';

function CheckoutContactForm({
  blueprintCategory,
  formData,
  setFormData,
  commentMaxLength,
}) {
  const { t } = useLocale();
  const { isMobile } = useScreenSize();
  const inputRef = useRef();

  useLayoutEffect(() => {
    if (isMobile) {
      return;
    }
    inputRef.current?.focus();
  }, [isMobile]);

  const createHandler = key => ({ target: { value } }) =>
    setFormData(nextFormData => ({ ...nextFormData, [key]: value }));

  return (
    <>
      <h6 className="mb-4">
        <b>{t('checkout.contactHeading')}</b>
      </h6>
      <Form.Group>
        <Form.Label>{t('checkout.email')}</Form.Label>
        <Form.Control
          required
          type="email"
          value={formData.email}
          name="email"
          onChange={createHandler('email')}
        />
      </Form.Group>
      <h6 className="mt-5 mb-4">
        <b>{t('checkout.deliveryBillingAddress')}</b>
      </h6>
      <Form.Group>
        {blueprintCategory !== 'wedding' && (
          <Form.Group>
            <Form.Label>{t('checkout.organizationLabel')}</Form.Label>
            <Form.Control
              required
              value={formData.organization}
              name="organization"
              onChange={createHandler('organization')}
            />
          </Form.Group>
        )}
        <Row>
          <Col>
            <Form.Label>{t('checkout.firstName')}</Form.Label>
            <Form.Control
              required
              className="qa-first-name-input"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={createHandler('firstName')}
              ref={inputRef}
            />
          </Col>
          <Col>
            <Form.Label>{t('checkout.lastName')}</Form.Label>
            <Form.Control
              required
              className="qa-last-name-input"
              type="text"
              value={formData.lastName}
              name="lastName"
              onChange={createHandler('lastName')}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('checkout.streetAndNumber')}</Form.Label>
        <Form.Control
          required
          className="qa-address1-input"
          type="text"
          value={formData.address1}
          name="address1"
          onChange={createHandler('address1')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {t('checkout.addressAddition')}{' '}
          <small className="text-secondary">({t('checkout.optional')})</small>
        </Form.Label>
        <Form.Control
          required
          className="qa-address2-input"
          type="text"
          value={formData.address2}
          name="address2"
          onChange={createHandler('address2')}
        />
      </Form.Group>
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>{t('checkout.zipCode')}</Form.Label>
            <Form.Control
              required
              className="qa-zipCode-input"
              type="text"
              value={formData.zipCode}
              name="zipCode"
              onChange={createHandler('zipCode')}
            />
          </Col>
          <Col>
            <Form.Label>{t('checkout.city')}</Form.Label>
            <Form.Control
              required
              className="qa-city-input"
              type="text"
              value={formData.city}
              name="city"
              onChange={createHandler('city')}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('checkout.country')}</Form.Label>
        <Form.Control
          as="select"
          value={formData.countryCode}
          className="qa-countryCode-input"
          name="countryCode"
          onChange={createHandler('countryCode')}
        >
          {countryOptions.map(option => (
            <option key={option.value} value={option.value}>
              {t(option.label)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {t('checkout.phone')}{' '}
          <small className="text-secondary">({t('checkout.optional')})</small>
        </Form.Label>
        <Form.Control
          className="qa-phone-input"
          type="phone"
          value={formData.phone}
          name="phone"
          onChange={createHandler('phone')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          {t('checkout.quoteNumber')}{' '}
          <small className="text-secondary">({t('checkout.optional')})</small>
        </Form.Label>
        <Form.Control
          className="qa-quoteName-input"
          type="phone"
          value={formData.quoteName}
          name="quoteName"
          onChange={createHandler('quoteName')}
        />
      </Form.Group>
      <h6 className="mt-5 mb-4">
        <b>{t('checkout.remarks')}</b>
      </h6>
      <Form.Group>
        <Form.Control
          as="textarea"
          className="qa-comment-input"
          rows={4}
          name="comment"
          placeholder={t('checkout.commentPlaceholder')}
          value={formData.comment}
          onChange={createHandler('comment')}
          maxLength={commentMaxLength}
        />
      </Form.Group>
    </>
  );
}

CheckoutContactForm.propTypes = {
  formData: CheckoutFormDataShape.isRequired,
  commentMaxLength: number.isRequired,
  blueprintCategory: string.isRequired,
  setFormData: func.isRequired,
};

export default CheckoutContactForm;
