import cc from '../util/cc';

export const SET_USER = 'user/set';
export const SET_COOKIE_CONSENT = 'user/COOKIE_CONSENT';
export const SET_ORGANIZATIONS = 'user/ORGANIZATIONS';
export const SET_LOCALE = 'user/SET_LOCALE';

const { accepted_categories: acceptedCategories } = cc.getUserPreferences();
const getCookieConsent = () => acceptedCategories.includes('necessary');

const SUPPORTED_LOCALES = ['en', 'de', 'fr', 'it', 'pl'];
export const [defaultLocale] = SUPPORTED_LOCALES;

export function detectLocale() {
  const browserLanguages = [];

  if (navigator.languages?.length) {
    browserLanguages.push(
      ...navigator.languages.map(lang => lang.split('-')[0])
    );
  }

  if (navigator.language) {
    browserLanguages.push(navigator.language.split('-')[0]);
  }

  const preferredLocale = browserLanguages.find(lang =>
    SUPPORTED_LOCALES.includes(lang)
  );

  return preferredLocale || defaultLocale;
}

const initialState = {
  currentUser: {},
  cookieConsent: getCookieConsent(),
  organizations: [],
  locale: detectLocale(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: action.payload,
      };
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

// Set the current user
export const setCurrentUser = payload => dispatch =>
  dispatch({ type: SET_USER, payload });

export const setCookieConsent = payload => dispatch =>
  dispatch({ type: SET_COOKIE_CONSENT, payload });

export const setOrganizations = payload => dispatch =>
  dispatch({ type: SET_ORGANIZATIONS, payload });

export const setUserLocale = payload => dispatch =>
  dispatch({ type: SET_LOCALE, payload });
