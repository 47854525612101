import { bool, func, string } from 'prop-types';
import React from 'react';

import { stickerTemplateIds } from '../../../../../../constants';
import useAnalytics from '../../../../../../containers/app/useAnalytics';
import Icon from '../../../../../Icon';
import { StylesPanelStateMachineShape } from '../../../../../shapes';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import StickerTemplateView from '../StickerTemplateView';
import useLocale from '../../../../../../hooks/localization/useLocale';

export function StickerTemplateButton({ onClick, stickerTemplateId, active }) {
  return (
    <div
      onClick={onClick}
      className={`sticker-template-button qa-sticker-template-button ${
        active ? 'active qa-active' : ''
      }`}
    >
      <StickerTemplateView stickerTemplateId={stickerTemplateId} />
      {active && <Icon name="checkmark" className="qa-active-icon" />}
    </div>
  );
}

StickerTemplateButton.propTypes = {
  onClick: func.isRequired,
  stickerTemplateId: string.isRequired,
  active: bool.isRequired,
};

function StickerTemplateList({ stateMachine, isWizard }) {
  const { t } = useLocale();
  const { stickerTemplateId, setStickerTemplateId, send } = stateMachine;

  const analytics = useAnalytics();

  const handleClick = nextStickerTemplateId => {
    setStickerTemplateId(nextStickerTemplateId);

    analytics.track(`${isWizard ? 'Wizard ' : ''}Sticker Template Updated`, {
      stickerTemplateId: nextStickerTemplateId,
    });
  };

  return (
    <div className="d-flex flex-column">
      <DropoutTitle
        label={t('editor.sidebar.styles.stickers')}
        onBack={() => send('back')}
      />
      <DropoutSection>
        <div className="sticker-template-list">
          {stickerTemplateIds.map(someStickerTemplateId => (
            <StickerTemplateButton
              key={someStickerTemplateId}
              stickerTemplateId={someStickerTemplateId}
              onClick={() => handleClick(someStickerTemplateId)}
              active={stickerTemplateId === someStickerTemplateId}
            />
          ))}
        </div>
      </DropoutSection>
    </div>
  );
}

StickerTemplateList.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
  isWizard: bool.isRequired,
};

export default StickerTemplateList;
