import React from 'react';
import { func, node, string } from 'prop-types';
import Button from 'react-bootstrap/Button';

import useLocale from '../../../../../hooks/localization/useLocale';

export function DropoutTitle({ label, onBack }) {
  const { t } = useLocale();

  return (
    <div className="d-flex align-items-center qa-dropout-title">
      {onBack && (
        <Button className="p-0 pr-3 qa-go-back" variant="link" onClick={onBack}>
          {`< ${t('back').toLowerCase()}`}
        </Button>
      )}
      {label}
    </div>
  );
}

DropoutTitle.defaultProps = {
  onBack: null,
};

DropoutTitle.propTypes = {
  label: string.isRequired,
  onBack: func,
};

function DropoutSection({ children, className, onClick, title }) {
  return (
    <div className={`dropout-section py-3 ${className}`} onClick={onClick}>
      {title && <div className="text-muted mb-2">{title}</div>}
      {children}
    </div>
  );
}

DropoutSection.defaultProps = {
  className: '',
  onClick: undefined,
  title: undefined,
};

DropoutSection.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  title: string,
};

export default DropoutSection;
