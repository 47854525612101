import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { shape, bool } from 'prop-types';

import { useFormState } from '../FormStateProvider';
import useLocale from '../../../hooks/localization/useLocale';

function Data({ config: { requirePosition } }) {
  const { t } = useLocale();
  const { name, lastName, position, setState } = useFormState();
  const nameInputRef = useRef();

  useEffect(() => {
    nameInputRef.current.focus();
  }, []);

  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('stickerUploadForm.nameLabel')}</Form.Label>
        {/**
         * Why a max-length of 39? These fields are concenated with one space-
         * char and the total max-length is 80 for sticker-names.
         */}
        <Form.Control
          ref={nameInputRef}
          size="lg"
          value={name}
          name="name"
          onChange={({ target: { value } }) => setState('name', value)}
          type="text"
          placeholder={t('stickerUploadForm.namePlaceholder')}
          maxLength={30}
        />
        <Form.Text className="text-muted">
          {t('stickerUploadForm.dataProtection')}{' '}
          <a
            href="https://www.stickerstars.de/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('stickerUploadForm.dataProtectionLink')}
          </a>{' '}
          {t('stickerUploadForm.dataProtectionInfo')}
        </Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('stickerUploadForm.lastNameLabel')}</Form.Label>
        <Form.Control
          value={lastName}
          name="last_name"
          onChange={({ target: { value } }) => setState('lastName', value)}
          size="lg"
          type="text"
          placeholder={t('stickerUploadForm.lastNamePlaceholder')}
          maxLength={30}
        />
      </Form.Group>
      {requirePosition && (
        <Form.Group>
          <Form.Label>{t('stickerUploadForm.positionLabel')}</Form.Label>
          <Form.Control
            value={position}
            name="position"
            onChange={({ target: { value } }) => setState('position', value)}
            size="lg"
            type="text"
            placeholder={t('stickerUploadForm.positionPlaceholder')}
            maxLength={30}
          />
        </Form.Group>
      )}
    </Form>
  );
}

Data.propTypes = {
  config: shape({
    requirePosition: bool,
  }),
};

Data.defaultProps = {
  config: {
    requirePosition: false,
  },
};

export default Data;
