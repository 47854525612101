import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import {
  selectStickerUploadsPath,
  selectStickerUploadConfig,
} from '../../../selectors/albums';
import { selectStickerUploads } from '../../../selectors/stickerUploads';
import StickerLinkSettings from './StickerLinkSettings';
import useTutorial from '../../../hooks/useTutorial';
import useAnalytics from '../../../containers/app/useAnalytics';
import ButtonWithTooltip from '../../menu/ButtonWithTooltip';
import useLocale from '../../../hooks/localization/useLocale';

function StickerLinkPanel() {
  const { linkTo } = useTutorial();
  const { t } = useLocale();
  const analytics = useAnalytics();

  const stickerUploads = useSelector(selectStickerUploads);
  const uploadsPath = useSelector(selectStickerUploadsPath);
  const { enabled, whiteLabel, domain } = useSelector(
    selectStickerUploadConfig
  );

  const fullUploadsUrl = `https://${
    whiteLabel && domain ? domain : window.location.host
  }${uploadsPath}`;

  const uploadsUrlRef = useRef();

  function handleCopy() {
    uploadsUrlRef.current.select();
    navigator.clipboard.writeText(uploadsUrlRef.current.value);
    analytics.track('StickerLink URL Copied');
  }

  return (
    <div className="sidebar-panel scrollable py-3">
      <Card
        bg={enabled ? 'primary' : 'secondary'}
        text="light"
        className="mb-3"
      >
        <Card.Header>{t('editor.sidebar.stickerlink.status')}</Card.Header>
        <Card.Body>
          <Card.Title>
            <b>
              {t('editor.sidebar.stickerlink.headline', {
                count: stickerUploads.length,
              })}
            </b>
          </Card.Title>
          <Card.Text>
            {t(
              `editor.sidebar.stickerlink.subline${
                enabled ? 'Active' : 'Inactive'
              }`
            )}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card bg="light" text="dark" className="mb-3">
        <Card.Header>{t('editor.sidebar.stickerlink.yourLink')}</Card.Header>
        <Card.Body>
          <Card.Text>
            {t('editor.sidebar.stickerlink.description')}{' '}
            <a
              href={linkTo('stickerlink')}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              {t('learnMore')}
            </a>
          </Card.Text>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              className="qa-sticker-uploads-url"
              value={fullUploadsUrl}
              ref={uploadsUrlRef}
              readOnly
            />
            <ButtonWithTooltip
              icon="clipboard"
              tooltip={t('editor.sidebar.stickerlink.copyLink')}
              onClick={handleCopy}
              variant="dark"
            />
          </InputGroup>
        </Card.Body>
      </Card>
      <StickerLinkSettings uploadsUrl={fullUploadsUrl} />
    </div>
  );
}

export default StickerLinkPanel;
