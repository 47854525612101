import React from 'react';
import { useSelector } from 'react-redux';

import Root from '../elements/Root';
import Selector from '../Selector';
import Viewport from '../Viewport';
import { selectIsAlbumEditable } from '../../../selectors/albums';
import HistoryControls from './HistoryControls';
import { selectCanRedo, selectCanUndo } from '../../../selectors/controls';

function MobileWorkspace() {
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const canUndo = useSelector(selectCanUndo);
  const canRedo = useSelector(selectCanRedo);

  return (
    <div className="workspace mobile qa-mobile-workspace">
      <Viewport>
        <Root preview={!isAlbumEditable} />
        <Selector />
      </Viewport>
      {isAlbumEditable && (canUndo || canRedo) && (
        <>
          <HistoryControls />
        </>
      )}
    </div>
  );
}

MobileWorkspace.propTypes = {};

export default MobileWorkspace;
