/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';

import { generateSpread } from '../../util/generators';
import { insertElements } from '../../actions/workspace';
import { historyAnchor } from '../../modules/history';
import { selectAlbumData } from '../../selectors/albums';
import useAnalytics from '../../containers/app/useAnalytics';
import { resetViewToSpreadIndex } from '../../modules/controls';
import { clearSelection } from '../../modules/selection';
import useLocale from '../../hooks/localization/useLocale';
import { colorValues } from '../../constants';
import useScreenSize from '../../hooks/useScreenSize';
import SpreadCreationModal from '../ui/SpreadCreationModal';

const InsertSpreadButton = ({ spreadIndex, sectionId, indexInSection }) => {
  const { t } = useLocale();
  const { id: albumId } = useSelector(selectAlbumData);
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { isMobile } = useScreenSize();
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    if (isMobile) {
      setShowModal(true);
      return;
    }

    dispatch(clearSelection());
    const spread = generateSpread();

    dispatch(insertElements([spread], sectionId, indexInSection + 1));
    dispatch(historyAnchor());

    analytics.track('Spread Created');
    dispatch(resetViewToSpreadIndex(spreadIndex + 1));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const size = 24;

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{t('editor.spreadMeta.addSpread')}</Tooltip>}
      >
        <Link to={isMobile ? '#' : `/album/${albumId}/layouts`}>
          <g
            className="insert-spread-button qa-insert-spread-button"
            onClick={handleClick}
          >
            <rect width={size} height={size} fill="none" />
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="6"
              ry="6"
              fill="white"
              stroke="#e1e1e1"
              strokeWidth="1"
            />
            <path
              className="icon"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 12.0892C16 12.3653 15.7761 12.5892 15.5 12.5892H13V15.0892C13 15.3653 12.7761 15.5892 12.5 15.5892H11.5C11.2239 15.5892 11 15.3653 11 15.0892V12.5892H8.5C8.22386 12.5892 8 12.3653 8 12.0892V11.0892C8 10.8131 8.22386 10.5892 8.5 10.5892H11V8.0892C11 7.81306 11.2239 7.5892 11.5 7.5892H12.5C12.7761 7.5892 13 7.81306 13 8.0892V10.5892H15.5C15.7761 10.5892 16 10.8131 16 11.0892V12.0892Z"
              fill={colorValues.selection}
            />
          </g>
        </Link>
      </OverlayTrigger>

      {/* Only render the modal on mobile devices */}
      {isMobile && (
        <SpreadCreationModal
          open={showModal}
          onClose={handleModalClose}
          targetSectionId={sectionId}
          insertionIndex={indexInSection + 1}
        />
      )}
    </>
  );
};

InsertSpreadButton.defaultProps = {
  sectionId: null,
};

InsertSpreadButton.propTypes = {
  spreadIndex: number.isRequired,
  indexInSection: number.isRequired,
  sectionId: string,
};

export default InsertSpreadButton;
