import React from 'react';

import ColorTiles from '../ColorTiles';
import FontTiles from '../FontTiles';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import { StylesPanelStateMachineShape } from '../../../../../shapes';
import StickerTemplateView from '../StickerTemplateView';
import useLocale from '../../../../../../hooks/localization/useLocale';

function StylesSelector({ stateMachine }) {
  const { t } = useLocale();
  const { send, colors, fonts, stickerTemplateId } = stateMachine;

  return (
    <div className="d-flex flex-column">
      <DropoutTitle label={t('editor.sidebar.styles.headline')} />
      <DropoutSection
        className="cursor-pointer mb-2 qa-colors-selector-link"
        onClick={() => send('colorsClick')}
        title={t('editor.sidebar.styles.colors')}
      >
        <ColorTiles colors={colors.slice(2)} />
      </DropoutSection>
      <DropoutSection
        className="cursor-pointer qa-fonts-list-link"
        onClick={() => send('fontsClick')}
        title={t('editor.sidebar.styles.fonts')}
      >
        <FontTiles fonts={fonts} />
      </DropoutSection>
      <DropoutSection
        className="cursor-pointer qa-sticker-template-link"
        onClick={() => send('stickerTemplateClick')}
        title={t('editor.sidebar.styles.stickers')}
      >
        <div className="d-flex justify-content-center rounded border">
          <StickerTemplateView
            stickerTemplateId={stickerTemplateId}
            className="p-3 w-50"
          />
        </div>
      </DropoutSection>
    </div>
  );
}

StylesSelector.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
};

export default StylesSelector;
