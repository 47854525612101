import React, { useLayoutEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Link, Route, useRouteMatch } from 'react-router-dom';

import MobileFooterMenu from '../../../../containers/editor/FooterMenu.mobile';
import {
  selectAlbumLoaded,
  selectIsAlbumEditable,
} from '../../../../selectors/albums';
import Icon from '../../../Icon';
import useScopedSidebarTabs from '../useScopedSidebarTabs';
import { smoothScrollElementToView } from '../util';
import editorTabs from './editorTabs';
import MobileEditorSidebar from './index.mobile';
import TabIcon from './TabIcon';

function MobileTabNav() {
  const { tabs, activeTab } = useScopedSidebarTabs(editorTabs);
  const albumLoaded = useSelector(selectAlbumLoaded);
  const activeKey = activeTab?.key;
  const navbarRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  const prevBtnVisible = scrollLeft > 0;
  const nextBtnVisible = navbarRef.current
    ? Math.round(scrollLeft + navbarRef.current.clientWidth) !==
      Math.round(navbarRef.current.scrollWidth)
    : false;

  const {
    params: { albumId },
  } = useRouteMatch();

  const makeLinkTo = key => {
    return activeKey === key ? `/album/${albumId}` : `/album/${albumId}/${key}`;
  };

  const scrollTabNav = offset => {
    navbarRef.current.scrollTo({
      left: navbarRef.current.scrollLeft + offset,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    setScrollLeft(navbarRef.current.scrollLeft);
  };

  // we need to scroll to the active navlink.
  useLayoutEffect(() => {
    if (navbarRef.current) {
      smoothScrollElementToView(navbarRef.current);
    }
  }, [albumLoaded, activeKey]);

  if (!isAlbumEditable) {
    return null;
  }

  return (
    <>
      <MobileFooterMenu>
        <>
          {prevBtnVisible && (
            <Button
              className="mobile-tabnav-tabs__scroll-btn mobile-tabnav-tabs__scroll-btn--prev"
              variant="light"
              onClick={() => scrollTabNav(-70)}
            >
              <Icon name="chevron_left" />
            </Button>
          )}

          <div
            onScroll={handleScroll}
            className="mobile-tabnav-tabs qa-navbar-mobile-tabs"
            ref={navbarRef}
          >
            {tabs.map(tab => (
              <Link
                key={tab.key}
                to={makeLinkTo(tab.key)}
                className={`mobile-tabnav-tabs__tab ${
                  activeKey === tab.key ? 'active' : ''
                } qa-nav-${tab.key}-link`}
              >
                <TabIcon {...tab} />
              </Link>
            ))}
          </div>
          {nextBtnVisible && (
            <Button
              variant="light"
              className="mobile-tabnav-tabs__scroll-btn mobile-tabnav-tabs__scroll-btn--next qa-navbar-mobile-tabs-next"
              onClick={() => scrollTabNav(70)}
            >
              <Icon name="chevron_right" />
            </Button>
          )}
        </>
      </MobileFooterMenu>
      <Route key="mobile-sidebar" path="/album/:albumId/:key">
        <MobileEditorSidebar />
      </Route>
    </>
  );
}

MobileTabNav.propTypes = {};

export default MobileTabNav;
