import React from 'react';

import { configShape } from '../shapes';
import useLocale from '../../../hooks/localization/useLocale';

function Welcome({ config: { content, title } }) {
  const { t } = useLocale();

  return (
    <>
      <h5>{title}</h5>
      <h2 className="font-weight-bold mb-3">
        {t('stickerUploadForm.uploaderHeading')}
      </h2>
      <p style={{ whiteSpace: 'pre-line' }}>{content}</p>
    </>
  );
}

Welcome.propTypes = {
  config: configShape.isRequired,
};

export default Welcome;
