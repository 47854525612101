import React, { useState } from 'react';
import { func, string } from 'prop-types';
import isEqual from 'lodash/isEqual';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import { useDispatch, useSelector } from 'react-redux';

import { fonts } from '../../constants';
import useApi from '../../hooks/useApi';
import { replaceFonts } from '../../modules/colorsAndFonts';
import useNotifications from '../../hooks/useNotifications';
import useLocale from '../../hooks/localization/useLocale';

function FontDropDown({ value, onChange }) {
  return (
    <Dropdown className="qa-font-dd" as={ButtonGroup}>
      <Dropdown.Toggle as={Button} variant="light">
        {value}
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight
        style={{ maxHeight: '300px' }}
        className="w-100 scrollable"
      >
        {fonts.map(option => (
          <Dropdown.Item key={option} onClick={() => onChange(option)}>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

FontDropDown.defaultProps = {
  value: '',
};

FontDropDown.propTypes = {
  value: string,
  onChange: func.isRequired,
};

export function FontSwatchPicker({ albumId }) {
  const { t } = useLocale();
  const storeFonts = useSelector(state => state.colorsAndFonts.fonts);

  const dispatch = useDispatch();
  const api = useApi();
  const { createError, createInfo } = useNotifications();

  const [localFonts, setLocalFonts] = useState(storeFonts);

  const hasChanged = !isEqual(localFonts, storeFonts);

  const makeHandler = key => font =>
    setLocalFonts({ ...localFonts, [key]: font });

  async function handleSubmit() {
    dispatch(replaceFonts(localFonts));

    try {
      await api.patch(`/albums/${albumId}`, { fonts: localFonts });
      createInfo(t('genericSuccess'));
    } catch (err) {
      createError(t('genericError'));
    }
  }

  return (
    <Card bg="light">
      <Card.Header>Schriftarten</Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item className="qa-title-item d-flex align-items-center justify-content-between">
            Überschrift
            <FontDropDown
              value={localFonts.title}
              onChange={makeHandler('title')}
            />
          </ListGroup.Item>
          <ListGroup.Item className="qa-sub_title-item d-flex align-items-center justify-content-between">
            Subline
            <FontDropDown
              value={localFonts.sub_title}
              onChange={makeHandler('sub_title')}
            />
          </ListGroup.Item>
          <ListGroup.Item className="qa-paragraph-item d-flex align-items-center justify-content-between">
            Fließtext
            <FontDropDown
              value={localFonts.paragraph}
              onChange={makeHandler('paragraph')}
            />
          </ListGroup.Item>
        </ListGroup>
        <Button
          variant={hasChanged ? 'primary' : 'light'}
          className="mt-3 float-right qa-submit-fonts-btn"
          onClick={handleSubmit}
          disabled={!hasChanged}
        >
          Speichern
        </Button>
      </Card.Body>
    </Card>
  );
}

FontSwatchPicker.propTypes = {
  albumId: string.isRequired,
};

export default FontSwatchPicker;
