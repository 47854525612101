import React from 'react';

import PageControl from './PageControl';
import ZoomControl from './ZoomControl';
import TogglePrintPreviewButton from './TogglePrintPreviewButton';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';
import ViewSettingsControl from './ViewSettingsControl';
import useScreenSize from '../../../../hooks/useScreenSize';

function ViewControlMenu() {
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return null;
  }

  return (
    <FadeInWhenAlbumLoaded>
      <div className="view-control-menu qa-view-control form-inline">
        <PageControl />
        <ZoomControl />
        <TogglePrintPreviewButton />
        <ViewSettingsControl />
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

ViewControlMenu.propTypes = {};

export default ViewControlMenu;
