import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useFileUpload from './useFileUpload';
import useApi from './useApi';
import { createImage, updateImage } from '../modules/images';
import { deleteImage } from '../actions/images';
import { updateStickersByImageId } from '../actions/stickers';
import {
  getDataUrl,
  computeImageDimensions,
  resizeImage,
  preloadImages,
  computeNativeImageSize,
} from '../util/images';
import { getStickerPositionFromFace } from '../util/faceapi';
import { createWarning } from '../actions/notifications';
import { selectCurrentAlbum } from '../selectors/albums';
import useLocale from './localization/useLocale';
import useLoading from './useLoading';
import { generateId } from '../util';
import { updateElementsByImageId } from '../actions/workspace';

export const imageModels = {
  sticker: 'sticker',
  image: 'image',
};

export const defaultValidators = {
  size: [0.0001, 16],
  type: ['image/jpeg', 'image/png', 'image/svg+xml'],
  totalPixels: 32000000,
};

/**
 * Hook for handling image uploads with face detection
 * @param {Object} options - Configuration options
 * @param {Object} options.validators - Image validation rules
 * @returns {Object} Image upload methods
 */
function useImageUpload({ validators = defaultValidators } = {}) {
  const { t } = useLocale();
  const { upload } = useFileUpload();
  const currentAlbum = useSelector(selectCurrentAlbum);
  const { startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const api = useApi();

  const endpoint = `/albums/${currentAlbum}/images`;
  const faceDetectionEndpoint = `/images/face_detection`;

  /**
   * Start face detection for a client image. Sends the base64
   * string of the resized blob to our face detection endpoint.
   */
  const startFaceDetection = useCallback(
    async clientImage => {
      startLoading(clientImage.id);
      const { data } = await api.post(faceDetectionEndpoint, {
        base64_image: clientImage.blob.full, // all blob sizes contain the resized base64 string here
      });
      const { face } = data;

      if (!face) {
        stopLoading(clientImage.id);
        return clientImage;
      }

      dispatch(
        updateStickersByImageId(clientImage.id, {
          ...getStickerPositionFromFace(
            face,
            computeNativeImageSize(clientImage)
          ),
        })
      );

      const clientImageWithFace = {
        ...clientImage,
        details: { ...clientImage.details, face },
      };

      dispatch(updateImage(clientImage.id, clientImageWithFace));
      stopLoading(clientImage.id);

      return clientImageWithFace;
    },
    [api, faceDetectionEndpoint, startLoading, stopLoading, dispatch]
  );

  /**
   * Replace a client image with server image and update references
   */
  const replaceImage = useCallback(
    async (id, serverImage) => {
      const { id: serverId } = serverImage;
      /**
       * Cache the small versions before replacing the image to avoid flickering.
       */
      const { small, medium } = serverImage.blob || {};
      await preloadImages([small, medium].filter(Boolean));

      // Update image in the store

      batch(() => {
        dispatch(
          updateStickersByImageId(id, {
            image: serverId,
          })
        );
        dispatch(updateElementsByImageId(id, serverId));
        dispatch(updateImage(id, serverImage));
      });
    },
    [dispatch]
  );

  /**
   * Schedule an image upload to the server
   */
  const startUpload = useCallback(
    async (clientImage, file) => {
      const { id } = clientImage;
      try {
        const { details } =
          clientImage.model === imageModels.sticker
            ? await startFaceDetection(clientImage)
            : clientImage;

        const { id: blobId } = await upload(file);

        const {
          data: { image: serverImage },
        } = await api.post(endpoint, {
          blob_id: blobId,
          details,
          model: clientImage.model,
        });

        await replaceImage(id, serverImage);

        return serverImage.id;
      } catch (err) {
        dispatch(
          createWarning(
            t('editor.imageUpload.uploadError', { fileName: file.name })
          )
        );
        dispatch(deleteImage(id));
        throw err;
      }
    },
    [api, replaceImage, upload, dispatch, t, startFaceDetection, endpoint]
  );

  /**
   * Validate a file against size and type constraints
   */
  const validateFile = useCallback(
    file => {
      const errors = [];

      const sizeMb = file.size / 1000 / 1000;
      const [min, max] = validators.size;

      if (sizeMb < min || sizeMb > max) {
        errors.push(t('editor.imageUpload.sizeError', { min, max }));
      }

      if (!validators.type.includes(file.type)) {
        errors.push(
          t('editor.imageUpload.typeError', {
            types: validators.type.join(', '),
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  /**
   * Validate image dimensions
   */
  const validatePixels = useCallback(
    ({ width, height }) => {
      const errors = [];

      if (width * height > validators.totalPixels) {
        errors.push(
          t('editor.imageUpload.dimensionsError', {
            maxPixels: validators.totalPixels.toLocaleString(),
          })
        );
      }

      return errors;
    },
    [validators, t]
  );

  /**
   * Create a client-side image object with upload capabilities
   */
  const createClientImage = useCallback(
    async (file, model) => {
      const imageId = generateId();

      // Validate file
      const fileErrors = validateFile(file);
      if (fileErrors.length > 0) {
        return { errors: fileErrors };
      }

      const originalDataUrl = await getDataUrl(file);
      const { width, height } = await computeImageDimensions(originalDataUrl);

      // Validate dimensions
      const pixelError = validatePixels({ width, height });
      if (pixelError.length > 0) {
        return { errors: pixelError };
      }

      const previewDataUrl = await resizeImage(originalDataUrl, 800);

      const clientImage = {
        id: imageId,
        model,
        blob: {
          filename: file.name,
          full: previewDataUrl,
          large: previewDataUrl,
          medium: previewDataUrl,
          original: originalDataUrl,
          small: previewDataUrl,
        },
        details: {
          width,
          height,
        },
        meta: {},
        tags: [],
      };

      // Add to store and remove from loading queue
      dispatch(createImage(clientImage));

      /**
       * Cancel the upload process
       */
      const cancelUpload = () => dispatch(deleteImage(clientImage));

      return {
        clientImage,
        cancelUpload,
        file,
      };
    },
    [dispatch, validateFile, validatePixels]
  );

  /**
   * Handle the image upload process
   */
  async function handler(file, model) {
    const { errors, clientImage } = await createClientImage(file, model);

    if (errors) {
      dispatch(createWarning(errors.join(', ')));
      return null;
    }

    startUpload(clientImage, file);

    // Return immediately without waiting for upload
    return clientImage;
  }

  /**
   * Create a sticker image with face detection (performed by server)
   */
  const createStickerImage = (file, stickerId) =>
    handler(file, imageModels.sticker, stickerId);

  /**
   * Create a workspace image
   */
  const createWorkspaceImage = file => handler(file, imageModels.image);

  return {
    createWorkspaceImage,
    createStickerImage,
    createClientImage,
  };
}

export default useImageUpload;
