import BlueprintsPanel from '../../AlbumWizard/Sidebar/BlueprintsPanel/index.container';
import OrganizationForm from '../../AlbumWizard/Content/OrganizationForm';

export default [
  {
    key: 'blueprints',
    title: 'wizard.tabs.blueprints.headline',
    content: 'wizard.tabs.blueprints.content',
    component: BlueprintsPanel,
  },
  {
    key: 'data',
    title: 'wizard.tabs.data.headline',
    content: 'wizard.tabs.data.content',
    renderContent: () => null,
    renderPreviewContent: OrganizationForm,
    renderHeader: () => null,
    renderFooter: () => null,
    hideSidebar: true,
    ignoreInCount: true,
    businessOnly: true,
    withoutOrganizationOnly: true,
  },
];
