import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { bool, func, string, arrayOf, shape } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Icon from '../../components/Icon';
import Spinner from '../../components/generic/Spinner';
import useLocale from '../../hooks/localization/useLocale';

function AlbumGrid({
  albums,
  isLoading,
  onDestroy,
  alertMessage,
  allowAlbumDestroy,
}) {
  const { fd } = useLocale();
  const [hoveredId, setHoveredId] = useState(null);

  if (isLoading) {
    return (
      <div className="w-100 text-center">
        <Spinner />
      </div>
    );
  }

  if (albums.length === 0) {
    return <Alert>{alertMessage}</Alert>;
  }

  return (
    <Row xs={1} md={2} lg={3}>
      {albums.map(album => (
        <Col className="p-3" key={album.id}>
          <div
            className={`border rounded p-3 qa-album-tile-${album.id}`}
            onMouseEnter={() => setHoveredId(album.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <div className="d-flex justify-content-end">
              <Button
                variant="danger"
                onClick={() => onDestroy(album.id)}
                className={`destroy-album-button qa-destroy-${album.id}`}
                style={{
                  visibility:
                    (album.id !== hoveredId || !allowAlbumDestroy) && 'hidden',
                }}
              >
                <Icon name="trash" />
              </Button>
            </div>
            <Link
              to={`/album/${album.id}`}
              className={`text-muted text-center album-link p-2 qa-album-link qa-album-link-${album.id}`}
            >
              <div className="d-flex flex-column align-items-center">
                {album.cover_preview_url && (
                  <img
                    className="shadow mb-3"
                    src={album.cover_preview_url}
                    alt="Album cover preview"
                    width="80%"
                  />
                )}
                <b>
                  {album.title}{' '}
                  {album.blueprint && <Badge variant="primary">Template</Badge>}
                </b>
                <span className="date">
                  {fd(new Date(album.updated_at), Date.now())}
                </span>
              </div>
            </Link>
          </div>
        </Col>
      ))}
    </Row>
  );
}

AlbumGrid.defaultProps = {
  albums: [],
};

AlbumGrid.propTypes = {
  albums: arrayOf(
    shape({
      id: string.isRequired,
      created_at: string.isRequired,
      title: string.isRequired,
    })
  ),
  isLoading: bool.isRequired,
  onDestroy: func.isRequired,
  alertMessage: string.isRequired,
  allowAlbumDestroy: bool.isRequired,
};

export default AlbumGrid;
