import React from 'react';
import { arrayOf, bool } from 'prop-types';
import isEqual from 'lodash/isEqual';

import ColorTiles from '../ColorTiles';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import {
  ColorsShape,
  StylesPanelStateMachineShape,
} from '../../../../../shapes';
import useAnalytics from '../../../../../../containers/app/useAnalytics';
import useLocale from '../../../../../../hooks/localization/useLocale';

function ColorPresetsList({ stateMachine, availableColors, isWizard }) {
  const { t } = useLocale();
  const analytics = useAnalytics();
  const { send } = stateMachine;
  const { colors: currentColors, setColors } = stateMachine;

  function handleClick(colors) {
    setColors(colors);

    analytics.track(`${isWizard ? 'Wizard ' : ''}Colors Updated`, {
      colorsValue: colors,
    });
  }

  return (
    <div className="d-flex flex-column">
      <DropoutTitle
        label={t('editor.sidebar.styles.tryPresets')}
        onBack={() => send('back')}
      />
      <DropoutSection>
        {availableColors.map(colors => (
          <div
            key={Object.values(colors).join(', ')}
            className="my-3 cursor-pointer qa-color-preset"
          >
            <ColorTiles
              colors={colors.slice(2)}
              onClick={() => handleClick(colors)}
              active={isEqual(currentColors, colors)}
            />
          </div>
        ))}
      </DropoutSection>
    </div>
  );
}

ColorPresetsList.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
  availableColors: arrayOf(ColorsShape).isRequired,
  isWizard: bool.isRequired,
};

export default ColorPresetsList;
