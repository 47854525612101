import React from 'react';
import { useDispatch } from 'react-redux';

import StickerForm from './StickerForm';
import useStickerForm from './useStickerForm';
import { updateSticker } from '../../../../actions/stickers';
import { historyAnchor } from '../../../../modules/history';
import useAnalytics from '../../../../containers/app/useAnalytics';

function MobileStickerForm() {
  const { selectedSticker, reset } = useStickerForm();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  if (!selectedSticker) {
    return null;
  }

  function handleSubmit(updatedSticker) {
    dispatch(updateSticker(updatedSticker.id, updatedSticker));

    analytics.track('Sticker Updated', {
      id: updatedSticker.id,
    });
    dispatch(historyAnchor());
  }

  function handleClose() {
    reset();
  }

  return (
    <div className="sticker-form-mobile">
      <StickerForm
        onSubmit={handleSubmit}
        onClose={handleClose}
        autoSubmit={false}
        initialSticker={selectedSticker}
        isExistingSticker={selectedSticker}
        deleteButton
        submitButton
      />
    </div>
  );
}

export default MobileStickerForm;
