import React, { useCallback, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from 'react-redux';

import useApi from '../../hooks/useApi';
import { selectAlbumData, selectCurrentAlbum } from '../../selectors/albums';
import { setAlbumData } from '../../modules/albums';

function OnboardingSidebarBadge() {
  const dispatch = useDispatch();
  const { onboardingState } = useSelector(selectAlbumData);

  const api = useApi();
  const albumId = useSelector(selectCurrentAlbum);

  const fetchOnboardingState = useCallback(async () => {
    const {
      data: { onboarding_state: onboardingStateFromServer },
    } = await api.get(`/albums/${albumId}/onboarding_state`);

    dispatch(
      setAlbumData({
        onboardingState: onboardingStateFromServer,
      })
    );
  }, [albumId, api, dispatch]);

  useEffect(() => {
    fetchOnboardingState();
  }, [albumId, fetchOnboardingState]);

  const doneTasksCount = Object.values(onboardingState).filter(Boolean).length;
  const dueTasksCount = Object.keys(onboardingState).length - doneTasksCount;

  if (dueTasksCount === 0) {
    return null;
  }

  return <Badge variant="success">{dueTasksCount}</Badge>;
}

export default OnboardingSidebarBadge;
