import { checkLowImageResolution } from '../../../../util/images';
import {
  levelError,
  levelWarning,
} from '../../../generic/FormValidationErrors';

function validateImageResolution(sticker) {
  /**
   * Usually the `imageObject` would be linked from `images` via its id in
   * `sticker.image`. But for both modals (`PendingStickerUploadsModal` and
   * `LocalStickerUploadsModal`) the temporary `imageObject` is already
   * included in the sticker.
   * In the other case, we resolve `sticker.image` in `SidebarSticker` and
   * include it in the sticker-object manually.
   */
  const imageObject =
    sticker.stickerUpload?.image || sticker.clientImage || sticker.imageObject;
  if (!imageObject) {
    return false;
  }

  return checkLowImageResolution(imageObject, sticker.cscale);
}

export default [
  [
    // `sticker.name` must be filled, unless it is a `doubleSticker`
    ({ name, doubleSticker }) => !(name || doubleSticker),
    'Name muss ausgefüllt werden',
    levelError,
  ],
  [validateImageResolution, 'Niedrige Auflösung', levelWarning],
];
