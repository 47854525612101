import React from 'react';
import { node } from 'prop-types';

import FadeInWhenAlbumLoaded from '../../components/generic/FadeInWhenAlbumLoaded';

function MobileFooterMenu({ children }) {
  return (
    <div className="mobile-footer-menu qa-mobile-footer-menu">
      <FadeInWhenAlbumLoaded>{children}</FadeInWhenAlbumLoaded>
    </div>
  );
}

MobileFooterMenu.propTypes = {
  children: node.isRequired,
};

export default MobileFooterMenu;
