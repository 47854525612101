import React, { useRef } from 'react';
import { string, number } from 'prop-types';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { itemTypes } from '../../../constants';
import SidebarSticker from './SidebarSticker';
import { updateControls } from '../../../modules/controls';

function SidebarStickerWithDnd(props) {
  const { id, sectionId, index } = props;
  const ref = useRef();
  const dispatch = useDispatch();

  /**
   * Sidebar sticker drag source. When starting the drag operation,
   * we're setting the `operationActive` flag to avoid unnessecary autosaves
   * and the `shouldHideHandles` to not block our drop target.
   */
  const [, drag] = useDrag({
    type: itemTypes.sticker,
    item: () => {
      dispatch(
        updateControls({ operationActive: true, shouldHideHandles: true })
      );
      return { id, index, sectionId };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      dispatch(
        updateControls({ operationActive: false, shouldHideHandles: false })
      );
    },
  });

  drag(ref);

  return (
    <div ref={ref}>
      <SidebarSticker {...props} />
    </div>
  );
}

SidebarStickerWithDnd.defaultProps = {
  id: undefined,
  sectionId: undefined,
};

SidebarStickerWithDnd.propTypes = {
  id: string,
  index: number.isRequired,
  sectionId: string,
};

export default SidebarStickerWithDnd;
