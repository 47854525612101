import React from 'react';
import { string } from 'prop-types';

import { AREAS } from './constants';

function EmptySlotPlaceholder({ areaId }) {
  const isSticker = areaId === AREAS.STICKERS;

  return (
    <div className="image-placeholder">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {isSticker ? (
          <>
            <path
              d="M5 3 H19 C20.1 3 21 3.9 21 5 V19 C21 20.1 20.1 21 19 21 H5 C3.9 21 3 20.1 3 19 V5 C3 3.9 3.9 3 5 3 Z"
              fill="none"
            />
            <path
              d="M14 21 L21 14 L21 21 Z"
              fill="currentColor"
              stroke="none"
            />
            <circle cx="12" cy="9" r="3" />
            <path d="M7 15 C7 12 9 11 12 11 C15 11 17 12 17 15" />
          </>
        ) : (
          <>
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <circle cx="8" cy="8" r="2" />
            <path d="M7 15 L10 12 L13 15" />
            <path d="M11 15 L15 10 L19 15" />
          </>
        )}
      </svg>
    </div>
  );
}

EmptySlotPlaceholder.propTypes = {
  areaId: string.isRequired,
};

export default EmptySlotPlaceholder;
