import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import formatDistance from 'date-fns/formatDistance';

import { closeNotification } from '../../../actions/notifications';
import { selectNotifications } from '../../../selectors/notifications';
import { types } from '../../../modules/notifications';

const typeToHeader = {
  [types.warning]: 'Warnung',
  [types.error]: 'Fehler',
  [types.info]: 'Info',
};

/**
 * This component will list notifications (errors, failed requests, later: external sticker creations, ...)
 * as bootstrap toasts in the top right corner of our app.
 */
function Notifications() {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  function handleClose(id) {
    dispatch(closeNotification(id));
  }

  return (
    <div className="notifications qa-notifications">
      {notifications.map(
        ({ id, timestamp, message, type, autoHide, showTimestamp }) => (
          <Toast
            onClose={() => handleClose(id)}
            key={id}
            autohide={!(autoHide === false)}
            delay={1000 * 10}
            className={`toast qa-notification-${id}`}
          >
            <Toast.Header>
              <strong className="mr-auto">{typeToHeader[type]}</strong>
              {!(showTimestamp === false) && (
                <small>{formatDistance(new Date(timestamp), Date.now())}</small>
              )}
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        )
      )}
    </div>
  );
}

export default Notifications;
