export default [
  {
    headline: 'editor.onboarding.steps.team.headline',
    text: 'editor.onboarding.steps.team.text',
    link: 'onboarding/settings/users',
    id: 'users',
  },
  {
    headline: 'editor.onboarding.steps.albumTitle.headline',
    text: 'editor.onboarding.steps.albumTitle.text',
    onClick: () => document.getElementById('albumTitleEditButton').click(),
    id: 'albumTitle',
  },
  {
    headline: 'editor.onboarding.steps.sticker.headline',
    text: 'editor.onboarding.steps.sticker.text',
    link: 'stickers',
    id: 'sticker',
  },
  {
    headline: 'editor.onboarding.steps.section.headline',
    text: 'editor.onboarding.steps.section.text',
    link: 'stickers',
    id: 'section',
  },
  {
    headline: 'editor.onboarding.steps.stickerlink.headline',
    text: 'editor.onboarding.steps.stickerlink.text',
    link: 'stickerlink',
    id: 'stickerlink',
  },
  {
    headline: 'editor.onboarding.steps.image.headline',
    text: 'editor.onboarding.steps.image.text',
    link: 'background',
    id: 'image',
  },
];
