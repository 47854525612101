import React from 'react';

import useLocale from '../../../hooks/localization/useLocale';

function Footer() {
  const { t } = useLocale();
  return (
    <div className="text-center p-3">
      <p>
        © {new Date().getFullYear()} STICKERSTARS Technologies GmbH
        <br /> support@stickerstars.de
      </p>
      <p>
        <span className="mr-2">
          <a
            href="https://www.stickerstars.de/impressum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.imprint')}
          </a>
        </span>
        <span>
          <a
            href="https://www.stickerstars.de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.privacy')}
          </a>
        </span>
      </p>
      <p>
        Made with{' '}
        <span role="img" aria-label="love" className="pr-1">
          ❤️
        </span>{' '}
        in Berlin
      </p>
    </div>
  );
}

export default Footer;
