import React from 'react';
import { string, array, number, func } from 'prop-types';

import useLocale from '../../../../hooks/localization/useLocale';
import { AREAS } from './constants';
import DragDropContainer from './DragDropContainer';
import ImageThumbnail from './ImageThumbnail';
import EmptySlotPlaceholder from './EmptySlotPlaceholder';

function DropArea({
  areaId,
  title,
  description,
  images,
  totalSlots,
  onAddMore,
  handleMoveImage,
  className,
}) {
  const { t } = useLocale();
  const usedSlots = images.length;

  const renderAddMoreButton = () => {
    if (areaId === AREAS.STICKERS) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={onAddMore}
        >
          {t('editor.spreadCreationModal.addMore')}
        </button>
      );
    }
    return null;
  };

  const renderEmptySlots = count => {
    return Array.from({ length: count }).map((_, index) => (
      <EmptySlotPlaceholder
        key={`${areaId}-empty-${index}`} // eslint-disable-line react/no-array-index-key
        areaId={areaId}
      />
    ));
  };

  const renderContent = () => {
    if (images.length > 0) {
      const remainingSlots = totalSlots - images.length;
      return (
        <div className="d-flex flex-wrap gap-2 justify-content-start">
          {images.map(image => (
            <ImageThumbnail key={image.id} image={image} sourceArea={areaId} />
          ))}
          {remainingSlots > 0 && renderEmptySlots(remainingSlots)}
        </div>
      );
    }

    if (totalSlots === 0) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-center text-muted py-4">
          <p className="mb-0">
            {t('editor.spreadCreationModal.noSlotsAvailable', { area: areaId })}
          </p>
        </div>
      );
    }

    return (
      <div className="d-flex flex-wrap gap-2 justify-content-start">
        {renderEmptySlots(totalSlots)}
      </div>
    );
  };

  return (
    <DragDropContainer
      onDrop={handleMoveImage}
      areaId={areaId}
      className={`drop-area border border-2 border-dashed rounded-3 p-3 my-1 ${className}`}
    >
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h6 className="mb-0">
          {title}{' '}
          <span className="text-muted small">
            ({usedSlots}/{totalSlots})
          </span>
        </h6>
        {renderAddMoreButton()}
      </div>
      <p className="text-muted small mb-3">{description}</p>

      {renderContent()}
    </DragDropContainer>
  );
}

DropArea.defaultProps = {
  className: '',
};

DropArea.propTypes = {
  areaId: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  images: array.isRequired,
  totalSlots: number.isRequired,
  onAddMore: func.isRequired,
  handleMoveImage: func.isRequired,
  className: string,
};

export default DropArea;
