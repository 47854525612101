import { createSelector } from 'reselect';

import { computeNativeImageSize } from '../util/images';
import { getPexelImage } from './pexels';

export const getImages = state => state.images;

export const getNonStickerImages = createSelector(getImages, images =>
  images.filter(image => image.model !== 'sticker')
);

export const selectBlueprintImageIds = createSelector(getImages, images =>
  images.filter(image => image.blueprint_image).map(image => image.id)
);

export const getImage = (state, imageId) => {
  // NOTE: placeholder images have their id set to null.
  // We also need to return null for their imageObject.
  if (!imageId) return null;

  const images = getImages(state);
  const matchImageId = ({ id }) => id === imageId;
  const image = images.find(matchImageId);
  if (!image) return null;
  return image;
};

export default getImage;

export const imageSize = (images, props) => {
  const { image: imageId } = props;
  const imageObject = getImage({ images }, imageId);
  return computeNativeImageSize(imageObject);
};

export const getImageSize = (state, element) => {
  const { image: imageId, pexelsId } = element.props;
  let imageObject;
  if (pexelsId) {
    const pexelsObject = getPexelImage(state, pexelsId);
    imageObject = { details: pexelsObject };
  } else {
    imageObject = getImage(state, imageId);
  }
  return computeNativeImageSize(imageObject);
};
