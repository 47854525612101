import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { batch, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

import {
  resetView,
  resetViewToSpreadIndex,
} from '../../../../modules/controls';
import DropdownItemWithIcon from '../../../generic/DropdownItemWithIcon';
import useViewport from '../useViewport';
import { zoomToPivot } from '../useViewportGestures';
import useTutorial from '../../../../hooks/useTutorial';
import useLocale from '../../../../hooks/localization/useLocale';

const zoomOptions = [0.5, 1, 2, 3];

function ZoomControl() {
  const { t } = useLocale();
  const {
    pan,
    zoom,
    setPan,
    setZoom,
    restrictPanAndZoom,
    viewportRef,
    clientToViewport,
  } = useViewport();
  const dispatch = useDispatch();

  const { linkTo } = useTutorial();

  function handleZoomOptionClick(wantedZoom) {
    try {
      const {
        x,
        y,
        width,
        height,
      } = viewportRef.current?.getBoundingClientRect();

      const clientPivot = { x: x + width / 2, y: y + height / 2 };

      const [nextPan, nextZoom] = zoomToPivot({
        wantedZoom,
        pan,
        clientPivot,
        clientToViewport,
        restrictPanAndZoom,
      });

      batch(() => {
        setZoom(nextZoom);
        setPan(nextPan);
      });
    } catch (err) {
      // viewport ref side effect crash?
      Sentry.captureException(err);
    }
  }

  function handleResetView() {
    dispatch(resetView());
  }

  function handleResetViewToSpreadIndex() {
    dispatch(resetViewToSpreadIndex());
  }

  return (
    <Dropdown drop="up" className="mr-2">
      <Dropdown.Toggle
        variant="light"
        className="bg-white shadow qa-toggle-view-control-menu"
      >
        {`${Math.round(zoom * 100)} %`}
      </Dropdown.Toggle>
      <Dropdown.Menu className="qa-view-control-menu">
        {zoomOptions.map(option => (
          <Dropdown.Item
            key={option.toString()}
            onClick={() => handleZoomOptionClick(option)}
            className={`qa-zoom-${option}`}
          >
            {`${option * 100} %`}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          className="qa-fit-spread-into-view"
          onClick={handleResetViewToSpreadIndex}
        >
          {t('editor.meta.currentSpread')}
        </Dropdown.Item>
        <Dropdown.Item
          className="qa-fit-all-into-view"
          onClick={handleResetView}
        >
          {t('editor.meta.allSpreads')}
        </Dropdown.Item>
        <Dropdown.Divider />
        <DropdownItemWithIcon
          target="_BLANK"
          href={linkTo('viewport')}
          className="text-muted"
          iconName="help"
        >
          {t('help')}
        </DropdownItemWithIcon>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ZoomControl;
