import React from 'react';
import { useSelector } from 'react-redux';

import { actions } from '../../../../constants';
import { getApplicableSendAction } from '../../../../selectors/selection';
import ActionButton from '../ActionButton';

function ElementOrderButton(props) {
  const action = useSelector(getApplicableSendAction);

  if (action === actions.sendItemsBack) {
    return (
      <ActionButton
        {...props}
        actionName={action}
        tooltip="editor.toolbar.toBack"
        icon="send_down"
      />
    );
  }

  if (action === actions.sendItemsFront) {
    return (
      <ActionButton
        {...props}
        actionName={action}
        tooltip="editor.toolbar.toFront"
        icon="send_up"
      />
    );
  }
  return null;
}

export default ElementOrderButton;
