import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  SelectionState,
  Modifier,
} from 'draft-js';
import orderBy from 'lodash/orderBy';

import { defaultBlockStyle } from '../components/svg/elements/Text/TextConstants';
import { dimensions, fonts, fontSwatches } from '../constants';
import calculateTextScale from './calculateTextScale';
import generatedDefaultReleaseDate from '../components/ui/Sidebar/util';
import { I18n } from '../hooks/localization/useLocale';

/** "Select" everything inside a contentState object to e. g. apply styles. */
export function createSelectionStateWithAllTextSelected(contentState) {
  const firstBlock = contentState.getBlockMap().first();
  const lastBlock = contentState.getBlockMap().last();

  return new SelectionState({
    anchorKey: firstBlock.getKey(),
    anchorOffset: 0,
    focusKey: lastBlock.getKey(),
    focusOffset: lastBlock.getLength(),
  });
}

/**
 * Create a blank, serialized contentState object from a string to be used when
 * creating text frames or rendering symbols.
 * @param {string} text
 */
export function createInitialContentStateFromText(text) {
  let contentState = ContentState.createFromText(text);
  const selection = createSelectionStateWithAllTextSelected(contentState);

  ['FONT-PARAGRAPH', 'SIZE-11', 'LINEHEIGHT-150%', 'COLOR-#000000'].forEach(
    style => {
      contentState = Modifier.applyInlineStyle(contentState, selection, style);
    }
  );

  return convertToRaw(contentState);
}

export const getSymbolTextFromProps = ({
  symbol,
  localTexts,
  sections,
  album,
  releaseDate,
  totalstickers,
  toc_sections,
  toc_pages,
  xPos,
  spreadProps,
}) => {
  const { sectionId, nodeSiblingCount, nodeIndex } = spreadProps || {};

  const defaultText = `[${symbol}]`;

  switch (symbol) {
    case 'sticker-name':
    case 'sticker-position':
    case 'sticker-number':
    case 'sticker-nextnumber':
      if (localTexts) {
        const value = localTexts[symbol.replace('sticker-', '')];
        return value === null || value === undefined ? defaultText : value;
      }
      return defaultText;

    case 'section':
      if (sections && sectionId) {
        const section = sections.find(s => s.id === sectionId);
        if (section) {
          return section.name;
        }
        return defaultText;
      }
      return defaultText;

    case 'pagenum':
      let num = nodeIndex === 0 ? 1 : nodeIndex * 2;
      if (xPos > dimensions.pageWidth) {
        num += 1;
      }
      return String(num);

    case 'totalpages':
      return (nodeSiblingCount - 2) * 2 + 2;

    case 'album':
      return album;
    case 'totalstickers':
      return totalstickers;
    case 'toc_pages':
      return toc_pages;
    case 'toc_sections':
      return toc_sections;
    case 'release_date':
      const date = releaseDate
        ? new Date(releaseDate)
        : generatedDefaultReleaseDate;
      return I18n.f(date, 'd. MMMM yyyy');
    default:
      return defaultText;
  }
};

export const getEditorStateFromSymbolProps = props => {
  const { text: rawContentState } = props;
  const [firstBlock] = rawContentState.blocks;
  let { inlineStyleRanges } = firstBlock;

  const actualText = String(getSymbolTextFromProps(props)) || '';

  // The ranges need to be sorted like this for AUTOSIZE to override SIZE
  inlineStyleRanges = orderBy(inlineStyleRanges, 'style', 'desc');

  // Patch acutal text and extend inline style range to actual length of text
  const blocks = [
    {
      ...firstBlock,
      text: actualText,
      inlineStyleRanges: inlineStyleRanges.map(range => ({
        ...range,
        length: actualText.length,
      })),
    },
  ];

  const nextRaw = { ...rawContentState, blocks };
  const editorState = EditorState.createWithContent(convertFromRaw(nextRaw));

  return {
    editorState,
    actualText,
  };
};

export function applyAutoSize(props, actualText) {
  const { customStyleMap, text: rawContentState, width: autosizeWidth } = props;
  const [firstBlock] = rawContentState.blocks;
  const { inlineStyleRanges } = firstBlock;

  // Determine relevant font styles
  const activeStyles = inlineStyleRanges.map(range => range.style);
  if (!activeStyles.includes('AUTOSIZE')) {
    return customStyleMap;
  }

  const { fontFamily, fontSize } = activeStyles.reduce(
    (acc, cur) => ({ ...acc, ...customStyleMap[cur] }),
    {}
  );

  const subStyles = ['BOLD', 'ITALIC'].filter(
    subStyle => activeStyles.indexOf(subStyle) !== -1
  );
  const text =
    activeStyles.indexOf('UPPERCASE') !== -1
      ? actualText.toLocaleUpperCase()
      : actualText;

  // Calculate scale to fit into width
  const fontStyle = `${subStyles.join(' ')} ${fontSize}pt ${fontFamily}`;
  const scale = calculateTextScale(text, fontStyle, autosizeWidth);

  if (scale === 1) {
    return customStyleMap;
  }

  // Add autosize CSS style to styleMap
  return {
    ...customStyleMap,
    AUTOSIZE: {
      fontSize: fontSize * scale,
      whiteSpace: 'nowrap',
    },
  };
}

const styleNames = {
  FONT: 'font',
  SIZE: 'size',
  LINEHEIGHT: 'lineHeight',
  COLOR: 'color',
  ALIGN: 'align',
  BOLD: 'bold',
  ITALIC: 'italic',
  AUTOSIZE: 'autosize',
  UPPERCASE: 'uppercase',
};

/**
 * A map like this: { RUBIK: Rubik, ... }
 */
const uncapitalizeFontNameMap = [...fonts, ...Object.keys(fontSwatches)].reduce(
  (acc, cur) => {
    acc[cur.toLocaleUpperCase()] = cur;
    return acc;
  },
  {}
);

export function extractTextPropsFromEditorState(editorState) {
  const selection = editorState.getSelection();
  const blockStyle = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getData();

  const inlineStyle = editorState.getCurrentInlineStyle();

  const mergedStyles = defaultBlockStyle
    .toOrderedSet()
    .merge(blockStyle.toOrderedSet())
    .merge(inlineStyle);

  const textProps = mergedStyles.reduce((acc, cur) => {
    const [key, value = true] = cur.split('-');
    if (styleNames[key]) {
      acc[styleNames[key]] = value;
    }
    return acc;
  }, {});

  return {
    ...textProps,
    font: uncapitalizeFontNameMap[textProps.font],
  };
}
