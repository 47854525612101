import { bool, func, string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import useLocale from '../../../../hooks/localization/useLocale';
import Icon from '../../../Icon';
import { fontSizes } from '../../elements/Text/TextConstants';
import DropdownWithTooltip from '../DropdownWithTooltip';
import withTextInputProps from './withTextInputProps';

const unit = 'pt';

function TextSizeInput({
  size: sizeFromProps,
  makeHandleChange,
  autosize,
  isSymbol,
  ...rest
}) {
  const { t } = useLocale();
  const size = parseInt(sizeFromProps, 10);

  return (
    <DropdownWithTooltip
      {...rest}
      className="size-input panel-container qa-font-size"
      tooltip={t('editor.toolbar.fontSize')}
    >
      <Dropdown.Toggle>
        {size} {unit}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="panel size-list scrollable">
          {fontSizes.map(someSize => (
            <Dropdown.Item
              key={someSize}
              active={size === someSize}
              onClick={makeHandleChange('size', someSize)}
            >
              {someSize} {unit}
            </Dropdown.Item>
          ))}
        </div>
        {isSymbol && (
          <ButtonGroup className="panel autosize-input">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>{t('editor.toolbar.fontSizeAutoFitHint')}</Tooltip>
              }
            >
              <Button
                active={autosize}
                onClick={makeHandleChange('autosize')}
                className="qa-font-autosize"
              >
                <Icon name="autosize" />
                &nbsp;{t('editor.toolbar.autoFit')}
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        )}
      </Dropdown.Menu>
    </DropdownWithTooltip>
  );
}

TextSizeInput.defaultProps = {
  size: 11,
  autosize: false,
  isSymbol: false,
};

TextSizeInput.propTypes = {
  makeHandleChange: func.isRequired,
  size: string,
  autosize: bool,
  isSymbol: bool,
};

export default withTextInputProps(TextSizeInput);
