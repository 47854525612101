// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { resolveImage } from '../../util/images';
import { getImage } from '../../selectors/images';
import usePexel from '../../hooks/usePexel';

const getFileExtension = path =>
  path
    .split('.')
    .pop()
    .toLowerCase();

function PreloadedImage({ src, alt, className }) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const image = new window.Image();
    image.src = src;
    image.onload = () => setReady(true);
  }, [src]);

  return ready ? (
    <img
      className={`${className} qa-ready-preloaded-image`}
      src={src}
      alt={alt}
    />
  ) : (
    <div
      className={`w-100 bg-light ${className}`}
      style={{ height: '100px' }}
    />
  );
}

const Image = ({
  src: srcFromProps,
  imageId,
  pexelsId,
  resolution,
  ...rest
}) => {
  const imageObject = useSelector(state => getImage(state, imageId));
  const { resolvePexelsId, resolvePexelsObject } = usePexel();
  const { value: pexelsObject } = useAsync(
    async () => resolvePexelsId(pexelsId),
    [pexelsId]
  );
  const { height, width } = imageObject?.details ||
    pexelsObject || { width: 100, height: 100 };

  /**
   * To render a somewhat aesthetically pleasing grid view in the ImagesPanel,
   * we have landscape-format images (ratio > 16:10) span two columns.
   */
  const orientationString = width > height * 1.6 ? 'landscape' : '';

  let src = srcFromProps;
  if (!src) {
    if (imageObject) {
      src = resolveImage(imageObject, resolution).src;
    } else if (pexelsObject) {
      src = resolvePexelsObject(pexelsObject, resolution);
    }
  }
  if (!src) {
    return null;
  }

  const isSvg = getFileExtension(src) === 'svg';

  return (
    <PreloadedImage
      src={src}
      {...rest}
      className={`ph-no-capture ${orientationString} ${isSvg ? 'svg' : ''}`}
    />
  );
};

export default Image;
