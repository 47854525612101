import React from 'react';
import { arrayOf, number } from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import findLast from 'lodash/findLast';

import Spinner from '../../../components/generic/Spinner';
import Pagination from '../../../components/generic/Pagination';
import useQuery from '../../../hooks/useQuery';
import useQueryParams from '../../../hooks/useQueryParams';
import AdminOnly from '../../../components/generic/AdminOnly';
import { OrderUpdateShape, OrderSignalShape } from '../../../components/shapes';
import Icon from '../../../components/Icon';
import useLocale from '../../../hooks/localization/useLocale';

function OrderUpdatesList({ orderSignals, orderUpdates }) {
  if (!orderSignals) {
    return null;
  }

  return (
    <ul className="p-0 list-unstyled d-flex flex-wrap">
      {orderSignals.map((orderSignal, index) => {
        const matchingOrderUpdate = findLast(orderUpdates, orderUpdate =>
          orderUpdate.signal.includes(orderSignal.title)
        );
        const signal = { ...orderSignal, ...matchingOrderUpdate };
        const active = !!signal.created_at;

        const badge = (
          <Badge
            variant="primary"
            style={{ opacity: active ? 1 : 0.15 }}
            className={`m-1 p-2 ${active ? 'qa-badge-active' : ''}`}
          >
            {signal.human_title}
          </Badge>
        );
        return (
          <li key={signal.title} className="d-flex align-items-center">
            {active ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={signal.title}>
                    {format(new Date(signal.created_at), 'dd. LLLL, yyyy')}
                  </Tooltip>
                }
              >
                {badge}
              </OverlayTrigger>
            ) : (
              badge
            )}
            {index < orderSignals.length - 1 && <Icon name="chevron_right" />}
          </li>
        );
      })}
    </ul>
  );
}

OrderUpdatesList.propTypes = {
  orderUpdates: arrayOf(OrderUpdateShape).isRequired,
  orderSignals: arrayOf(OrderSignalShape).isRequired,
};

function OrdersList({ perPage }) {
  const { t, f } = useLocale();
  const page = useQueryParams().get('page');

  const { data: orderSignals } = useQuery('/order_signals', []);

  const {
    data,
    error,
    isLoading,
    pagination,
  } = useQuery(`/orders?page=${page}&per=${perPage}`, [page]);

  if (isLoading || !data) {
    return <Spinner />;
  }

  if (error) {
    return <Alert variant="danger">{t('genericError')}</Alert>;
  }

  const { total_pages: totalPages, current_page: currentPage } = pagination;

  return (
    <>
      {totalPages > 1 && (
        <div className="text-right text-muted">
          {t('pagination', { page: currentPage, total: totalPages })}
        </div>
      )}
      {data &&
        data.orders.map(
          ({
            id,
            album,
            album: { release_date: releaseDate },
            name,
            status_message: statusMessage,
            order_updates: orderUpdates,
            created_at: createdAt,
            shipping_address: { address1 },
          }) => (
            <Card
              className={`border mt-4 qa-order-item qa-order-${id}`}
              key={id}
            >
              <Card.Header>
                {album.title} /{' '}
                <span className="font-weight-normal">{address1}</span>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={2} lg={2}>
                    {album.cover_preview_url && (
                      <img
                        src={album.cover_preview_url}
                        alt="Album cover preview"
                        className="w-100 rounded shadow"
                      />
                    )}
                  </Col>
                  <Col md={10} lg={10}>
                    <Card.Text>{statusMessage}</Card.Text>
                  </Col>
                </Row>
                <hr />
                <Card.Text>
                  <b>{t('models.order.id')}:</b> {name}
                  <br />
                  <b>{t('models.createdAt')}:</b> {f(new Date(createdAt))}
                  <br />
                  <b>{t('models.album.releaseDate')}:</b>{' '}
                  {f(new Date(releaseDate), 'dd. LLLL, yyyy')}
                </Card.Text>
                <AdminOnly>
                  <OrderUpdatesList
                    orderSignals={orderSignals}
                    orderUpdates={orderUpdates}
                  />
                </AdminOnly>
                <Link to={`/orders/${id}`}>
                  <Button
                    component={Button}
                    variant="outline-dark"
                    className="float-right qa-order-details-btn"
                  >
                    {t('profile.showDetails')}
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          )
        )}
      <Pagination active={currentPage} total={totalPages} />
    </>
  );
}

OrdersList.defaultProps = {
  perPage: 25,
};

OrdersList.propTypes = {
  perPage: number,
};

export default OrdersList;
