import React, { useRef } from 'react';

import Sidebar from '../index';
import TabContent, { TabPane } from '../TabContent';
import wizardTabs from './wizardTabs';
import useScopedSidebarTabs from '../useScopedSidebarTabs';
import StepHeader from '../../AlbumWizard/Content/StepHeader';
import useScreenSize from '../../../../hooks/useScreenSize';
import Logo from '../../../../assets/icons/logo.svg';

function WizardSidebar(props) {
  const sidebarRef = useRef();
  const {
    countableTabs,
    activeTab,
    activeCountableTabIndex,
  } = useScopedSidebarTabs(wizardTabs);
  const { isMobile } = useScreenSize();

  return (
    <Sidebar
      minimized={activeTab.hideSidebar}
      sidebarRef={sidebarRef}
      className="shadow-none"
    >
      <TabContent>
        {activeTab.renderContent ? (
          activeTab.renderContent()
        ) : (
          <>
            <div className="my-2 text-center">
              <img
                src={Logo}
                style={{ width: '90px' }}
                alt="Stickerstars Logo"
              />
            </div>
            {isMobile && (
              <StepHeader
                tabs={countableTabs}
                activeIndex={activeCountableTabIndex}
              />
            )}
            <TabPane
              component={activeTab.component}
              sidebarRef={sidebarRef}
              isWizard
              {...props}
            />
          </>
        )}
      </TabContent>
    </Sidebar>
  );
}

export default WizardSidebar;
