import React, { createContext, useContext } from 'react';
import { node, object } from 'prop-types';

export const NodeContext = createContext();

export const useNode = () => {
  return useContext(NodeContext);
};

/**
 * Provides the Node component to the entire tree
 * to avoid circular dependencies (e. g. Node -> Spread -> Page layout -> Node)
 * @param {node} children
 * @returns
 */
function NodeProvider({ children, value }) {
  return <NodeContext.Provider value={value}>{children}</NodeContext.Provider>;
}

NodeProvider.propTypes = {
  children: node.isRequired,
  value: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default NodeProvider;
