import { string, shape } from 'prop-types';

export const OrderStatusShape = shape({
  status: string.isRequired,
  date: string.isRequired,
});

export const DashboardOrderShape = shape({
  id: string.isRequired,
  albums_status: OrderStatusShape.isRequired,
  shipment_status: OrderStatusShape.isRequired,
  transfer_status: OrderStatusShape.isRequired,
});

export const DashboardAlbumShape = shape({
  id: string.isRequired,
});
