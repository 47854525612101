import React, { useLayoutEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bool, func, string } from 'prop-types';

import Logo from '../../../../assets/icons/logo.svg';
import { selectWizardState } from '../../../../selectors/wizard';
import { setAlbumData } from '../../../../modules/albums';
import useScreenSize from '../../../../hooks/useScreenSize';
import SubmitButtonWithLink from './SubmitButtonWithLink';
import useLocale from '../../../../hooks/localization/useLocale';

const sizes = [
  { value: 1, label: '1-150' },
  { value: 151, label: '151-200' },
  { value: 201, label: '201-1000' },
  { value: 1001, label: '1000+' },
];

const contactRoles = [
  { value: 'member', label: 'wizard.tabs.data.contactRoles.member' },
  { value: 'lead', label: 'wizard.tabs.data.contactRoles.lead' },
  { value: 'senior', label: 'wizard.tabs.data.contactRoles.senior' },
  { value: 'executive', label: 'wizard.tabs.data.contactRoles.executive' },
];

function OrganizationForm({ handleSubmit, backLinkTo, submitDisabled }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { isMobile } = useScreenSize();
  const { t } = useLocale();

  const {
    albumData: { organization },
  } = useSelector(selectWizardState);
  const invalidInput =
    !organization.name ||
    !organization.size ||
    !organization.contactName ||
    !organization.phone ||
    !organization.contactRole;

  const handleChange = (key, value) => {
    dispatch(setAlbumData({ organization: { ...organization, [key]: value } }));
  };

  useLayoutEffect(() => {
    if (isMobile) {
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.focus();
  }, [isMobile]);

  return (
    <div className="organization-form">
      <img src={Logo} className="logo my-4" alt="stickerstars logo" />
      <h1>
        <span className="highlight-emoji" role="img" aria-label="Wave emoji">
          👏
        </span>
      </h1>
      <h3>{t('wizard.tabs.data.headline')}</h3>
      <Form className="w-100">
        <Form.Group className="mt-4 text-left">
          <Form.Label>
            {t('wizard.tabs.data.organizationName')}
            <span className="text-primary pl-1">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="qa-organization-name-input"
            name="organization_name"
            value={organization.name}
            onChange={event => handleChange('name', event.target.value)}
            ref={inputRef}
          />
        </Form.Group>
        <Form.Group className="mt-4 text-left">
          <Form.Label>
            {t('wizard.tabs.data.numEmployees')}
            <span className="text-primary pl-1">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            className="qa-organization-size-input"
            value={organization.size}
            onChange={event => handleChange('size', event.target.value)}
            name="organization_size"
          >
            <option value="">{t('selectOption')}</option>
            {sizes.map(({ value, label }) => (
              <option
                key={value}
                value={value}
                className="qa-organization-size-option"
              >
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mt-4 text-left">
          <Form.Label>
            {t('wizard.tabs.data.contactName')}
            <span className="text-primary pl-1">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="qa-organization-contact-name-input"
            name="organization_contact_name"
            value={organization.contactName}
            onChange={event => handleChange('contactName', event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-4 text-left">
          <Form.Label className="mb-0">
            {t('wizard.tabs.data.phone')}
            <span className="text-primary pl-1">*</span>
          </Form.Label>
          <div className="mb-2">
            <small className="text-muted label-description">
              {t('wizard.tabs.data.phoneDescription')}
            </small>
          </div>
          <Form.Control
            type="text"
            className="qa-organization-phone-input"
            name="organization_phone"
            value={organization.phone}
            onChange={event => handleChange('phone', event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-4 text-left">
          <Form.Label className="mb-0">
            {t('wizard.tabs.data.contactRole')}
            <span className="text-primary pl-1">*</span>
          </Form.Label>
          <div className="mb-2">
            <small className="text-muted label-description">
              {t('wizard.tabs.data.contactRoleDescription')}
            </small>
          </div>
          <Form.Control
            as="select"
            className="qa-organization-contact-role-input"
            value={organization.contactRole}
            onChange={event => handleChange('contactRole', event.target.value)}
            name="organization_contact_role"
          >
            <option value="">{t('selectOption')}</option>
            {contactRoles.map(({ value, label }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <div className="mt-4">
          <SubmitButtonWithLink
            onSubmit={handleSubmit}
            disabled={submitDisabled || invalidInput}
          >
            {t('createAlbum')}
          </SubmitButtonWithLink>
          <Link to={backLinkTo} className="qa-back-link">
            <Button variant="link" block>
              {t('back')}
            </Button>
          </Link>
        </div>
      </Form>
    </div>
  );
}

OrganizationForm.defaultProps = {};

OrganizationForm.propTypes = {
  handleSubmit: func.isRequired,
  backLinkTo: string.isRequired,
  submitDisabled: bool.isRequired,
};

export default OrganizationForm;
