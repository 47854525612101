import { func, string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../Icon';
import withTextInputProps from './withTextInputProps';
import useLocale from '../../../../hooks/localization/useLocale';

const textStyles = [
  { icon: 'bold', style: 'bold', label: 'editor.toolbar.bold' },
  { icon: 'italic', style: 'italic', label: 'editor.toolbar.italic' },
  { icon: 'uppercase', style: 'uppercase', label: 'editor.toolbar.uppercase' },
];

function TextStyleInput(props) {
  const { t } = useLocale();
  const { makeHandleChange, dropDirection } = props;
  const placement = dropDirection === 'up' ? 'bottom' : 'top';

  return textStyles.map(({ label, icon, style }) => (
    <OverlayTrigger
      placement={placement}
      key={style}
      overlay={<Tooltip>{t(label)}</Tooltip>}
    >
      <Button
        active={props[style]}
        onClick={makeHandleChange(style)}
        className={`rounded-0 qa-font-style-${style}`}
      >
        <Icon name={icon} />
      </Button>
    </OverlayTrigger>
  ));
}

TextStyleInput.propTypes = {
  makeHandleChange: func.isRequired,
  dropDirection: string.isRequired,
};

export default withTextInputProps(TextStyleInput);
