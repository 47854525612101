import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';

import { OrderShape } from '../../../../components/shapes';
import useApi from '../../../../hooks/useApi';
import useNotifications from '../../../../hooks/useNotifications';
import { MetadaFieldShape } from '../shapes';

const signals = [
  'accepted',
  'rejected',
  'review_feedback',
  'review_completed',
  'transfer_completed',
  'production_started',
  'albums_shipped',
  'shipment_created',
];

const signalToMetadataFields = {
  albums_shipped: {
    key: 'dhl_tracking_code',
    label: 'DHL-Tracking-Code',
    description:
      'Code, der über die DHL-Website trackbar ist, nicht die ganze URL.',
    placeholder: 'JVGL11122233344455566677',
    canTriggerEmail: false,
  },
  shipment_created: {
    key: 'tracking_code',
    label: 'Tracking-Link',
    description:
      'Ganze URL, kann jeder Versanddienstleister sein (DHL, UPS etc.).',
    placeholder: 'https://www.dhl.de/de/privatkunden.html?piececode=...',
    canTriggerEmail: true,
  },
};

export function MetadataFieldForm({ field, onChange, value }) {
  return (
    <Form.Group>
      <Form.Label>{field.label}</Form.Label>
      <Form.Control
        as="input"
        type="text"
        placeholder={field.placeholder}
        value={value}
        name={`order_event_metadata_${field.key}`}
        onChange={onChange}
      />
      <Form.Text className="text-muted">{field.description}</Form.Text>
    </Form.Group>
  );
}

MetadataFieldForm.propTypes = {
  field: MetadaFieldShape.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

function OrderEventForm({ order, onDispatch }) {
  const api = useApi();

  const { id, order_updates: pastUpdates } = order;
  const availableSignals = without(
    signals,
    ...pastUpdates.map(({ signal }) => signal)
  );
  const [event, setEvent] = useState({ signal: '' });
  const [submitting, setSubmitting] = useState(false);
  const { createError } = useNotifications();

  const metadataField = signalToMetadataFields[event.signal] || {};
  const shouldRenderMetadataAlert = !isEmpty(metadataField);

  if (availableSignals.length < 1) {
    return (
      <Alert variant="dark">
        Es können keine weiteren Ereignisse für diese Bestellung erstellt
        werden.
      </Alert>
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitting(true);
    try {
      await api.post(`/orders/${id}/order_updates`, event);

      onDispatch();
    } catch (err) {
      createError(err.toString());
    }
    setSubmitting(false);

    setEvent({ signal: '' });
  }

  function handleSignalChange({ target: { value } }) {
    setEvent({ signal: value });
  }

  return (
    <div className="qa-order-event-form">
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Signal</Form.Label>
          <Form.Control
            as="select"
            onChange={handleSignalChange}
            value={event.signal}
            name="order_event_signal"
            className="qa-order-event-signal-select"
          >
            <option value="" disabled>
              Bitte wählen
            </option>
            {availableSignals.map(signal => (
              <option key={signal} value={signal}>
                {signal}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {shouldRenderMetadataAlert && (
          <Alert variant="info" className="qa-order-event-metadata-alert">
            <MetadataFieldForm
              key={metadataField.key}
              field={metadataField}
              value={event.metadata?.[metadataField.key] || ''}
              onChange={({ target: { value } }) =>
                setEvent({
                  ...event,
                  metadata: {
                    ...event.metadata,
                    [metadataField.key]: value,
                  },
                })
              }
            />
            {metadataField.canTriggerEmail && (
              <Form.Group className="text-right">
                <Form.Check
                  type="checkbox"
                  checked={event.trigger_email}
                  onChange={() =>
                    setEvent({ ...event, trigger_email: !event.trigger_email })
                  }
                  name="order_event_trigger_email"
                  label="E-Mail an Kund:in versenden?"
                />
              </Form.Group>
            )}
          </Alert>
        )}
        <Form.Group>
          <Button
            className="qa-order-event-submit-btn"
            disabled={submitting || event.signal === ''}
            type="submit"
          >
            Speichern
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

OrderEventForm.propTypes = {
  order: OrderShape.isRequired,
  onDispatch: func.isRequired,
};

export default OrderEventForm;
