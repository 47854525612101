import React from 'react';
import { node, func, string, object } from 'prop-types';
import { useDrop } from 'react-dnd';

import { ItemTypes } from './constants';

function DragDropContainer({ children, onDrop, areaId, className, style }) {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    drop: item => onDrop(item.id, item.sourceArea, areaId),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`${className || ''} ${isOver ? 'highlight' : ''}`}
      style={style}
    >
      {children}
    </div>
  );
}

DragDropContainer.propTypes = {
  children: node.isRequired,
  onDrop: func.isRequired,
  areaId: string.isRequired,
  className: string,
  style: object,
};

DragDropContainer.defaultProps = {
  className: '',
  style: {},
};

export default DragDropContainer;
