import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch, Switch } from 'react-router';

import AlbumTitle from '../AlbumTitle';
import { TabPane } from '../TabContent';
import useScopedSidebarTabs from '../useScopedSidebarTabs';
import editorTabs from './editorTabs';
import { selectIsAlbumEditable } from '../../../../selectors/albums';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';

function MobileEditorSidebar() {
  const { tabs, activeTab } = useScopedSidebarTabs(editorTabs);
  const { path } = useRouteMatch();
  const sidebarRef = useRef();

  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  /**
   * When accessing an unscoped or non-existing tab, `activeTab` will
   * be undefined before the redirect.
   */
  const activeKey = activeTab?.key;

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({ top: 0 });
    }
  }, [activeKey]);

  if (!isAlbumEditable) {
    return null;
  }

  return (
    <FadeInWhenAlbumLoaded>
      <div
        className="mobile-sidebar qa-mobile-sidebar px-3 pt-2"
        ref={sidebarRef}
      >
        <div className="mobile-sidebar-content d-flex flex-column h-100 pt-2">
          {!(activeTab?.albumTitle === false) && (
            <AlbumTitle className="mb-2" />
          )}
          <Switch>
            {tabs.map(tab => (
              <Route key={tab.key} path={`${path}(${tab.key})`}>
                {tab.renderContent ? (
                  tab.renderContent()
                ) : (
                  <TabPane component={tab.component} sidebarRef={sidebarRef} />
                )}
              </Route>
            ))}
          </Switch>
        </div>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

MobileEditorSidebar.defaultProps = {};

MobileEditorSidebar.propTypes = {};

export default MobileEditorSidebar;
