import React, { useEffect, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { bool, object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AlbumSvg from '../../../svg/Viewport/AlbumSvg';
import Node from '../../../svg/Node';
import Icon from '../../../Icon';
import { dimensions } from '../../../../constants';
import { WorkspaceShape } from '../../../shapes';
import Hint from '../../../generic/Hint';
import GridPattern from '../../../svg/Viewport/GridPattern';
import { getSpreadIds } from '../../../../selectors/legacy';
import { fetchPexelsImagesForWorkspace } from '../../../../actions/pexels';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import useScreenSize from '../../../../hooks/useScreenSize';
import useAnalytics from '../../../../containers/app/useAnalytics';
import { selectActiveAlbumPreviewSpreadIndex } from '../../../../selectors/controls';
import { updateControls } from '../../../../modules/controls';
import useLocale from '../../../../hooks/localization/useLocale';

const CoverLoader = () => {
  const { isMobile } = useScreenSize();

  return (
    <div className="w-100 h-100 position-relative d-flex justify-content-center">
      <svg
        role="img"
        style={{ height: isMobile ? '180px' : '100%' }}
        aria-labelledby="loading-aria"
        viewBox="0 0 140 180"
        preserveAspectRatio="none"
        className={isMobile ? '' : 'position-absolute'}
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-path)"
          style={{ fill: 'url("#fill")' }}
        />
        <defs>
          <clipPath id="clip-path">
            <rect x={0} y={0} rx={0} ry={0} width={140} height={180} />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stopColor="#ededed" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="1.59996" stopColor="#d3d3d3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="2.59996" stopColor="#ededed" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

function AlbumPreviewNavigator({
  workspace,
  style,
  className,
  flatButtons,
  showHint,
}) {
  const spreadIndex = useSelector(selectActiveAlbumPreviewSpreadIndex);
  const { isLoading: isLoadingPexels, loadWhile } = useLoading(
    commonTypes.fetchingPexel,
    true
  );
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { t } = useLocale();

  const spreadIds = useMemo(
    () =>
      workspace
        ? getSpreadIds({ workspaceAndStickers: { present: { workspace } } })
        : [],
    [workspace]
  );

  /**
   * When applying a new blueprint, thus updating `spreadIds`,
   * we want to "jump back" to the cover spread.
   */
  useEffect(() => {
    dispatch(updateControls({ activeAlbumPreviewSpreadIndex: 0 }));
  }, [dispatch, spreadIds]);

  useEffect(() => {
    if (!workspace) {
      return;
    }

    loadWhile(async () => {
      await dispatch(fetchPexelsImagesForWorkspace(workspace));
    });
  }, [dispatch, loadWhile, workspace]);

  const spreadId = spreadIds?.[spreadIndex];

  const isLoading = !spreadId || !workspace.nodes[spreadId] || isLoadingPexels;

  if (isLoading) {
    return <CoverLoader />;
  }

  function handleNextPage(hideHint) {
    hideHint();
    dispatch(
      updateControls({ activeAlbumPreviewSpreadIndex: spreadIndex + 1 })
    );
    analytics.track('Blueprint Preview Navigated');
  }

  const spreadCount = spreadIds.length;
  const { pageHeight, pageWidth } = dimensions;
  const isCover = spreadIndex === 0 || spreadIndex === spreadCount - 1;

  const viewBox = `0 0 ${isCover ? pageWidth : pageWidth * 2} ${pageHeight}`;

  const { nodes } = workspace;
  const sectionNode = nodes[nodes[spreadId].parent];

  return (
    <>
      <Button
        variant="light"
        className={`p-1 spread-nav-btn qa-prev-spread-button ${
          flatButtons ? 'bg-transparent border-0' : 'shadow bg-white'
        }`}
        onClick={() =>
          dispatch(
            updateControls({ activeAlbumPreviewSpreadIndex: spreadIndex - 1 })
          )
        }
        style={{ visibility: spreadIndex === 0 && 'hidden' }}
      >
        <Icon name="chevron_left" />
      </Button>
      <div className="album-preview-svg-container qa-album-preview-svg-container w-100">
        <AlbumSvg
          viewBox={viewBox}
          className={`${className} ${isCover ? 'w-50' : 'w-100'}`}
          style={style}
          preview
        >
          <Node
            id={spreadId}
            nodeIndex={spreadIndex}
            nodeSiblingCount={spreadCount}
            renderingSpreadPosition={{
              x: 0,
              y: 0,
            }}
            sectionNode={sectionNode}
            sectionId={sectionNode.props.id}
            preview
          />
          <GridPattern gridEnabled={false} />
        </AlbumSvg>
      </div>
      <Hint
        text={t('wizard.pageHint')}
        placement="left"
        toggle={{ autoHide: false, autoOn: showHint }}
      >
        {({ target, hide: hideHint }) => (
          <Button
            variant="light"
            className={`p-1 spread-nav-btn qa-next-spread-button ${
              flatButtons ? 'bg-transparent border-0' : 'shadow bg-white'
            }`}
            onClick={() => handleNextPage(hideHint)}
            style={{ visibility: spreadIndex >= spreadCount - 1 && 'hidden' }}
            ref={target}
          >
            <Icon name="chevron_right" />
          </Button>
        )}
      </Hint>
    </>
  );
}

AlbumPreviewNavigator.defaultProps = {
  flatButtons: false,
  showHint: false,
  style: {},
  className: '',
};

AlbumPreviewNavigator.propTypes = {
  workspace: WorkspaceShape.isRequired,
  flatButtons: bool,
  showHint: bool,
  style: object, // eslint-disable-line react/forbid-prop-types
  className: string,
};

export default AlbumPreviewNavigator;
