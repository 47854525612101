import React, { useState } from 'react';
import { string } from 'prop-types';
import axios from 'axios';
import { useEffectOnce } from 'react-use';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { API_URL } from '../../../constants';
import { useFormState } from '../FormStateProvider';
import useFileUpload from '../../../hooks/useFileUpload';
import useLocale from '../../../hooks/localization/useLocale';

function Submit({ clientId, token }) {
  const { t } = useLocale();
  const [blobUploaded, setBlobUploaded] = useState(false);
  const {
    file,
    fileDetails,
    name,
    lastName,
    position,
    sectionId,
    step,
    setStep,
    setState,
  } = useFormState();

  const { upload, uploads } = useFileUpload();

  useEffectOnce(() => {
    upload(file).then(({ id: blobId }) => {
      setBlobUploaded(true);

      axios
        .post(`${API_URL}/public/sticker_uploads/${token}`, {
          sticker_upload: {
            name,
            last_name: lastName,
            position,
            section_id: sectionId,
            blob_id: blobId,
            client_id: clientId,
            details: fileDetails,
          },
        })
        .then(() => {
          setStep(step + 1);
        })
        .catch(err => {
          setState('error', err);
          setStep(step + 1);
        });
    });
  });

  const [uploadInfo] = Object.values(uploads);
  const { progress } = uploadInfo || { progress: blobUploaded ? 100 : 0 };

  return (
    <>
      <h2 className="font-weight-bold">
        {t('stickerUploadForm.uploadingHeading')}
      </h2>
      <p>{t('stickerUploadForm.uploadingMessage')}</p>
      <Form.Group>
        <Form.Label>{t('stickerUploadForm.progressLabel')}</Form.Label>
        <ProgressBar className="qa-progress" now={progress} />
      </Form.Group>
    </>
  );
}

Submit.propTypes = {
  clientId: string.isRequired,
  token: string.isRequired,
};

export default Submit;
