import React from 'react';

import FadeInWhenAlbumLoaded from '../../components/generic/FadeInWhenAlbumLoaded';
import OrderButton from './OrderButton';
import UserMenu from './UserMenu';
import TutorialButton from './TutorialButton';
import useScreenSize from '../../hooks/useScreenSize';

function AppMetaMenu() {
  const { isMobile } = useScreenSize();

  return (
    <div className="app-meta-menu qa-app-meta-menu">
      {!isMobile && (
        <FadeInWhenAlbumLoaded>
          <div>
            <OrderButton />
          </div>
        </FadeInWhenAlbumLoaded>
      )}
      <TutorialButton />
      <UserMenu />
    </div>
  );
}

export default AppMetaMenu;
