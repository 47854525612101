import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ColorPicker from 'rc-color-picker';

import ColorTiles from '../ColorTiles';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import { StylesPanelStateMachineShape } from '../../../../../shapes';
import useLocale from '../../../../../../hooks/localization/useLocale';

export function ColorContainerWithPicker({
  color,
  label,
  active,
  onClick,
  onChange,
  onExit,
}) {
  const colorPicker = (
    <Popover style={{ border: 'none', backgroundColor: 'transparent' }}>
      <ColorPicker.Panel
        className="text-secondary qa-color-picker"
        color={color}
        enableAlpha={false}
        onChange={({ color: nextColor }) => onChange(nextColor)}
      />
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={colorPicker}
      rootClose
      rootCloseEvent="mousedown"
      onExit={onExit}
    >
      <div
        className={`color-container border qa-color-container d-flex mb-2 rounded cursor-pointer${
          active ? ' active' : ''
        }`}
        onClick={onClick}
      >
        <div className="color-highlight" style={{ background: color }} />
        <div className="font-weight-bold qa-container-label">{label}</div>
        <div className="qa-container-color">{color}</div>
      </div>
    </OverlayTrigger>
  );
}

ColorContainerWithPicker.propTypes = {
  active: bool.isRequired,
  color: string.isRequired,
  label: string.isRequired,
  onChange: func.isRequired,
  onClick: func.isRequired,
  onExit: func.isRequired,
};

function CustomColorSelector({ stateMachine }) {
  const { t } = useLocale();
  const { send } = stateMachine;
  const { colors, setColors } = stateMachine;
  const [activeColor, setActiveColor] = useState(null);

  const userColors = colors.slice(2);
  const [primaryColor, secondaryColor, tertiaryColor] = userColors;

  function handleColorChange(idx, color) {
    const nextColors = [...colors];
    nextColors[idx] = color;
    setColors(nextColors);
    setActiveColor(color);
  }

  return (
    <div className="custom-color-selector d-flex flex-column">
      <DropoutTitle
        label={t('editor.sidebar.styles.custom')}
        onBack={() => send('back')}
      />
      <DropoutSection title={t('editor.sidebar.styles.changeColor')}>
        <ColorContainerWithPicker
          color={primaryColor}
          label={t('editor.sidebar.styles.primaryColor')}
          active={activeColor === primaryColor}
          onClick={() => setActiveColor(primaryColor)}
          onChange={color => handleColorChange(2, color)}
          onExit={() => setActiveColor(null)}
        />
        <ColorContainerWithPicker
          color={secondaryColor}
          label={t('editor.sidebar.styles.secondaryColor')}
          active={activeColor === secondaryColor}
          onClick={() => setActiveColor(secondaryColor)}
          onChange={color => handleColorChange(3, color)}
          onExit={() => setActiveColor(null)}
        />
        <ColorContainerWithPicker
          color={tertiaryColor}
          label={t('editor.sidebar.styles.tertiaryColor')}
          active={activeColor === tertiaryColor}
          onClick={() => setActiveColor(tertiaryColor)}
          onChange={color => handleColorChange(4, color)}
          onExit={() => setActiveColor(null)}
        />
      </DropoutSection>
      <DropoutSection title={t('editor.sidebar.styles.yourColors')}>
        <ColorTiles colors={userColors} />
      </DropoutSection>
    </div>
  );
}

CustomColorSelector.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
};

export default CustomColorSelector;
