import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import useAnalytics from './useAnalytics';
import useLocale from '../../hooks/localization/useLocale';
import Icon from '../../components/Icon';

function DeviceSwitchButton() {
  const { t } = useLocale();
  const [showModal, setShowModal] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const analytics = useAnalytics();

  function handleClick() {
    const { href } = window.location;

    analytics.track('Desktop Link Requested', {
      href,
    });
    setLinkSent(true);
  }

  const shareURL = async () => {
    const url = window.location.href;

    try {
      if (navigator.share) {
        await navigator.share({
          title: t('editor.meta.mobileModalShareTitle'),
          text: t('editor.meta.mobileModalShareText', { url }),
          url,
        });
      } else {
        await navigator.clipboard.writeText(url);
      }
    } catch (error) {
      console.error('Error sharing URL', error);
    }
  };

  return (
    <>
      <div className="device-switch-btn qa-device-switch-btn position-absolute w-100 px-3">
        <Button block size="lg" onClick={() => setShowModal(true)}>
          {t('editor.meta.mobileCta')}
          <Icon className="d-inline-block ml-2" name="arrow_right" />
        </Button>
      </div>
      <Modal
        className="desktop-modal"
        centered
        show={showModal}
        keyboard={false}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('editor.meta.mobileModalHeadline')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('editor.meta.mobileAlert')}</p>
          <p>{t('editor.meta.mobileModalContent')}</p>
          {!linkSent ? (
            <Button
              className="qa-request-link"
              variant="primary"
              block
              onClick={handleClick}
            >
              <Icon name="email" className="mr-3 d-inline-block" />
              {t('editor.meta.mobileModalCta')}
            </Button>
          ) : (
            <Alert className="border">
              {t('editor.meta.mobileModalConfirmation')}
            </Alert>
          )}
          <Button variant="outline-primary" block onClick={shareURL}>
            <Icon name="share" className="mr-3 d-inline-block" />
            {t('editor.meta.mobileModalShareCta')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

DeviceSwitchButton.propTypes = {};

export default DeviceSwitchButton;
