/**
 * The Root component is used to render the `root` node in the `workspace` reducer.
 */
import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Flipper, Flipped } from 'react-flip-toolkit';

import { getSpreadIds, getWorkspace } from '../../../selectors/legacy';
import { useNode } from '../NodeProvider';
import useScreenSize from '../../../hooks/useScreenSize';

function Root({ preview }) {
  const spreadIds = useSelector(getSpreadIds);
  const { nodes } = useSelector(getWorkspace);
  const Node = useNode();
  const { isMobile } = useScreenSize();

  const flipKey = isMobile ? 'no-animation' : spreadIds.join('');

  return (
    <Flipper flipKey={flipKey} element="g">
      {spreadIds.map((spreadId, index) => {
        const { parent } = nodes[spreadId];
        const sectionNode = nodes[parent];

        return (
          <Flipped key={spreadId} flipId={spreadId}>
            {flippedProps => (
              <Node
                id={spreadId}
                nodeIndex={index}
                nodeSiblingCount={spreadIds.length}
                sectionId={sectionNode.props.id}
                sectionNode={sectionNode}
                preview={preview}
                {...flippedProps}
              />
            )}
          </Flipped>
        );
      })}
    </Flipper>
  );
}

Root.defaultProps = {
  preview: false,
};

Root.propTypes = {
  preview: bool,
};

export default Root;
