import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import usePexel from '../../../hooks/usePexel';
import { getImage } from '../../../selectors/images';
import { ImageIdShape } from '../../shapes';
import { ImageContext } from '../../ImageContext';

const withImageResolver = WrappedComponent => {
  const Component = props => {
    const { image: imageId, pexelsId } = props;

    /**
     * Resolve the imageObject via image id. In some cases, we're working
     * inside a Context here - e. g. inside the `LayoutsPanel`, we're rendering
     * spread previews containing nodes with image IDs from the original blueprint
     * workspace that do not exist in our copied album `images`. We're thus "overriding"
     * the image lookup via the context here.
     */
    const { images: imagesFromContext } = useContext(ImageContext);

    const imageObjectFromContext = imagesFromContext?.find(
      image => image.id === imageId
    );
    const imageObjectFromStore = useSelector(state => getImage(state, imageId));

    // Resolve pexelsId to pexelsObject
    const { resolvePexelsId } = usePexel();
    const { value: pexelsObject } = useAsync(
      async () => resolvePexelsId(pexelsId),
      [pexelsId]
    );

    return (
      <WrappedComponent
        {...props}
        imageObject={imageObjectFromContext || imageObjectFromStore}
        pexelsObject={pexelsObject}
      />
    );
  };

  Component.defaultProps = {
    image: null,
    pexelsId: null,
  };

  Component.propTypes = {
    image: ImageIdShape,
    pexelsId: ImageIdShape,
  };

  return Component;
};

export default withImageResolver;
