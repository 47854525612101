import React from 'react';
import { node } from 'prop-types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  applyBlueprintSpread,
  insertElements,
} from '../../../actions/workspace';
import {
  moveSticker,
  unlinkStickerCellForStickerId,
} from '../../../actions/stickers';
import { elementSelect } from '../../../modules/selection';
import { historyAnchor } from '../../../modules/history';
import { dimensions, itemTypes } from '../../../constants';
import { generateId } from '../../../util/index';
import { pointToSpreadIndex } from '../../../util/generators';
import { computeNativeImageSize } from '../../../util/images';
import useImageUpload from '../../../hooks/useImageUpload';
import { getSpreadIds, getWorkspace } from '../../../selectors/legacy';
import { useConfirmation } from '../../ui/ConfirmModal/ConfirmationService';
import useViewport from './useViewport';
import { getGridEnabled } from '../../../selectors/controls';
import { satisfyGrid } from '../../../util/geometry';
import useAnalytics from '../../../containers/app/useAnalytics';
import useLocale from '../../../hooks/localization/useLocale';

function ViewportDropzone({ children }) {
  const { t } = useLocale();
  const spreadIds = useSelector(getSpreadIds);
  const { nodes } = useSelector(getWorkspace);
  const { clientToViewport } = useViewport();
  const gridEnabled = useSelector(getGridEnabled);

  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const { createWorkspaceImage } = useImageUpload();

  const confirm = useConfirmation();

  const [, drop] = useDrop({
    accept: [
      itemTypes.element,
      itemTypes.image,
      itemTypes.stock,
      NativeTypes.FILE,
      itemTypes.spread,
      itemTypes.sticker,
    ],
    drop(item, monitor) {
      // We need to prevent handling of dropped items if they were
      // already processed by another (child) drop target.
      if (monitor.didDrop()) {
        return;
      }

      const coords = monitor.getClientOffset();
      const type = monitor.getItemType();

      const viewportCoords = clientToViewport(coords);
      const spreadIndex = pointToSpreadIndex(viewportCoords, spreadIds.length);
      const spreadId = spreadIds[spreadIndex];
      const nextSectionId = nodes[spreadId].parent;

      const yOffset =
        (dimensions.pageHeight + dimensions.pagePadding) * spreadIndex;

      const xOffset = spreadIndex === 0 ? dimensions.pageWidth : 0;

      const spreadCoords = {
        x: viewportCoords.x - xOffset,
        y: viewportCoords.y - yOffset,
      };

      const { x, y } = gridEnabled ? satisfyGrid(spreadCoords) : spreadCoords;

      analytics.track('Element Created', {
        type,
        drop: true,
      });

      switch (type) {
        case itemTypes.sticker:
        case itemTypes.image:
        case itemTypes.stock:
        case itemTypes.element: {
          let newNode = {};

          const nodeId = generateId();

          if (
            type === itemTypes.element ||
            type === itemTypes.image ||
            type === itemTypes.stock
          ) {
            // Deep clone the element
            newNode = JSON.parse(JSON.stringify(item.data));
            newNode.props = {
              ...newNode.props,
              id: nodeId,
              x,
              y,
            };
          }

          /**
           * When dropping stickers from the sidebar, we need to first create a
           * sticker cell element, then link it to the dropped sticker item.
           */
          if (type === itemTypes.sticker) {
            const { stickerWidth, stickerHeight } = dimensions;

            newNode = {
              type: 'StickerCell',
              props: {
                id: nodeId,
                x,
                y,
                height: stickerHeight,
                width: stickerWidth,
                stickerId: item.id,
              },
            };
          }

          batch(() => {
            if (type === itemTypes.sticker) {
              dispatch(unlinkStickerCellForStickerId(item.id));
              dispatch(moveSticker(item.id, nextSectionId));
            }
            dispatch(insertElements([newNode], spreadId));
            dispatch(elementSelect([nodeId]));
            dispatch(historyAnchor());
          });

          break;
        }
        case itemTypes.spread: {
          const { blueprintSpreadId } = item.data;

          if (nodes[spreadId].children.length === 0) {
            dispatch(applyBlueprintSpread(blueprintSpreadId, spreadId));
            dispatch(historyAnchor());
            break;
          }

          /**
           * We open a confirm modal here whenever the spread that is to be
           * replaced by a layout has children, any changes are applied only
           * after user confirm.
           */
          confirm({
            body: t('editor.sidebar.layouts.placeWarning'),
          })
            .then(() => {
              dispatch(applyBlueprintSpread(blueprintSpreadId, spreadId));
              dispatch(historyAnchor());
            })
            .catch(() => {});
          break;
        }
        case NativeTypes.FILE: {
          const { files } = monitor.getItem();

          Array.from(files).forEach(async file => {
            const nodeId = generateId();
            const image = await createWorkspaceImage(file);

            if (!image) {
              return;
            }

            const newNode = {
              id: nodeId,
              type: 'Image',
              props: {
                id: nodeId,
                image: image.id,
                x,
                y,
                ...computeNativeImageSize(image),
              },
            };

            batch(() => {
              dispatch(insertElements([newNode], spreadId));
              dispatch(elementSelect([nodeId]));
              dispatch(historyAnchor());
            });
          });

          break;
        }
        default: {
          // eslint-disable-next-line no-console
          console.error('invalid type dropped');
        }
      }
    },
  });
  return (
    <div ref={drop} className="w-100 position-relative">
      {children}
    </div>
  );
}

ViewportDropzone.propTypes = {
  children: node.isRequired,
};

export default ViewportDropzone;
