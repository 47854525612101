// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import Button from 'react-bootstrap/Button';

import useLocale from '../hooks/localization/useLocale';

function ErrorBoundary({ children }) {
  const { t } = useLocale();
  const [errorInfo, setErrorInfo] = useState(null);

  const handleError = error => {
    console.error(error);
    Sentry.captureException(error);
    setErrorInfo(error.message);
  };

  useEffect(() => {
    const windowError = ({ error }) => {
      if (error) {
        handleError(error);
      }
    };

    window.addEventListener('error', windowError);

    return () => {
      window.removeEventListener('error', windowError);
    };
  }, []);

  if (errorInfo) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="text-center w-25">
          <h1>Oh no :(</h1>
          <p>{t('errorBoundary')}</p>
          <p>
            <code>Error: {errorInfo}</code>
          </p>
          <Button onClick={() => window.location.reload()}>Neu Laden</Button>
        </div>
      </div>
    );
  }

  return children;
}

export default connect()(ErrorBoundary);
