import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';

import useAutosave from './useAutosave';
import { createWarning } from '../../actions/notifications';
import { setUnsavedChanges } from '../../modules/albums';
import useLocale from '../../hooks/localization/useLocale';

/**
 * Component responsible for handling autosave-related side-effects:
 * - showing a loading spinner
 * - handling errors
 * @param {object} config { albumId }
 */
function Autosaver({ albumId }) {
  const { t } = useLocale();
  const { synced, error } = useAutosave(albumId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(createWarning(t('editor.meta.saveError')));
    }
  }, [error, dispatch, t]);

  useEffect(() => {
    dispatch(setUnsavedChanges(!synced));
  }, [dispatch, synced]);

  return synced && <div className="qa-synced" />;
}

Autosaver.propTypes = {
  albumId: string.isRequired,
};

export default Autosaver;
