import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';

import ButtonWithTooltip from '../../../menu/ButtonWithTooltip';
import { getShowStickers } from '../../../../selectors/controls';
import { updateControls } from '../../../../modules/controls';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';
import useLocale from '../../../../hooks/localization/useLocale';

function TogglePrintPreviewButton() {
  const dispatch = useDispatch();
  const showStickers = useSelector(getShowStickers);
  const { t } = useLocale();

  const title = showStickers
    ? `${t('editor.meta.printPreview.turnOn')}`
    : `${t('editor.meta.printPreview.turnOff')}`;

  return (
    <FadeInWhenAlbumLoaded>
      <div className="mr-2">
        <ButtonGroup className="print-preview rounded shadow">
          <ButtonWithTooltip
            placement="top"
            icon="printer"
            size={null}
            tooltip={title}
            onClick={() =>
              dispatch(updateControls({ showStickers: !showStickers }))
            }
            active={!showStickers}
          />
        </ButtonGroup>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

export default TogglePrintPreviewButton;
