import React from 'react';
import { arrayOf, array, shape } from 'prop-types';
import Form from 'react-bootstrap/Form';

import { useFormState } from '../FormStateProvider';
import useLocale from '../../../hooks/localization/useLocale';

function Section({ config: { sections } }) {
  const { t } = useLocale();
  const { sectionId, setState } = useFormState();

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>{t('stickerUploadForm.sectionLabel')}</Form.Label>
          <Form.Control
            name="section_id"
            as="select"
            size="lg"
            value={sectionId}
            onChange={({ target: { value } }) => setState('sectionId', value)}
          >
            <option value="">{t('stickerUploadForm.dontKnow')}</option>
            {sections.map(([id, name]) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="text-muted">
            {t('stickerUploadForm.sectionHelp')}
          </Form.Text>
        </Form.Group>
      </Form>
    </>
  );
}

Section.propTypes = {
  config: shape({
    sections: arrayOf(array), // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default Section;
