import React from 'react';
import { arrayOf, func } from 'prop-types';
import { batch, useDispatch } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

import { NodeShape } from '../../shapes';
import { elementSelect } from '../../../modules/selection';
import { resetViewToSpreadOfSelection } from '../../../modules/controls';
import useLocale from '../../../hooks/localization/useLocale';

export function CommentCard({ comment, onClick }) {
  return (
    <Card
      className="comment-card qa-comment-card mb-2 cursor-pointer"
      bg="light"
      onClick={onClick}
    >
      <Card.Header>
        <Badge className="float-right" variant="primary">
          Stickerstars
        </Badge>
      </Card.Header>
      <Card.Body className="qa-comment-card-body overflow-auto">
        {comment.props.text}
      </Card.Body>
    </Card>
  );
}

CommentCard.defaultProps = {
  onClick: null,
};

CommentCard.propTypes = {
  comment: NodeShape.isRequired,
  onClick: func,
};

function CommentsList({ comments }) {
  const { t } = useLocale();
  const dispatch = useDispatch();

  /**
   * On comment card click, we select the comment element (thus open it),
   * and reset the viewport to its parent spread.
   */
  const handleCommentClick = id => {
    batch(() => {
      dispatch(elementSelect([id]));
      dispatch(resetViewToSpreadOfSelection());
    });
  };

  return (
    <div className="comments-list scrollable qa-comments-list">
      {comments.length > 0 ? (
        comments.map(comment => (
          <CommentCard
            key={comment.props.id}
            comment={comment}
            onClick={() => handleCommentClick(comment.props.id)}
          />
        ))
      ) : (
        <div className="qa-no-comments">
          {t('editor.sidebar.review.noComments')}
        </div>
      )}
    </div>
  );
}

CommentsList.propTypes = {
  comments: arrayOf(NodeShape).isRequired,
};

export default CommentsList;
