import React, { useLayoutEffect, useRef, useState } from 'react';
import { func, number, string, bool } from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useClickAway } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../../../Icon';
import LocalStickerUploadsButton from './LocalStickerUploadsButton';
import ButtonWithTooltip from '../../../menu/ButtonWithTooltip';
import { maxImageUploads } from '../../../../constants';
import LocalStickerUploadsModal from './LocalStickerUploadsModal';
import { generateId } from '../../../../util';
import { createSticker, deleteSticker } from '../../../../actions/stickers';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import useImageUpload from '../../../../hooks/useImageUpload';
import useNotifications from '../../../../hooks/useNotifications';
import useAnalytics from '../../../../containers/app/useAnalytics';
import { useConfirmation } from '../../ConfirmModal/ConfirmationService';
import useLocale from '../../../../hooks/localization/useLocale';
import { selectCurrentAlbum } from '../../../../selectors/albums';

function SectionSidebarMenu({
  filter,
  setFilter,
  setOpen,
  itemsCount,
  hasSelection,
  onDestroy,
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const { startLoading, stopLoading } = useLoading(commonTypes.uploadingFiles);
  const { createStickerImage } = useImageUpload();
  const { createError, createWarning } = useNotifications();
  const analytics = useAnalytics();
  const confirm = useConfirmation();
  const { t } = useLocale();
  const albumId = useSelector(selectCurrentAlbum);

  function toggleSearchOpen() {
    const nextSearchOpen = !searchOpen;
    setSearchOpen(nextSearchOpen);

    // Clear filter when closing search
    if (!nextSearchOpen) {
      setFilter('');
    }
  }

  const searchInputRef = useRef();

  useClickAway(searchInputRef, searchOpen ? toggleSearchOpen : null);

  useLayoutEffect(() => {
    if (searchOpen) {
      searchInputRef.current.focus();
    }
  }, [searchOpen]);

  const handleSearchChange = event => setFilter(event.target.value);

  const [modalStickers, setModalStickers] = useState([]);

  const dispatch = useDispatch();

  /**
   * Handles sticker creation from file uploads:
   * 1. Creates images on the server via createStickerImage (handles upload process)
   * 2. Creates sticker objects in the Redux store with references to the images
   * 3. Opens a modal that allows editing the created stickers
   *
   * The modal works directly with the stickers in the Redux store,
   * so changes are immediately applied to the application state.
   */
  async function handleRequestUpload(event) {
    const files = Array.from(event.target.files);

    if (files.length > maxImageUploads) {
      createWarning(
        t('editor.imageUpload.maxImagesError', { max: maxImageUploads })
      );
      return;
    }

    try {
      startLoading();
      files.forEach(async file => {
        const stickerId = generateId();
        const image = await createStickerImage(file);

        if (!image) {
          return; // eslint-disable-line no-continue
        }

        const stickerName =
          file.name?.split('.')?.[0] ||
          t('editor.sidebar.stickers.defaultStickerName');

        const sticker = {
          id: stickerId,
          name: stickerName,
          position: '',
          doubleSticker: false,
          sectionId: '',
          image: image.id,
        };

        dispatch(createSticker(sticker));

        analytics.track('Sticker Created', {
          id: stickerId,
          fromFile: true,
          drop: false,
          albumId,
        });

        setModalStickers(prev => [...prev, { id: stickerId }]);
      });
    } catch (err) {
      createError(t('genericError'));
    } finally {
      stopLoading();
    }
  }

  function handleModalClose(stickers, keepStickers = false) {
    setModalStickers([]);

    if (!keepStickers) {
      stickers.forEach(sticker => {
        dispatch(deleteSticker(sticker.id));
      });
    }
  }

  const handleDestroy = () => {
    confirm({
      body: t('editor.sidebar.stickers.stickerDeleteWarning'),
    })
      .then(onDestroy)
      .catch(() => {});
  };

  return (
    <div className="sidebar-menu py-3 qa-sidebar-section-panel">
      {searchOpen ? (
        <Form.Control
          name="sticker_search"
          className="w-100 qa-sticker-search"
          placeholder={t('editor.sidebar.stickers.search')}
          onChange={handleSearchChange}
          ref={searchInputRef}
          value={filter}
          type="text"
        />
      ) : (
        <>
          {hasSelection ? (
            <button
              type="button"
              className="btn btn-danger w-30 destroy-button qa-destroy-item"
              onClick={handleDestroy}
            >
              <Icon name="trash" />
            </button>
          ) : (
            <>
              <LocalStickerUploadsButton onChange={handleRequestUpload}>
                {t('upload')}
              </LocalStickerUploadsButton>
              {modalStickers.length > 0 && (
                <LocalStickerUploadsModal
                  initialStickers={modalStickers}
                  onClose={stickers => handleModalClose(stickers, false)}
                  onSubmit={stickers => handleModalClose(stickers, true)}
                >
                  {t('upload')}
                </LocalStickerUploadsModal>
              )}
            </>
          )}
          <div className="items-count grow">
            <span className="qa-sticker-count">{`${itemsCount} ${t(
              'stickersPlural'
            )}`}</span>
          </div>
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-open-all-sections"
            icon="plus_square"
            tooltip={t('editor.sidebar.stickers.expand')}
            onClick={() => setOpen(true)}
          />
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-close-all-sections"
            icon="minus_square"
            tooltip={t('editor.sidebar.stickers.collapse')}
            onClick={() => setOpen(false)}
          />
          <ButtonWithTooltip
            className="sidebar-menu-button mx-1 qa-toggle-search"
            icon="search"
            tooltip={t('editor.sidebar.stickers.search')}
            onClick={toggleSearchOpen}
          />
        </>
      )}
    </div>
  );
}

SectionSidebarMenu.propTypes = {
  filter: string.isRequired,
  setFilter: func.isRequired,
  itemsCount: number.isRequired,
  hasSelection: bool.isRequired,
  onDestroy: func.isRequired,
  setOpen: func.isRequired,
};

export default SectionSidebarMenu;
