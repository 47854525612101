import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import de from 'date-fns/locale/de';
import Table from 'react-bootstrap/Table';

import TableLoader from './TableLoader';
import { DashboardOrderShape, OrderStatusShape } from './shapes';

const composeClassNames = classNames => {
  return Object.keys(classNames)
    .filter(key => classNames[key])
    .join(' ');
};

export const renderCountryIcon = countryCode => {
  switch (countryCode) {
    case 'DE':
      return '🇩🇪';
    case 'AT':
      return '🇦🇹';
    case 'CH':
      return '🇨🇭';
    case 'LU':
      return '🇱🇺';
    case 'BE':
      return '🇧🇪';
    case 'NL':
      return '🇳🇱';
    case 'FR':
      return '🇫🇷';
    case 'IT':
      return '🇮🇹';
    case 'IE':
      return '🇮🇪';
    case 'FI':
      return '🇫🇮';
    case 'PL':
      return '🇵🇱';
    case 'GB':
      return '🇬🇧';
    default:
      return '';
  }
};

function OrderStatus({ orderStatus }) {
  const { status, date, target } = orderStatus;

  if (!date) {
    return '-';
  }

  const dateAsDate = new Date(date);
  const dateFormatted = format(dateAsDate, 'dd.MM.yyyy');

  const ConditionalLink = () =>
    target ? (
      <a href={target} target="_blank" rel="noreferrer">
        {`✔️ ${dateFormatted}`}
      </a>
    ) : (
      `✔️ ${dateFormatted}`
    );

  const isOverdue = dateAsDate < new Date();
  const isSameWeek = format(dateAsDate, 'w') === format(new Date(), 'w');

  const textClass = composeClassNames({
    'text-danger': isOverdue,
    'text-warning': isSameWeek,
  });

  return (
    <>
      {status === 'pending' ? (
        <span className={textClass}>
          <span role="img" aria-label="Clock emoji">
            🕙
          </span>{' '}
          {dateFormatted}
        </span>
      ) : (
        <ConditionalLink />
      )}
      <br />
      {formatDistance(dateAsDate, new Date(), {
        addSuffix: true,
        locale: de,
      })}
    </>
  );
}

OrderStatus.propTypes = {
  orderStatus: OrderStatusShape.isRequired,
};

function OrdersTable({ data, isLoading }) {
  if (isLoading || !data || !data.orders) {
    return <TableLoader />;
  }

  const { orders } = data;

  return (
    <Table hover size="sm" className="small">
      <thead>
        <tr>
          <th width="8%">Cover</th>
          <th width="18%">Name</th>
          <th width="12%">Erstellt</th>
          <th width="12%">Event</th>
          <th>Check?</th>
          <th width="14%">Transfer</th>
          <th width="14%">Alben</th>
          <th width="14%">Versand</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => (
          <tr key={order.id}>
            <td width="7%">
              <Link to={`/album/${order.album.id}`}>
                <img
                  src={order.album.cover_preview_url}
                  alt={order.album.title}
                  className="img-fluid"
                />
              </Link>
            </td>
            <td className="align-middle">
              <Link to={`/orders/${order.id}`}>{order.name}</Link>{' '}
              {renderCountryIcon(order.shipping_address.country_code)}
              <br />
              {order.email}
            </td>
            <td className="align-middle">
              {format(new Date(order.created_at), 'dd.MM.yyyy')}
              <br />
              {formatDistance(new Date(order.created_at), new Date(), {
                addSuffix: true,
                locale: de,
              })}
              <br />
            </td>
            <td className="align-middle qa-order-release-date">
              {order.album.release_date ? (
                <>
                  {format(new Date(order.album.release_date), 'dd.MM.yyyy')}
                  <br />
                  {formatDistance(
                    new Date(order.album.release_date),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: de,
                    }
                  )}
                </>
              ) : (
                '-'
              )}
            </td>
            <td className="align-middle qa-order-design-check">
              {order.design_check ? 'JA' : 'NEIN'}
            </td>
            <td className="align-middle qa-order-transfer-status">
              <OrderStatus orderStatus={order.transfer_status} />
            </td>
            <td className="align-middle qa-order-albums-status">
              <OrderStatus orderStatus={order.albums_status} />
            </td>
            <td className="align-middle qa-order-shipment-status">
              <OrderStatus orderStatus={order.shipment_status} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

OrdersTable.defaultProps = {
  data: null,
};

OrdersTable.propTypes = {
  data: shape({
    orders: arrayOf(DashboardOrderShape),
  }),
  isLoading: bool.isRequired,
};

export default OrdersTable;
