import at from 'lodash/at';
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import pl from 'date-fns/locale/pl';

import translations from './translations';

const dateLocales = {
  de,
  en: enGB,
  fr,
  it,
  pl,
};

export function insertValues(template, values) {
  return template?.replace(/%\{(\w+)\}/g, (match, key) =>
    key in values ? values[key] : match
  );
}

export function translate({ id, values = {}, environment, locale }) {
  const [env, fallback] = at(
    translations[locale],
    `${environment}.${id}`,
    `default.${id}`
  );

  return `${insertValues(env || fallback || id, values)}`;
}

export function formatDate(date, dateFormat = 'PPpp', locale) {
  return format(date, dateFormat, { locale: dateLocales[locale] });
}

export function formatDistance(startDate, endDate, locale) {
  return formatRelative(startDate, endDate, {
    locale: dateLocales[locale],
    addSuffix: true,
  });
}
