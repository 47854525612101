import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

import AppLayout from '../../components/ui/AppLayout';
import useAuth from '../../hooks/useAuth';
import useApi from '../../hooks/useApi';
import { setAlbums } from '../../modules/albums';
import OrdersList from '../orders/OrdersList';
import AccountActivation from './AccountActivation';
import RedirectWithSearchParams from '../app/withSearchParams';
import useLocale from '../../hooks/localization/useLocale';

function Profile() {
  const { t } = useLocale();
  const auth = useAuth();
  const api = useApi();
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/albums');
      dispatch(setAlbums(data.albums));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    user: { email, nickname, picture },
  } = auth;

  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">{t('profile.headline')}</h1>
          <p>{t('profile.subline')}</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={4} md={4} className="mb-3">
          <div className="card card-profile border">
            <div className="card-body">
              <div className="text-center">
                <div>
                  <img
                    alt="User gravatar"
                    src={picture}
                    className="rounded-circle my-4"
                    width={80}
                  />
                </div>
                <h4>{nickname}</h4>
                <div>{email}</div>
              </div>
            </div>
          </div>
          <Switch>
            <Route path={`${path}/activate`}>
              <AccountActivation />
            </Route>
            <RedirectWithSearchParams to={path} />
          </Switch>
        </Col>
        <Col lg={8} md={8}>
          <h2>{t('profile.ordersHeadline')}</h2>
          <OrdersList perPage={3} />
        </Col>
      </Row>
    </AppLayout>
  );
}

export default Profile;
