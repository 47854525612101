import React from 'react';
import { node } from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import AlbumTitle from '../../components/ui/Sidebar/AlbumTitle';
import OnboardingPanel from '../../components/ui/OnboardingPanel/OnboardingPanel';
import Icon from '../../components/Icon';
import useScreenSize from '../../hooks/useScreenSize';
import TutorialImage from '../../assets/images/tutorial.jpg';
import FaqImage from '../../assets/images/faq.jpg';
import BudgetImage from '../../assets/images/budget.jpg';
import useTutorial from '../../hooks/useTutorial';
import { salesContact } from '../../constants';
import { selectAlbumOrganization } from '../../selectors/albums';
import useAnalytics from '../app/useAnalytics';
import useLocale from '../../hooks/localization/useLocale';

function ContactLink({ children }) {
  const beaconDefined = !!window.Beacon;

  return (
    <a
      href={beaconDefined ? '#' : 'mailto:support@stickerstars.de'}
      onClick={() => beaconDefined && window.Beacon('open')}
    >
      {children}
    </a>
  );
}

ContactLink.propTypes = {
  children: node.isRequired,
};

function SalesContactAlert() {
  const analytics = useAnalytics();
  const { t } = useLocale();

  function handleClick() {
    analytics.track('Large Organization Scheduler Link Clicked');
  }

  return (
    <Alert variant="info" className="qa-sales-contact-alert">
      <div className="d-flex align-items-center">
        <div className="pr-3">
          <div
            className="avatar"
            style={{
              backgroundImage: `url('${salesContact.avatarLink}')`,
            }}
          />
        </div>
        <p>
          {t('editor.onboarding.salesContactAlert')}{' '}
          <a
            onClick={handleClick}
            href={salesContact.schedulerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('editor.onboarding.schedulerLink')}
          </a>
        </p>
      </div>
    </Alert>
  );
}

function EditorOnboarding() {
  const { isMobile } = useScreenSize();
  const { linkTo } = useTutorial();
  const { t } = useLocale();

  const organization = useSelector(selectAlbumOrganization);

  return (
    <div className="w-100 h-100 scrollable qa-onboarding-content">
      {!isMobile && (
        <div className="editor-onboarding-album-title-wrapper">
          <AlbumTitle />
        </div>
      )}
      <div className="bg-lighter w-100 p-3">
        <div className="container">
          <div className="b-1 border-bottom px-0 py-3 d-flex flex-column align-items-center justify-content-between">
            <Row>
              <Col lg={8} md={12}>
                <Alert className="px-0">
                  <Alert.Heading>
                    {t('editor.onboarding.headline')}
                  </Alert.Heading>
                  {t('editor.onboarding.subline')}
                </Alert>
              </Col>
              <Col
                lg={4}
                md={12}
                className="flex align-items-end justify-content-center"
              >
                <Link to="stickers">
                  <Button variant="outline-primary" size="sm">
                    {t('editor.onboarding.toAlbum')}
                    <Icon className="d-inline-block ml-2" name="arrow_right" />
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                {organization?.size >
                  salesContact.organizationSizeThreshold && (
                  <SalesContactAlert />
                )}
              </Col>
            </Row>
          </div>

          <div>
            <OnboardingPanel />
          </div>
        </div>
      </div>
      <div className="my-4 container">
        <h2 className="h5">{t('editor.onboarding.usefulLinks')}</h2>
        <Row>
          <Col lg={4}>
            {' '}
            <a
              href={linkTo('start')}
              target="_blank"
              rel="noreferrer"
              className="text-dark"
            >
              <Card className="border mb-4">
                <Card.Img variant="top" src={TutorialImage} />

                <Card.Body>
                  <Card.Title>
                    {t('editor.onboarding.tutorial.headline')}
                  </Card.Title>
                  <Card.Text>
                    {t('editor.onboarding.tutorial.subline')}
                  </Card.Text>
                </Card.Body>
              </Card>{' '}
            </a>
          </Col>
          <Col lg={4}>
            {' '}
            <a
              href={linkTo('faq')}
              target="_blank"
              rel="noreferrer"
              className="text-dark"
            >
              <Card className="border mb-4">
                <Card.Img variant="top" src={FaqImage} />

                <Card.Body>
                  <Card.Title>{t('editor.onboarding.faq.headline')}</Card.Title>
                  <Card.Text>{t('editor.onboarding.faq.subline')}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={4}>
            <a
              href={linkTo('budget')}
              target="_blank"
              rel="noreferrer"
              className="text-dark"
            >
              <Card className="border mb-4">
                <Card.Img variant="top" src={BudgetImage} />
                <Card.Body>
                  <Card.Title>
                    {t('editor.onboarding.budget.headline')}
                  </Card.Title>
                  <Card.Text>{t('editor.onboarding.budget.subline')}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}

EditorOnboarding.defaultProps = {};

EditorOnboarding.propTypes = {};

export default EditorOnboarding;
