import React from 'react';
import { bool, func, node, oneOfType, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import useLocale from '../../../hooks/localization/useLocale';

function ConfirmModal({
  open,
  onSubmit,
  onClose,
  body,
  declineText,
  confirmText,
  headerTitle,
  backdrop,
  keyboard,
  closeButton,
}) {
  const { t } = useLocale();

  return (
    <Modal
      className="qa-confirm-modal"
      show={open}
      onHide={onClose}
      backdrop={backdrop}
      keyboard={keyboard}
      centered
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>
          {headerTitle || t('confirmModal.defaultHeadline')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="qa-confirm-modal-body">{body}</Modal.Body>
      <Modal.Footer>
        <Button className="qa-cancel-confirm" variant="link" onClick={onClose}>
          {declineText || t('cancel')}
        </Button>
        <Button
          className="qa-accept-confirm"
          variant="primary"
          onClick={onSubmit}
        >
          {confirmText || t('confirmModal.defaultConfirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.defaultProps = {
  body: '',
  confirmText: null,
  declineText: null,
  headerTitle: null,
  backdrop: true,
  keyboard: true,
  closeButton: true,
};

ConfirmModal.propTypes = {
  open: bool.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  body: node,
  confirmText: node,
  declineText: node,
  headerTitle: node,
  backdrop: oneOfType([bool, string]),
  keyboard: bool,
  closeButton: bool,
};

export default ConfirmModal;
