import { func, objectOf, string } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSection,
  createSticker,
  updateSticker,
} from '../../../actions/stickers';
import useAnalytics from '../../../containers/app/useAnalytics';
import { setEditingStickerId } from '../../../modules/controls';
import { historyAnchor } from '../../../modules/history';
import { getEditingStickerId } from '../../../selectors/controls';
import { generateId } from '../../../util/index';
import { IdListShape, RefShape, SectionShape } from '../../shapes';
import SectionInput from '../SectionInput/index.container';
import StickerFormPopup from './StickerForm';
import useStickerForm from './StickerForm/useStickerForm';
import StickerUploadsAlert from './StickerUploadsAlert';
import { selectCurrentAlbum } from '../../../selectors/albums';

const SectionPanel = ({
  filter,
  sectionIds,
  sectionsById,
  addNewSection,
  sidebarRef,
  openSectionIds,
  setOpenSectionIds,
}) => {
  const dispatch = useDispatch();
  const { stickerFormData, reset, selectedSticker } = useStickerForm();
  const analytics = useAnalytics();
  const albumId = useSelector(selectCurrentAlbum);

  const toggleCollapse = useCallback(
    id => {
      if (openSectionIds.includes(id)) {
        setOpenSectionIds(openSectionIds.filter(item => item !== id));
      } else {
        setOpenSectionIds([...openSectionIds, id]);
      }
      dispatch(setEditingStickerId(null));
    },
    [dispatch, openSectionIds, setOpenSectionIds]
  );

  // Determine editing section-id
  const editingStickerId = useSelector(getEditingStickerId);
  const editingSectionId = editingStickerId && selectedSticker?.sectionId;

  // Open editing section-id if necessary
  useEffect(() => {
    if (!editingSectionId) {
      return;
    }

    if (!openSectionIds.includes(editingSectionId)) {
      setOpenSectionIds([...openSectionIds, editingSectionId]);
    }
  }, [editingSectionId, setOpenSectionIds, openSectionIds]);

  const createSectionAndOpenIt = section => {
    setOpenSectionIds([...openSectionIds, section.id]);
    addNewSection(section);
  };

  const handleSubmit = updatedSticker => {
    const { placeholder } = stickerFormData;
    let { sectionId } = stickerFormData;

    if (placeholder) {
      if (updatedSticker.name.trim() === '') {
        return;
      }

      if (!sectionId) {
        sectionId = generateId();
        const newSection = {
          id: sectionId,
          name: 'Unbenanntes Team',
          stickers: [],
        };
        dispatch(createSection(newSection));

        analytics.track('Section Created', {
          id: newSection.id,
          name: newSection.name,
        });
      }

      const newSticker = {
        ...updatedSticker,
        id: generateId(),
        sectionId,
      };
      dispatch(createSticker(newSticker));

      analytics.track('Sticker Created', {
        id: newSticker.id,
        fromFile: false,
        drop: false,
        albumId,
      });
    } else {
      dispatch(updateSticker(updatedSticker.id, updatedSticker));

      analytics.track('Sticker Updated', {
        id: updatedSticker.id,
      });
    }
    dispatch(historyAnchor());
  };

  const scrollRefIntoView = node => node?.scrollIntoView();

  return (
    <div className="sidebar-panel section-panel scrollable qa-section-content qa-sections-list position-relative mt-0">
      <StickerUploadsAlert />
      <Flipper flipKey={sectionIds.join('')}>
        {sectionIds.map((sectionId, index) => (
          <div
            key={sectionId}
            ref={sectionId === editingSectionId ? scrollRefIntoView : null}
          >
            <SectionInput
              index={index}
              sectionId={sectionId}
              section={sectionsById[sectionId]}
              sectionIds={sectionIds}
              filter={filter}
              open={openSectionIds.includes(sectionId)}
              onCollapseClick={() => toggleCollapse(sectionId)}
              createSection={createSectionAndOpenIt}
            />
          </div>
        ))}
      </Flipper>
      <SectionInput
        placeholder
        sectionId="new"
        sectionIds={sectionIds}
        createSection={createSectionAndOpenIt}
      />
      {stickerFormData.stickerId && (
        <StickerFormPopup
          key={`sticker-form-${stickerFormData.stickerId}`}
          sticker={selectedSticker}
          onSubmit={handleSubmit}
          onClose={reset}
          sidebarRef={sidebarRef}
        />
      )}
    </div>
  );
};

SectionPanel.propTypes = {
  filter: string.isRequired,
  sectionIds: IdListShape.isRequired,
  sectionsById: objectOf(SectionShape).isRequired,
  openSectionIds: IdListShape.isRequired,
  setOpenSectionIds: func.isRequired,
  addNewSection: func.isRequired,
  sidebarRef: RefShape.isRequired,
};

export default SectionPanel;
