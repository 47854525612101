import Color from 'color';
import { func, number, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';

import { makeGetColorByIndexOrValue } from '../../../../selectors/colorsAndFonts';
import SwatchColorPicker from '../../../ui/SwatchColorPicker';
import DropdownWithTooltip from '../DropdownWithTooltip';
import withInputProps from './withInputProps';
import useLocale from '../../../../hooks/localization/useLocale';

const previewRectSize = 20;

const previewStrokeWidthScale = 0.16;

const shadowLuminosityThreshold = 0.7;

const strokeStep = 1;

function StrokeInput({
  onChange,
  stroke: strokeFromProps,
  strokeWidth: strokeWidthFromProps,
  ...rest
}) {
  const { t } = useLocale();
  const strokeWidth =
    parseInt(strokeWidthFromProps / strokeStep, 10) * strokeStep;

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const getColorByIndexOrValue = makeGetColorByIndexOrValue();
  const stroke = useSelector(state =>
    getColorByIndexOrValue(state, strokeFromProps)
  );

  const needShadow =
    stroke && Color(stroke).luminosity() > shadowLuminosityThreshold;

  function handleWidthChange(event) {
    const nextStrokeWidth = event.target.value;
    const delta = { strokeWidth: nextStrokeWidth };
    if (strokeFromProps === null && nextStrokeWidth !== 0) {
      delta.stroke = 0;
    }
    onChange(delta);
  }

  function handleColorChange(nextStroke) {
    const delta = { stroke: nextStroke };
    if (nextStroke !== null && !strokeWidth) {
      delta.strokeWidth = 1;
    }
    if (nextStroke === null) {
      delta.strokeWidth = 0;
    }
    onChange(delta);
  }

  const hasStroke = stroke !== null && strokeWidth > 0;

  const previewStrokeWidth = Math.max(1, strokeWidth * previewStrokeWidthScale);

  return (
    <>
      <DropdownWithTooltip
        {...rest}
        show={isOpen}
        tooltip={t('editor.toolbar.strokeColor')}
        className="stroke-input stroke-color-toggle qa-stroke-color-input"
        onToggle={nextIsOpen => setIsOpen(isColorPickerOpen || nextIsOpen)}
      >
        <Dropdown.Toggle>
          {hasStroke ? (
            <span className="preview">
              <svg
                className={`preview ${needShadow ? 'highlight-shadow' : null}`}
                width={previewRectSize}
                height={previewRectSize}
              >
                <rect
                  x={1}
                  y={1}
                  width={previewRectSize - 2}
                  height={previewRectSize - 2}
                  stroke={stroke}
                  fill="none"
                  strokeWidth={previewStrokeWidth}
                />
              </svg>
            </span>
          ) : (
            <span className="preview background-none qa-stroke-disabled" />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="color-picker">
          <SwatchColorPicker
            allowCustom
            color={strokeFromProps}
            onChange={handleColorChange}
            onOpen={() => setIsColorPickerOpen(true)}
            onClose={() => setIsColorPickerOpen(false)}
          />
        </Dropdown.Menu>
      </DropdownWithTooltip>

      <DropdownWithTooltip
        {...rest}
        className="stroke-width-input qa-stroke-width-input"
        tooltip={t('editor.toolbar.strokeWidth')}
      >
        <Dropdown.Toggle tabIndex={-1}>
          <span className="stroke-width">{strokeWidth}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form.Control
            type="range"
            onChange={handleWidthChange}
            min="0"
            max="100"
            value={strokeWidth}
            step={strokeStep}
          />
        </Dropdown.Menu>
      </DropdownWithTooltip>
    </>
  );
}

StrokeInput.defaultProps = {
  stroke: null,
  strokeWidth: 0,
};

StrokeInput.propTypes = {
  onChange: func.isRequired,
  stroke: oneOfType([string, number]),
  strokeWidth: oneOfType([string, number]),
};

export default withInputProps(StrokeInput);
