import get from 'lodash/get';
import some from 'lodash/some';

import { resolutions } from '../constants';

const makeWidthGetter = image => key => get(image, `${key}.width`);

export const imageFrameWidth = 100;
export const imageFrameHeight = 100;

export const millimetersPerInch = 25.4;

export const hasSizeMeta = image => {
  const hasWidthAtKey = makeWidthGetter(image);

  // Dimensions (`width` and `height`) can be stored under two different keys: `meta` or `details`
  return some(['meta', 'details'], hasWidthAtKey);
};

/**
 * Returns the width and height of an image fit into a given frame.
 * The frame size defaults to 100 x 100. Dimensions can live in either
 * .meta or .details /shrug.
 */
export const computeNativeImageSize = (imageObject = {}) => {
  if (!hasSizeMeta(imageObject)) {
    return { width: imageFrameWidth, height: imageFrameHeight };
  }

  const {
    details: { width, height },
  } = imageObject;

  const scale = Math.min(imageFrameWidth / width, imageFrameHeight / height);
  return { width: width * scale, height: height * scale };
};

/**
 * Resolves a given image to the src URL for the requested resolution and its
 * filename.
 * For SVG images, the filename is extracted from the original path on the
 * server and the resolution ignored.
 */
export const resolveImage = (imageObject, resolution = resolutions.small) => {
  let src;
  let filename;
  if (typeof imageObject.blob === 'string') {
    src = imageObject.blob;
    filename = src.split('/').pop();

    return { src, filename };
  }
  src = imageObject.blob[resolution];
  filename = imageObject.blob.filename;
  return { src, filename };
};

/**
 * Compute an images data url with `FileReader`.
 * @param {*} file File object
 */
export async function getDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => {
      resolve(result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Fetch an images width and height by creating an `img`
 * node and setting its source to the data url.
 * @param {} dataUrl a valid image data url
 */
export async function computeImageDimensions(dataUrl) {
  return new Promise((resolve, reject) => {
    const imgNode = document.createElement('img');
    imgNode.onload = () => {
      const width = imgNode.naturalWidth || imgNode.width;
      const height = imgNode.naturalHeight || imgNode.height;

      resolve({ width, height });
    };
    imgNode.onerror = reject;
    imgNode.src = dataUrl;
  });
}

/**
 * Receives the current pixel size to determine proper
 * SVG image resolution.
 */
export function computeResolution(pixelSize) {
  const sizes = [
    [resolutions.small, 100],
    [resolutions.medium, 500],
    [resolutions.large, 1000],
    // The full resolution is used as a fallback value, so not needed here:
    // [resolutions.full, 6000],
  ];
  const maxSize = [resolutions.full];
  const [resolution] = sizes.find(([, size]) => size > pixelSize) || maxSize;
  return resolution;
}

/**
 * Receives the DPI of an image in an unscaled image frame (= 10x10 cm)
 */
export function getImageDpi(imageObject) {
  const { width = 0, height = 0 } = imageObject?.details || {};
  const pixelPerMillimeter = Math.max(
    width / imageFrameWidth,
    height / imageFrameHeight
  );
  return pixelPerMillimeter * millimetersPerInch;
}

/**
 * Checks if an image resolution is suited for printing.
 * Returns `true` if the resolution is too low.
 */
const fullResolution = 6000;
const warningThresholdDpi = 75;

export function checkLowImageResolution(imageObject, scale) {
  const { width = 0, height = 0 } = imageObject?.details || {};
  const overFullResolution = Math.max(width, height) >= fullResolution;
  if (overFullResolution) {
    return false;
  }

  // The DPI relate to an unscaled 10x10 cm image
  const dpi = getImageDpi(imageObject);
  // The effective DPI takes scaling into account
  const effectiveDpi = dpi / scale;
  return effectiveDpi < warningThresholdDpi;
}

/**
 * Resize an image to a maximum dimension while maintaining aspect ratio
 * @param {string} dataUrl The original image data URL
 * @param {number} maxDimension Maximum width or height
 * @returns {Promise<string>} Resized image data URL
 */
export async function resizeImage(dataUrl, maxDimension = 800) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let { width, height } = img;

      // Calculate new dimensions maintaining aspect ratio
      if (width > height && width > maxDimension) {
        height = Math.round((height * maxDimension) / width);
        width = maxDimension;
      } else if (height > maxDimension) {
        width = Math.round((width * maxDimension) / height);
        height = maxDimension;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL('image/jpeg', 0.8));
    };
    img.onerror = reject;
    img.src = dataUrl;
  });
}

/**
 * Preloads an image or multiple images by creating Image objects and waiting for them to load
 * @param {string|string[]} urls A single URL or array of URLs to preload
 * @returns {Promise<void>} Promise that resolves when all images are loaded
 */
export function preloadImages(urls) {
  if (!urls) {
    return Promise.resolve();
  }

  const urlArray = Array.isArray(urls) ? urls : [urls];
  if (urlArray.length === 0) {
    return Promise.resolve();
  }

  const promises = urlArray.map(url => {
    if (!url) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => resolve(); // Resolve even on error to avoid blocking
      img.src = url;
    });
  });

  return Promise.all(promises);
}
