import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToQueue, removeFromQueue } from '../modules/loading';
import useFirstTime from './useFirstTime';

export const commonTypes = {
  uploadingFiles: 'uploadingFiles',
  fetchingAlbum: 'fetchingAlbum',
  fetchingBlueprints: 'fetchingBlueprints',
  fetchingPexel: 'fetchingPexel',
  fetchingOnboarding: 'fetchingOnboarding',
  patchTitleReleaseDate: 'patchTitleReleaseDate',
};

/**
 * A super thin wrapper around the `loading` store to start/stop
 * and get current loading state of a specific loading key
 * (e. g. `uploadingFiles` = currently uploading images).
 *
 * @param {string} jobId
 * @param {boolean} initialValue
 */
function useLoading(initialJobId = null, initialValue = false) {
  const dispatch = useDispatch();
  const queue = useSelector(state => state.loading);

  const startLoading = useCallback(
    (jobId = initialJobId) => dispatch(addToQueue(jobId)),
    [initialJobId, dispatch]
  );

  const stopLoading = useCallback(
    (jobId = initialJobId) => dispatch(removeFromQueue(jobId)),
    [initialJobId, dispatch]
  );

  const loadWhile = useCallback(
    async (callback, jobId = initialJobId) => {
      startLoading(jobId);
      await callback();
      stopLoading(jobId);
    },
    [startLoading, stopLoading, initialJobId]
  );

  useFirstTime(startLoading, initialValue);

  const isLoading = !!queue.find(({ id }) => id === initialJobId);

  const isLoadingJobId = useCallback(
    (jobId = initialJobId) => !!queue.find(({ id }) => id === jobId),
    [queue, initialJobId]
  );

  return {
    startLoading,
    stopLoading,
    isLoading,
    loadWhile,
    isLoadingJobId,
  };
}

export default useLoading;
