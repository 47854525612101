import React from 'react';
import { useSelector } from 'react-redux';

import { getSingleSelectedElement } from '../../../selectors/legacy';
import Icon from '../../Icon';
import { ToolbarNotificationShape } from '../../shapes';
import useLocale from '../../../hooks/localization/useLocale';

function ToolbarNotification({ position }) {
  const { t } = useLocale();
  const { left, top } = position;
  const selectedElement = useSelector(getSingleSelectedElement);
  const isSymbolText =
    selectedElement?.type === 'Text' && selectedElement.props?.symbol;

  const notificationText = t('editor.toolbar.symbolNote');

  if (!isSymbolText) {
    return null;
  }

  return (
    <div
      className="toolbar-notification d-flex align-items-center position-fixed shadow rounded bg-secondary qa-toolbar-notification p-2"
      style={{ left, top }}
    >
      <Icon name="exclamation_triangle" className="text-white" />
      <span className="pl-3 text-white">{notificationText}</span>
    </div>
  );
}

ToolbarNotification.propTypes = {
  position: ToolbarNotificationShape,
};

ToolbarNotification.defaultProps = {
  position: { left: 0, top: 'auto' },
};

export default ToolbarNotification;
