import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

import { selectPendingStickerUploads } from '../../../../selectors/stickerUploads';
import { getStickerPositionFromFace } from '../../../../util/faceapi';
import { computeNativeImageSize } from '../../../../util/images';
import PendingStickerUploadsModal from './PendingStickerUploadsModal';
import useLocale from '../../../../hooks/localization/useLocale';

function transformPendingStickerUpload(stickerUpload, index) {
  const {
    id,
    name,
    last_name: lastName,
    position,
    image,
    section_id: sectionId,
  } = stickerUpload;

  const {
    details: { face },
  } = image;

  const size = computeNativeImageSize(image);

  return {
    id,
    name: `${name}${lastName ? ` ${lastName}` : ''}`,
    image: image.id,
    position,
    number: index + 1,
    doubleSticker: false,
    sectionId,
    stickerUpload,
    ...getStickerPositionFromFace(face, size),
  };
}

function StickerUploadsAlert() {
  const pendingStickerUploads = useSelector(selectPendingStickerUploads);
  const [modalStickers, setModalStickers] = useState(null);
  const { t } = useLocale();

  if (pendingStickerUploads.length === 0) {
    return null;
  }

  function handleClick() {
    setModalStickers(pendingStickerUploads.map(transformPendingStickerUpload));
  }

  function handleClose() {
    setModalStickers(null);
  }

  return (
    <>
      <Card bg="light" text="dark" className="mb-3 qa-new-sticker-uploads">
        <Card.Header>{t('editor.sidebar.stickers.newStickers')}</Card.Header>
        <Card.Body>
          <Alert>
            <div
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'editor.sidebar.stickers.newStickersNotification',
                  {
                    count: pendingStickerUploads.length,
                  }
                )} 
            🚀`,
              }}
            />
            <Button
              block
              className="mt-2 qa-open-sticker-uploads-modal-btn"
              onClick={handleClick}
            >
              {t('editor.sidebar.stickers.collectStickers')}{' '}
              <Badge variant="light">{pendingStickerUploads.length}</Badge>
            </Button>
          </Alert>
        </Card.Body>
      </Card>
      {!!modalStickers && (
        <PendingStickerUploadsModal
          initialStickers={modalStickers}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default StickerUploadsAlert;
