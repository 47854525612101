import React, { memo } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import tools from './tools';
import ToolCreateButton from './ToolCreateButton';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';

function ToolMenu() {
  return (
    <FadeInWhenAlbumLoaded>
      <div className="workspace-tool-menu">
        <ButtonGroup vertical className="rounded shadow qa-tool-menu">
          {tools.map(tool => (
            <ToolCreateButton key={tool.id} tool={tool} />
          ))}
        </ButtonGroup>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

export default memo(ToolMenu);
