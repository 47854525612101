import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';

import useLocale from '../../../../hooks/localization/useLocale';
import TextSizeInput from './TextSizeInput';
import { fonts, fontSwatches } from '../../../../constants';
import { getFonts } from '../../../../selectors/colorsAndFonts';
import DropdownWithTooltip from '../DropdownWithTooltip';
import TextStyleInput from './TextStyleInput';
import withTextInputProps from './withTextInputProps';
import { getTextEditActive } from '../../../../selectors/selection';

function TextFontAndStyleInput(props) {
  const { t } = useLocale();
  const { font, makeHandleChange, dropDirection, ...rest } = props;
  const albumFonts = useSelector(getFonts);
  const textEditActive = useSelector(getTextEditActive);
  const showStyleInputsDirectly = textEditActive;
  const isAdditionalFont = !fontSwatches[font];
  const [showAdditionalFonts, setShowAdditionalFonts] = useState(
    isAdditionalFont
  );

  return (
    <>
      {showStyleInputsDirectly && <div className="seperator" />}
      <DropdownWithTooltip
        {...rest}
        dropDirection={dropDirection}
        className="font-input panel-container qa-font-input"
        tooltip={t('editor.toolbar.fontFamily')}
      >
        <Dropdown.Toggle
          style={{
            fontFamily: albumFonts[fontSwatches[font]?.symbol],
          }}
        >
          {fontSwatches[font] ? t(fontSwatches[font].label) : font}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="basic-fonts">
            <div className="panel">
              {Object.keys(fontSwatches).map(someFont => (
                <Dropdown.Item
                  key={someFont}
                  active={font === someFont}
                  onClick={makeHandleChange('font', someFont)}
                  style={{
                    fontFamily: albumFonts[fontSwatches[someFont]?.symbol],
                  }}
                >
                  {t(fontSwatches[someFont].label)}
                </Dropdown.Item>
              ))}
              <Button
                className="dropdown-item toggle-additional-fonts"
                onClick={() => setShowAdditionalFonts(!showAdditionalFonts)}
                variant="link"
              >
                {showAdditionalFonts
                  ? t('editor.toolbar.showLess')
                  : t('editor.toolbar.showMore')}
              </Button>
            </div>
            {!showStyleInputsDirectly && (
              <ButtonGroup className="panel">
                <TextStyleInput {...props} />
              </ButtonGroup>
            )}
          </div>
          {showAdditionalFonts && (
            <div>
              <div className="panel scrollable additional-fonts">
                {fonts.map(someFont => (
                  <Dropdown.Item
                    key={someFont}
                    active={font === someFont}
                    onClick={makeHandleChange('font', someFont)}
                    style={{ fontFamily: someFont }}
                  >
                    {someFont}
                  </Dropdown.Item>
                ))}
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </DropdownWithTooltip>
      <TextSizeInput {...props} />
      {showStyleInputsDirectly && (
        <>
          <div className="seperator" />
          <TextStyleInput {...props} />
        </>
      )}
    </>
  );
}

TextFontAndStyleInput.defaultProps = {
  font: 'Title',
};

TextFontAndStyleInput.propTypes = {
  makeHandleChange: func.isRequired,
  font: string,
  dropDirection: string.isRequired,
};

export default withTextInputProps(TextFontAndStyleInput);
