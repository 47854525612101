import React, { useState, useRef, useEffect } from 'react';
import { func, shape, string, number, arrayOf } from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import { Preview } from 'react-dnd-preview';

import useLocale from '../../../../hooks/localization/useLocale';
import { AREAS } from './constants';
import { generatePreview } from './ImageThumbnail';
import DropArea from './DropArea';
import { generateId } from '../../../../util';

function ImageUploadStep({
  onImagesUpload,
  uploadedImages = [],
  selectedLayout = null,
}) {
  const { t } = useLocale();
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);
  const [images, setImages] = useState({
    [AREAS.STICKERS]: uploadedImages || [],
    [AREAS.BACKGROUNDS]: [],
  });

  const totalImages =
    images[AREAS.STICKERS].length + images[AREAS.BACKGROUNDS].length;

  const stickerSlots = selectedLayout?.stickerSlots || 1;
  const backgroundSlots = selectedLayout?.backgroundSlots || 0;
  const requiredImages = stickerSlots + backgroundSlots;

  useEffect(() => {
    /**
     * Here we're making sure that we're not leaking memory
     * by revoking the object URLs of the images. Sometimes users
     * might upload a lot of images and then close the modal...
     */
    return () => {
      [...images[AREAS.STICKERS], ...images[AREAS.BACKGROUNDS]].forEach(img => {
        if (img.preview?.startsWith('blob:')) {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateImages = newImages => {
    const allImages = [
      ...newImages[AREAS.STICKERS],
      ...newImages[AREAS.BACKGROUNDS],
    ];
    onImagesUpload(allImages);
    setImages(newImages);
  };

  const handleFileChange = e => {
    const files = Array.from(e.target.files);
    if (!files.length) {
      return;
    }

    const invalidFiles = files.filter(
      file => !file.type.match(/^image\/(jpeg|jpg|png|gif)$/i)
    );

    if (invalidFiles.length) {
      setError(t('editor.spreadCreationModal.invalidFileType'));
      return;
    }

    const newImages = files.map(file => ({
      id: generateId(),
      file,
      preview: URL.createObjectURL(file),
      name: file.name,
      category: 'stickers', // Default category for new uploads
    }));

    updateImages({
      ...images,
      [AREAS.STICKERS]: [...images[AREAS.STICKERS], ...newImages],
    });
    setError(null);
  };

  const handleMoveImage = (imageId, sourceArea, targetArea) => {
    if (sourceArea === targetArea) {
      return;
    }

    const imageToMove = images[sourceArea].find(img => img.id === imageId);
    if (!imageToMove) {
      return;
    }

    const imageCopy = {
      ...imageToMove,
      category: targetArea === AREAS.STICKERS ? 'stickers' : 'backgrounds',
    };

    const newImages = {
      ...images,
      [sourceArea]: images[sourceArea].filter(img => img.id !== imageId),
      [targetArea]: [...images[targetArea], imageCopy],
    };

    updateImages(newImages);
  };

  const handleAddMore = () => fileInputRef.current.click();

  return (
    <div className="image-upload-step qa-image-upload-step">
      <Preview>{generatePreview}</Preview>

      <p>
        <small>
          {totalImages > 0
            ? t('editor.spreadCreationModal.dragAndDropInstructions', {
                stickerSlots,
                backgroundSlots,
              })
            : t('editor.spreadCreationModal.uploadInstructions')}
        </small>
      </p>

      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          dismissible
          className="mb-4"
        >
          {error}
        </Alert>
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="d-none qa-image-upload-input"
        name="qa-image-upload-input"
        accept="image/jpeg,image/png,image/gif"
        multiple={requiredImages > 1}
        max={requiredImages}
      />

      {totalImages === 0 ? (
        <button
          type="button"
          className="w-100 btn border border-2 border-dashed rounded-3 d-flex flex-column justify-content-center align-items-center text-secondary bg-light cursor-pointer image-upload-empty-container qa-image-upload-button"
          onClick={handleAddMore}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mb-3"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="17 8 12 3 7 8" />
            <line x1="12" y1="3" x2="12" y2="15" />
          </svg>
          <span>{t('editor.spreadCreationModal.dragDropHint')}</span>
        </button>
      ) : (
        <div className="d-flex flex-column gap-5 mb-3">
          <DropArea
            areaId={AREAS.STICKERS}
            title={t('editor.spreadCreationModal.stickers')}
            description={t('editor.spreadCreationModal.stickersDescription')}
            images={images[AREAS.STICKERS]}
            totalSlots={stickerSlots}
            onAddMore={handleAddMore}
            handleMoveImage={handleMoveImage}
            className="qa-stickers-drop-area"
          />
          <DropArea
            areaId={AREAS.BACKGROUNDS}
            title={t('editor.spreadCreationModal.backgroundImages')}
            description={t(
              'editor.spreadCreationModal.backgroundImagesDescription'
            )}
            images={images[AREAS.BACKGROUNDS]}
            totalSlots={backgroundSlots}
            onAddMore={handleAddMore}
            handleMoveImage={handleMoveImage}
            className="qa-backgrounds-drop-area"
          />
        </div>
      )}
    </div>
  );
}

ImageUploadStep.propTypes = {
  onImagesUpload: func.isRequired,
  selectedLayout: shape({
    id: string.isRequired,
    name: string,
    stickerSlots: number,
    backgroundSlots: number,
  }),
  uploadedImages: arrayOf(
    shape({
      id: string.isRequired,
      preview: string.isRequired,
      name: string.isRequired,
      file: shape({}).isRequired,
    })
  ),
};

ImageUploadStep.defaultProps = {
  selectedLayout: null,
  uploadedImages: [],
};

export default ImageUploadStep;
