import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from 'react-redux';
import qs from 'query-string';

import Icon from '../Icon';
import { renderModes, renderFormats, resolutions } from '../../constants';
import presets from '../../exportPresets';
import { getTargetSpreadIndex } from '../../selectors/legacy';
import { updateControls } from '../../modules/controls';
import ExportPreflight from './ExportPreflight';
import useApi from '../../hooks/useApi';
import ButtonWithTooltip from '../menu/ButtonWithTooltip';

const CreateJobClass = ({
  changed,
  currentAlbum,
  jobSettings,
  onJobCreate,
  saving,
  targetSpreadIndex,
  updateControls, // eslint-disable-line no-shadow
}) => {
  const api = useApi();
  const [renderUrl, setRenderUrl] = useState('');
  const renderUrlRef = useRef();

  function handleCopy() {
    renderUrlRef.current.select();
    navigator.clipboard.writeText(renderUrlRef.current.value);
  }

  const { custom, mode, preset } = jobSettings;
  let { range } = jobSettings;

  if (preset === 'current_spread') {
    range = String(targetSpreadIndex + 1);
  }

  const startJob = () => {
    const params = {
      ...jobSettings,
      album_id: currentAlbum,
      range: String(range).replace(' ', ''),
      dpi: String(jobSettings.dpi).replace(' ', ''),
    };
    ['label', 'preset', 'custom'].forEach(key => delete params[key]);

    const data = {
      render_job: {
        params,
      },
    };

    api
      .post('/exports/render_jobs', data)
      .then(
        ({
          data: {
            render_job: renderJob,
            meta: { token },
          },
        }) => {
          const url = `${window.location.origin}/render?${qs.stringify({
            ...params,
            token,
          })}`;

          setRenderUrl(url);
          // eslint-disable-next-line no-console
          console.log(url);
          onJobCreate(renderJob);
        }
      )
      .catch(e => {
        window.alert(e.toString());
      });
  };

  const start = () => {
    startJob();
  };

  const setValue = (key, value) => {
    // Need to reset about-to-be stale render URL
    setRenderUrl('');

    let { showStickers } = jobSettings;
    if (key === 'mode' && value === 'stickers') {
      showStickers = true;
    }
    updateControls({
      jobSettings: { ...jobSettings, showStickers, [key]: value },
    });
  };

  const setPreset = key => {
    // Need to reset about-to-be stale render URL
    setRenderUrl('');

    const newJobSettings = { ...jobSettings, ...presets[key], preset: key };
    updateControls({ jobSettings: newJobSettings });
  };

  let rangeLabel;
  if (mode === 'spread') {
    rangeLabel = 'Doppelseiten';
  } else if (mode === 'stickers') {
    rangeLabel = 'Sticker';
  } else {
    rangeLabel = 'Seiten';
  }

  const startLabel = changed ? 'Speichern & Export starten' : 'Export starten';

  return (
    <Form className="create-job">
      <Form.Group>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Form.Label>Voreinstellung</Form.Label>
        <select
          className="qa-create-job-dd create-job-select"
          value={jobSettings.preset}
          onChange={e => setPreset(e.target.value)}
          name="create-job-select"
        >
          {Object.keys(presets).map(key => (
            <option key={key} value={key}>
              {presets[key].label}
            </option>
          ))}
        </select>
      </Form.Group>
      {custom && (
        <>
          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Label>Mode</Form.Label>
            <select
              className="qa-create-job-form--mode-dd create-job-select"
              value={jobSettings.mode}
              onChange={e => setValue('mode', e.target.value)}
              name="create-job-form-mode"
            >
              {Object.keys(renderModes).map(key => (
                <option key={key} value={key}>
                  {renderModes[key]}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Label>Format</Form.Label>
            <select
              className="qa-create-job-form--format-dd create-job-select"
              value={jobSettings.format}
              onChange={e => setValue('format', e.target.value)}
              name="create-job-format-select"
            >
              {Object.keys(renderFormats).map(key => (
                <option key={key} value={key}>
                  {renderFormats[key]}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Label>Auflösung</Form.Label>
            <select
              className="qa-create-job-form--resolution-dd create-job-select"
              value={jobSettings.resolution}
              onChange={e => setValue('resolution', e.target.value)}
              name="create-job-form-resolution"
            >
              {Object.keys(resolutions).map(key => (
                <option key={key} value={key}>
                  {resolutions[key]}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group className="w-50">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Label htmlFor="create-job-form[dpi]">DPI</Form.Label>
            <Form.Control
              id="create-job-form[dpi]"
              onChange={e => setValue('dpi', e.target.value)}
              value={jobSettings.dpi}
              type="text"
              size="sm"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              id="create-job-form[preview-sticker]"
              label="Sticker anzeigen"
              checked={jobSettings.showStickers}
              onChange={e => setValue('showStickers', e.target.checked)}
            />
          </Form.Group>

          <Form.Group disabled={mode !== 'album_softcover_inside'}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Check
              id="create-job-form[include-cover]"
              label="Cover einschliessen"
              checked={jobSettings.includeCover}
              onChange={e => setValue('includeCover', e.target.checked)}
            />
          </Form.Group>

          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Check
              id="create-job-form[include-bleed]"
              label="Beschnittzugabe + Schnittmarken"
              checked={jobSettings.includeBleed}
              onChange={e => setValue('includeBleed', e.target.checked)}
            />
          </Form.Group>

          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Form.Check
              id="create-job-form[compress-pdf]"
              label="PDF komprimieren"
              checked={jobSettings.compress}
              onChange={e => setValue('compress', e.target.checked)}
            />
          </Form.Group>
        </>
      )}
      <Form.Group className="w-50">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Form.Label htmlFor="create-job-form[range]">
          {rangeLabel} (z.B. 1-3,5)
        </Form.Label>
        <Form.Control
          id="create-job-form[range]"
          onChange={e => setValue('range', e.target.value)}
          value={range}
          type="text"
          size="sm"
        />
      </Form.Group>

      {mode !== 'stickers' && (
        <Form.Group inline>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <Form.Label>Preflight</Form.Label>
          <ExportPreflight />
        </Form.Group>
      )}

      <Form.Label>Render-URL</Form.Label>
      <InputGroup className="pb-3 pr-3">
        <Form.Control
          className="mr-1"
          value={renderUrl}
          type="text"
          size="sm"
          ref={renderUrlRef}
          readOnly
        />
        <ButtonWithTooltip
          icon="clipboard"
          tooltip="URL kopieren"
          onClick={handleCopy}
          variant="dark"
        />
      </InputGroup>

      <Form.Group>
        <Button
          className="qa-start-job-btn d-flex align-items-center"
          disabled={saving}
          variant="success"
          onClick={start}
        >
          <Icon name="play" /> {startLabel}
        </Button>
      </Form.Group>
    </Form>
  );
};

CreateJobClass.defaultProps = {
  currentAlbum: null,
};

CreateJobClass.propTypes = {
  changed: PropTypes.bool.isRequired,
  currentAlbum: PropTypes.string,
  jobSettings: PropTypes.object.isRequired, // eslint-disable-line
  onJobCreate: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  targetSpreadIndex: PropTypes.number.isRequired,
  updateControls: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  changed: state.history.changed,
  currentAlbum: state.albums.currentAlbum,
  jobSettings: state.controls.jobSettings,
  saving: state.albums.saving,
  targetSpreadIndex: getTargetSpreadIndex(state),
});

const mapDispatchToProps = {
  updateControls,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobClass);
