import React from 'react';
import { func, number, oneOfType, string } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import withInputProps from './withInputProps';
import DropdownWithTooltip from '../DropdownWithTooltip';
import useLocale from '../../../../hooks/localization/useLocale';

function OpacityInput({ onChange, opacity: opacityFromProps, ...rest }) {
  const { t } = useLocale();

  function handleChange(event) {
    const nextOpacity = event.target.value / 100;
    onChange({ opacity: nextOpacity });
  }

  const opacity = parseFloat(opacityFromProps);

  return (
    <DropdownWithTooltip
      {...rest}
      className="opacity-input qa-opacity-input"
      tooltip={t('editor.toolbar.opacity')}
    >
      <Dropdown.Toggle>
        <span className="opacity-value">{`${Math.round(opacity * 100)}%`}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Form.Control
          type="range"
          onChange={handleChange}
          min="0"
          max="100"
          value={opacity * 100}
          step="1"
        />
      </Dropdown.Menu>
    </DropdownWithTooltip>
  );
}

OpacityInput.defaultProps = {
  opacity: 1,
};

OpacityInput.propTypes = {
  onChange: func.isRequired,
  opacity: oneOfType([string, number]),
};

export default withInputProps(OpacityInput);
