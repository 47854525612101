import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';
import { arrayOf, func } from 'prop-types';

import StickerUploadsModal from '../../StickerUploadsModal/StickerUploadsModal';
import { createSticker } from '../../../../actions/stickers';
import {
  deleteStickerUploads,
  setMergedAt,
} from '../../../../actions/stickerUploads';
import useApi from '../../../../hooks/useApi';
import { StickerFormProvider } from '../StickerForm/useStickerForm';
import { filterValidItems } from '../../../generic/FormValidationErrors';
import { selectCurrentAlbum } from '../../../../selectors/albums';
import { validStickerKeys } from '../../../../constants';
import { StickerShape } from '../../../shapes';
import stickerFormRules from '../StickerForm/stickerFormRules';
import useAnalytics from '../../../../containers/app/useAnalytics';
import { generateId } from '../../../../util';

function PendingStickerUploadsModal({ initialStickers, onClose }) {
  const dispatch = useDispatch();

  const [stickers, setStickers] = useState(initialStickers);
  const updateSticker = nextSticker =>
    setStickers(
      stickers.map(sticker =>
        nextSticker.id === sticker.id ? nextSticker : sticker
      )
    );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentAlbumId = useSelector(selectCurrentAlbum);

  const api = useApi();
  const analytics = useAnalytics();

  /**
   * Create a sticker from each stickerUpload and set the
   * `merged_at` attribute server-side
   */
  function handleSubmit() {
    setIsSubmitting(true);

    const validStickers = filterValidItems(stickerFormRules, stickers);
    const stickerUploadIds = validStickers.map(
      ({ stickerUpload }) => stickerUpload.id
    );

    validStickers.forEach(sticker => {
      dispatch(
        createSticker(
          pick(
            {
              ...sticker,
              id: generateId(),
            },
            validStickerKeys
          )
        )
      );

      analytics.track('Sticker Created', {
        id: sticker.id,
        fromMerge: true,
        drop: false,
        albumId: currentAlbumId,
      });
    });

    api
      .patch(`/albums/${currentAlbumId}/sticker_uploads/merge`, {
        sticker_upload_ids: stickerUploadIds,
      })
      .then(({ data: { merged_at: mergedAt } }) => {
        dispatch(setMergedAt(stickerUploadIds, mergedAt));
        setIsSubmitting(false);
      });

    onClose();
  }

  async function handleDelete({ id: selectedId }) {
    setIsSubmitting(true);
    const { stickerUpload } = stickers.find(
      sticker => sticker.id === selectedId
    );
    const { id: stickerUploadId } = stickerUpload;
    await api.delete(`/sticker_uploads/${stickerUploadId}`);
    dispatch(deleteStickerUploads([stickerUploadId]));
    setStickers(stickers.filter(sticker => sticker.id !== selectedId));
    setIsSubmitting(false);
  }

  return (
    <StickerFormProvider>
      <StickerUploadsModal
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onClose={onClose}
        disabled={isSubmitting}
        stickers={stickers}
        updateSticker={updateSticker}
      />
    </StickerFormProvider>
  );
}

PendingStickerUploadsModal.propTypes = {
  onClose: func.isRequired,
  initialStickers: arrayOf(StickerShape).isRequired,
};

export default PendingStickerUploadsModal;
