import React from 'react';
import { node } from 'prop-types';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HelmetProvider } from 'react-helmet-async';

import NodeProvider from './components/svg/NodeProvider';
import Node from './components/svg/Node';
import AuthProvider from './AuthProvider';
import App from './containers/app';
import store from './store';
import CookieConsent from './components/ui/CookieConsent';
import MetaTags from './components/MetaTags';
import WebFontsLoader from './WebFontsLoader';
import { isTouchDevice } from './util/isTouchDevice';
import './sass/_.scss';

const target = document.querySelector('#root');

// Configure the backend based on device type
const backendOptions = isTouchDevice()
  ? {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
    }
  : { backend: HTML5Backend };

function MaybeWrapWithAuthContext({ children }) {
  /**
   * Auth0 client will throw an exception when running in an "insecure" location (e. g. `http://client`);
   * we skip it for the render-route (authentication will be handled by different token)
   * to not break our development export flow.
   */
  const isRender = window.location.pathname.indexOf('render') > -1;

  return isRender ? children : <AuthProvider>{children}</AuthProvider>;
}

MaybeWrapWithAuthContext.propTypes = {
  children: node.isRequired,
};

render(
  <div id="app">
    <HelmetProvider>
      <NodeProvider value={Node}>
        <Provider store={store}>
          <WebFontsLoader />
          <CookieConsent />
          <BrowserRouter>
            <DndProvider {...backendOptions}>
              <MaybeWrapWithAuthContext>
                <MetaTags />
                <App />
              </MaybeWrapWithAuthContext>
            </DndProvider>
          </BrowserRouter>
        </Provider>
      </NodeProvider>
    </HelmetProvider>
  </div>,
  target
);
