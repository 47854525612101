import React from 'react';
import { number, func } from 'prop-types';
import Button from 'react-bootstrap/Button';

import { useFormState } from './FormStateProvider';
import steps from './steps';
import { configShape } from './shapes';
import useLocale from '../../hooks/localization/useLocale';

function Navigation({ step, setStep, config }) {
  const { t } = useLocale();
  const formState = useFormState();

  const { nextDisabled, preventNavigation, renderFooter } =
    steps[step].config?.(formState, config) || {};

  if (preventNavigation) {
    return null;
  }

  return (
    <div className="footer">
      {renderFooter?.()}
      {steps[step - 1] && (
        <Button
          block
          className="qa-prev-btn"
          size="lg"
          variant="light"
          onClick={() => setStep(step - 1)}
        >
          {t('stickerUploadForm.backButton')}
        </Button>
      )}
      {steps[step + 1] && (
        <Button
          disabled={nextDisabled}
          block
          className="qa-next-btn"
          size="lg"
          onClick={() => setStep(step + 1)}
        >
          {t('stickerUploadForm.nextButton')}
        </Button>
      )}
      <div className="mt-2 d-flex justify-content-center">
        <a
          href="https://www.stickerstars.de/datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('stickerUploadForm.privacyLink')}
        </a>
        <a
          className="ml-3"
          href="https://www.stickerstars.de/impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('stickerUploadForm.imprintLink')}
        </a>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  config: configShape.isRequired,
  step: number.isRequired,
  setStep: func.isRequired,
};

export default Navigation;
