import React from 'react';
import { number, string, object, func, bool } from 'prop-types';
import { useDrag } from 'react-dnd';

import AlbumSvg from '../../svg/Viewport/AlbumSvg';
import { dimensions, itemTypes } from '../../../constants';
import { WorkspaceShape } from '../../shapes';
import { useNode } from '../../svg/NodeProvider';

function SpreadPreview({
  spreadId,
  sectionNode,
  spreadsCount,
  spreadIndex,
  workspace,
  onClick,
  draggable,
}) {
  const { pageHeight, pageWidth } = dimensions;
  const isCover = spreadIndex === 0 || spreadIndex === spreadsCount - 1;
  const viewBox = `0 0 ${isCover ? pageWidth : pageWidth * 2} ${pageHeight}`;
  const Node = useNode();

  const [, drag] = useDrag({
    type: itemTypes.spread,
    item: {
      data: {
        blueprintSpreadId: spreadId,
      },
    },
  });

  return (
    <div
      className="shadow spread-preview qa-spread-preview"
      onClick={onClick}
      style={{
        width: `${isCover ? '50%' : '100%'}`,
        cursor: draggable ? 'grab' : 'pointer',
      }}
      ref={draggable ? drag : null}
    >
      <AlbumSvg viewBox={viewBox} preview>
        <Node
          workspace={workspace}
          id={spreadId}
          nodeIndex={spreadIndex}
          nodeSiblingCount={spreadsCount}
          renderingSpreadPosition={{
            x: 0,
            y: 0,
          }}
          sectionNode={sectionNode}
          preview
        />
      </AlbumSvg>
    </div>
  );
}

SpreadPreview.defaultProps = {
  onClick: () => {},
  draggable: false,
};

SpreadPreview.propTypes = {
  spreadId: string.isRequired,
  spreadIndex: number.isRequired,
  workspace: WorkspaceShape.isRequired,
  spreadsCount: number.isRequired,
  sectionNode: object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: func,
  draggable: bool,
};

export default SpreadPreview;
