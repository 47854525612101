import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import Icon from '../../components/Icon';
import { legalPermalinks } from '../../constants';
import useScreenSize from '../../hooks/useScreenSize';
import useTutorial from '../../hooks/useTutorial';
import useHelpscoutBeacon from './useHelpscoutBeacon';
import useLocale from '../../hooks/localization/useLocale';

function TutorialButton() {
  const { t } = useLocale();
  const { beacon } = useHelpscoutBeacon();
  const { linkTo } = useTutorial();

  const { isMobile } = useScreenSize();

  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="light" className="mr-2 bg-white">
        <Icon className="d-inline-block" name="help" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item target="_BLANK" href={linkTo('start')}>
          {t('help')}
        </Dropdown.Item>
        {isMobile && (
          <Dropdown.Item onClick={() => beacon('open')}>
            {t('editor.meta.contact')}
          </Dropdown.Item>
        )}
        <Dropdown.Item target="_BLANK" href={legalPermalinks.agb}>
          {t('editor.meta.terms')}
        </Dropdown.Item>
        <Dropdown.Item target="_BLANK" href={legalPermalinks.info}>
          {t('editor.meta.customerInformation')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TutorialButton;
