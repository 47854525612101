import React from 'react';
import { arrayOf } from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import useScopedSidebarTabs from '../Sidebar/useScopedSidebarTabs';
import useLocale from '../../../hooks/localization/useLocale';

function BackgroundPanel({ tabs }) {
  const { t } = useLocale();
  const { tabs: scopedTabs } = useScopedSidebarTabs(tabs);

  return (
    <>
      <Tab.Container defaultActiveKey="images">
        <Nav variant="tabs">
          {scopedTabs.map(({ key, title }) => (
            <Nav.Item key={key}>
              <Nav.Link className={`qa-${key}-tab`} eventKey={key}>
                {t(title)}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content bsPrefix="tab-content d-flex flex-grow-1">
          {scopedTabs.map(({ key, title, component: Component, ...rest }) => (
            <Tab.Pane
              bsPrefix={`tab-pane flex-column qa-${key}-content`}
              eventKey={key}
              title={title}
              key={key}
            >
              <Component {...rest} />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

BackgroundPanel.propTypes = {
  tabs: arrayOf(Object).isRequired,
};

export default BackgroundPanel;
