import React from 'react';
import { bool, object, string } from 'prop-types';

import stickerTemplates from '../../../../../assets/sticker';
import StickerDesign from '../../../../svg/elements/Sticker/StickerDesign';
import AlbumSvg from '../../../../svg/Viewport/AlbumSvg';
import useLocale from '../../../../../hooks/localization/useLocale';
import { dimensions } from '../../../../../constants';

function StickerTemplateView({
  stickerTemplateId,
  stickerLayout,
  className,
  cell,
  doubleSticker,
  style,
}) {
  const { t } = useLocale();

  const singleOrDouble = doubleSticker ? 'double' : 'single';
  const workspace =
    stickerTemplates[stickerTemplateId]?.[singleOrDouble]?.[
      cell ? 'cell' : 'sticker'
    ]?.[stickerLayout];

  if (!workspace) {
    throw new Error(`Workspace not found: ${stickerTemplateId}`);
  }

  const width = dimensions.stickerWidth * (doubleSticker ? 2 : 1);
  const { height } = workspace.nodes[workspace.root].props;

  return (
    <div
      className={`sticker-template-view qa-sticker-template-${stickerTemplateId} ${className}`}
      style={style}
    >
      <AlbumSvg viewBox={`0 0 ${width} ${height}`} preview>
        {!cell && (
          <image
            width={width * 1.1}
            x={width * -0.05}
            xlinkHref="/assets/images/sticker-example.jpg"
            preserveAspectRatio="none"
          />
        )}
        <StickerDesign
          workspace={workspace}
          name={t('stickerTemplate.name')}
          position={t('stickerTemplate.position')}
          number={1}
          clipPath={`url(#clip-${doubleSticker ? 'double' : ''}sticker)`}
        />
      </AlbumSvg>
    </div>
  );
}

StickerTemplateView.defaultProps = {
  className: '',
  cell: false,
  stickerLayout: 'default',
  doubleSticker: false,
  style: {},
};

StickerTemplateView.propTypes = {
  stickerTemplateId: string.isRequired,
  stickerLayout: string,
  className: string,
  cell: bool,
  doubleSticker: bool,
  style: object, // eslint-disable-line react/forbid-prop-types
};

export default StickerTemplateView;
