import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router';

import useInvitationFromParams from './useInvitation';
import useApi from '../../hooks/useApi';
import Spinner from '../../components/generic/Spinner';
import useLocale from '../../hooks/localization/useLocale';
import useAnalytics from '../app/useAnalytics';

function ConfirmInvitation() {
  const { t } = useLocale();
  const { invitation, error } = useInvitationFromParams();
  const [confirmationError, setConfirmationError] = useState(null);
  const history = useHistory();

  const api = useApi();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!invitation) {
      return;
    }

    (async () => {
      try {
        await api.post(`/invitations/${invitation.id}/confirm`);
        analytics.track('Invitation Accepted', {
          albumId: invitation.album_id,
        });
        history.push(`/album/${invitation.album_id}`);
      } catch (err) {
        setConfirmationError(err);
      }
    })();
  }, [api, invitation, history, analytics]);

  return (
    <div className="w-100 vh-100 d-flex align-items-center justify-content-center bg-light">
      {error || confirmationError ? (
        <Alert variant="danger" className="my-4">
          {t('genericError')}
        </Alert>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

ConfirmInvitation.defaultProps = {};

ConfirmInvitation.propTypes = {};

export default ConfirmInvitation;
