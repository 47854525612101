import React from 'react';
import { useParams } from 'react-router';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import Logo from '../../assets/icons/logo.svg';
import useInvitationFromParams from './useInvitation';
import useLocale from '../../hooks/localization/useLocale';

function AcceptInvitation() {
  const { t } = useLocale();
  const { token } = useParams();
  const { invitation, error, isLoading } = useInvitationFromParams();

  if (isLoading) {
    return null;
  }

  return (
    <div className="w-100 vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="accept-invitation-form">
        <div className="bg-white rounded p-5">
          <img
            src={Logo}
            className="logo my-4"
            alt="stickerstars logo"
            width={100}
          />
          {error && (
            <Alert variant="danger" className="my-4">
              {t('invitations.error')}
            </Alert>
          )}
          {invitation && (
            <>
              <p
                className="my-1"
                dangerouslySetInnerHTML={{
                  __html: t('invitations.joinMessage', {
                    inviterEmail: invitation.inviter_email,
                    albumTitle: invitation.album_title,
                  }),
                }}
              />

              <Button
                variant="primary"
                className="my-4 qa-accept-invitation-button"
                href={`/invitations/${token}/confirm?email=${invitation.email}&hint=signup`}
              >
                {t('invitations.acceptInvitation')}
              </Button>
              <p>{t('invitations.accessInfo')}</p>
              <p>
                <a
                  href="https://www.stickerstars.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('invitations.moreInfo')}
                </a>
              </p>
            </>
          )}
        </div>
        <div className="text-center text-secondary my-4">
          <small>
            <a
              href="https://www.stickerstars.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('invitations.companyInfo')}
            </a>
          </small>
        </div>
      </div>
    </div>
  );
}

export default AcceptInvitation;
