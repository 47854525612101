export const albumRanges = {
  wedding: {
    min: 10,
    max: 200,
  },
  business: {
    min: 10,
    max: 500,
  },
  sports: {
    min: 10,
    max: 200,
  },
  birthday: {
    min: 10,
    max: 200,
  },
  school: {
    min: 10,
    max: 200,
  },
  community: {
    min: 10,
    max: 500,
  },
};

export const commentMaxLength = 1000;
