import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../Icon';
import { historyUndo, historyRedo } from '../../../../modules/history';
import { selectCanRedo, selectCanUndo } from '../../../../selectors/controls';
import useLocale from '../../../../hooks/localization/useLocale';

function HistoryControls() {
  const { t } = useLocale();
  const dispatch = useDispatch();

  /**
   * If the user is currently editing a text frame, we'll let
   * `draft` handle a local timeline for us and thus disable
   * the global history controls. A `historyAnchor` will be dispatched
   * as soon as the draft instance is blurred.
   */
  const canUndo = useSelector(selectCanUndo);
  const canRedo = useSelector(selectCanRedo);

  return (
    <div className="workspace-history qa-workspace-history">
      <ButtonGroup className="rounded shadow">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{t('undo')}</Tooltip>}
          rootClose
        >
          <Button
            variant="light"
            className="bg-white qa-undo-btn"
            disabled={!canUndo}
            onClick={() => dispatch(historyUndo())}
          >
            <Icon name="undo" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{t('redo')}</Tooltip>}
          rootClose
        >
          <Button
            variant="light"
            className="bg-white qa-redo-btn"
            disabled={!canRedo}
            onClick={() => dispatch(historyRedo())}
          >
            <Icon name="redo" />
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </div>
  );
}

export default HistoryControls;
