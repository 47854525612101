import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import useImageUpload from '../../../hooks/useImageUpload';
import ImagesPanel from '.';
import { createWarning } from '../../../actions/notifications';
import { maxImageUploads } from '../../../constants';
import useAnalytics from '../../../containers/app/useAnalytics';
import useLocale from '../../../hooks/localization/useLocale';
import { selectCurrentAlbum } from '../../../selectors/albums';

function ImagesPanelWithDnd(props) {
  const { t } = useLocale();
  const { createWorkspaceImage } = useImageUpload();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const albumId = useSelector(selectCurrentAlbum);

  // When dropping a file, we're uploading it.
  const handleDrop = useCallback(
    ({ monitor }) => {
      if (monitor.getItemType() === NativeTypes.FILE) {
        const { files } = monitor.getItem();
        if (files.length > maxImageUploads) {
          dispatch(
            createWarning(
              t('editor.imageUpload.maxImagesError', { max: maxImageUploads })
            )
          );
          return;
        }
        Array.from(files).forEach(file => {
          createWorkspaceImage(file);

          analytics.track('Image Uploaded', {
            drop: true,
            sidebar: true,
            albumId,
          });
        });
      }
    },
    [createWorkspaceImage, dispatch, analytics, t, albumId]
  );

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(_, monitor) {
      if (handleDrop) {
        handleDrop({ monitor });
      }
    },
    collect: el => ({
      isOver: el.isOver() && el.getItemType() === NativeTypes.FILE,
    }),
  });

  return <ImagesPanel {...props} dropRef={drop} isOver={isOver} />;
}

export default ImagesPanelWithDnd;
