import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';

import useApi from '../../hooks/useApi';
import useLoading, { commonTypes } from '../../hooks/useLoading';
import {
  receiveBlueprintData,
  receiveBlueprints,
  requestBlueprints,
  selectBlueprint,
  setBlueprintCategory,
} from '../../modules/blueprints';
import {
  getBlueprintData,
  getCurrentBlueprintData,
  selectCurrentBlueprintCategory,
} from '../../selectors/blueprints';
import useNotifications from '../../hooks/useNotifications';
import useAppEnvironment, {
  validEnvironments,
} from '../../hooks/useAppEnvironment';
import useLocale from '../../hooks/localization/useLocale';

function useWizardBlueprints({ fetchOnLoad } = {}) {
  const { t } = useLocale();
  const dispatch = useDispatch();

  const { get } = useApi();
  const { startLoading, stopLoading, isLoading } = useLoading(
    commonTypes.fetchingBlueprints
  );
  const { loadWhile } = useLoading('fetchingBlueprintData');
  const { createError } = useNotifications();

  const blueprints = useSelector(state => state.blueprints.blueprints);
  const currentBlueprintData = useSelector(getCurrentBlueprintData) || {};
  const blueprintsByBlueprintId = useSelector(getBlueprintData) || {};

  /**
   * Compute all available blueprint categories to
   * allow client-side filtering.
   */
  const categories = uniq(
    blueprints.map(blueprint => blueprint.blueprint_category).filter(Boolean)
  );

  /**
   * We're populating the `category` filter with the
   * `entrypoint` URL param here unless there is autosave data.
   * If no valid entrypoint was given, we're defaulting to `wedding`.
   */
  const { entrypoint } = useAppEnvironment();
  const [defaultEnvironment] = validEnvironments;
  const categoryFilter =
    useSelector(selectCurrentBlueprintCategory) ||
    entrypoint ||
    defaultEnvironment;

  const setCategoryFilter = category =>
    dispatch(setBlueprintCategory(category));

  /**
   * We need to fetch blueprints from the server only once: on initial
   * mount of the album wizard container.
   * In order to not re-fetch when mounting any other components that
   * uses the hook, we pass a `fetchOnLoad` property indicating
   * that a request should be made. Defaults to false.
   */
  useEffect(() => {
    const fetchBlueprints = async () => {
      try {
        dispatch(requestBlueprints());
        startLoading();
        const {
          data: { blueprints: blueprintsFromServer },
        } = await get('/blueprints');
        stopLoading();

        if (blueprintsFromServer.length > 0) {
          dispatch(receiveBlueprints(blueprintsFromServer));
        }
      } catch (err) {
        createError(t('genericError'));
      }
    };

    if (fetchOnLoad) {
      fetchBlueprints();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAndCache(id) {
    loadWhile(async () => {
      const {
        data: { blueprint },
      } = await get(`/blueprints/${id}`);
      dispatch(receiveBlueprintData(id, blueprint));
    });
  }

  const filteredBlueprints = useMemo(
    () =>
      blueprints.filter(
        blueprint => blueprint.blueprint_category === categoryFilter
      ),
    [blueprints, categoryFilter]
  );

  function handleCategoryChange(nextCategory) {
    if (blueprints.length === 0) {
      return;
    }

    const { id } = blueprints.find(
      ({ blueprint_category: blueprintCategory }) =>
        blueprintCategory === nextCategory
    );

    if (!blueprintsByBlueprintId[id]) {
      fetchAndCache(id);
    }

    dispatch(selectBlueprint(id));
    setCategoryFilter(nextCategory);
  }

  return {
    blueprints,
    filteredBlueprints,
    categories,
    fetchAndCache,
    currentBlueprintData,
    isLoading,
    categoryFilter,
    setCategoryFilter,
    handleCategoryChange,
  };
}

export default useWizardBlueprints;
