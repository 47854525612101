import React, { useEffect, useRef } from 'react';
import { func, string, bool, number, object } from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { NativeTypes, getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import SectionInput from './index';
import { itemTypes } from '../../../constants';
import useImageUpload from '../../../hooks/useImageUpload';
import { fileNameToStickerName, generateId } from '../../../util/index';
import { createWarning } from '../../../actions/notifications';
import useLoading, { commonTypes } from '../../../hooks/useLoading';
import useAnalytics from '../../../containers/app/useAnalytics';
import useLocale from '../../../hooks/localization/useLocale';
import { selectCurrentAlbum } from '../../../selectors/albums';

const maxStickerUploads = 15;

function SectionInputWithDnd(props) {
  const {
    sectionId,
    sectionIndex,
    moveSection,
    moveSticker,
    sectionDragEnabled,
    historyAnchor,
    createSticker,
    updateControls,
    unlinkStickerCell,
    stickerCellIdsByStickerId,
  } = props;

  const { t } = useLocale();
  const { createStickerImage } = useImageUpload();
  const { startLoading, stopLoading } = useLoading(commonTypes.uploadingFiles);
  const analytics = useAnalytics();

  const elementRef = useRef(null);

  const dispatch = useDispatch();
  const albumId = useSelector(selectCurrentAlbum);

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemTypes.section,
    item: () => {
      updateControls({ operationActive: true });
      return {
        sectionId,
        sectionIndex,
      };
    },
    end() {
      updateControls({ operationActive: false });
    },
    canDrag() {
      return sectionDragEnabled;
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Drop handler to allow sorting sections and moving stickers between
   * sections via DnD. When dropping a file, the image is uploaded
   * and a sticker created.
   */
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.sticker, itemTypes.section, NativeTypes.FILE],
    async drop(item, monitor) {
      const itemType = monitor.getItemType();

      if (
        monitor.didDrop() || // nested drop target has already handled the event (ie. `StickerInput`)
        !elementRef.current || // ?
        [itemTypes.sticker, NativeTypes.FILE].indexOf(itemType) === -1 // itemTypes.section is handled in `hover`
      ) {
        return;
      }

      /**
       * Moving stickers to another section.
       */
      if (itemType === itemTypes.sticker) {
        if (sectionId !== monitor.getItem().sectionId) {
          const stickerId = item.id;
          const stickerCellId = stickerCellIdsByStickerId[stickerId];

          /**
           * We're mutating the item for performance reasons - see:
           * https://react-dnd.github.io/react-dnd/examples/sortable/simple
           */
          item.sectionId = sectionId; // eslint-disable-line no-param-reassign
          moveSticker(stickerId, sectionId);

          /**
           * We need to unlink the sticker cell belonging to the sticker's
           * previous section if it was placed.
           */
          if (stickerCellId) {
            unlinkStickerCell(stickerCellId);
          }
        }
      }

      /**
       * Creating stickers by dropping files.
       */
      if (itemType === NativeTypes.FILE) {
        const { files } = monitor.getItem();

        if (files.length > maxStickerUploads) {
          dispatch(
            createWarning(
              t('editor.imageUpload.maxImagesError', { max: maxStickerUploads })
            )
          );
          return;
        }

        startLoading();
        for (const file of Array.from(files)) {
          const stickerId = generateId();
          // eslint-disable-next-line no-await-in-loop
          const image = await createStickerImage(file);

          if (!image) {
            return;
          }

          createSticker({
            id: stickerId,
            sectionId,
            name: fileNameToStickerName(file.name),
            image: image.id,
          });

          analytics.track('Sticker Created', {
            id: stickerId,
            fromFile: true,
            drop: true,
            albumId,
          });
          historyAnchor();
        }
        stopLoading();
      }
    },
    hover(item, monitor) {
      const itemType = monitor.getItemType();

      // Return early if the hovered element is a nested drop target (ie. `StickerInput`)
      if (
        !monitor.isOver({ shallow: true }) ||
        itemType !== itemTypes.section
      ) {
        return;
      }

      /**
       * Changing the order of sections.
       */
      const dragId = item.sectionId;
      const dragIndex = item.index;
      const hoverIndex = sectionIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = elementRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (hoverIndex === undefined) {
        return;
      }

      moveSection(dragId, hoverIndex);
      item.index = hoverIndex; // eslint-disable-line no-param-reassign

      historyAnchor();
    },
    collect: el => ({
      isOver:
        el.isOver() &&
        (el.getItemType() === NativeTypes.FILE ||
          el.getItemType() === itemTypes.sticker),
    }),
  });

  if (sectionDragEnabled) {
    drag(drop(elementRef));
  }

  return (
    <SectionInput
      dragRef={elementRef}
      isOver={isOver}
      isDragging={isDragging}
      {...props}
    />
  );
}

SectionInputWithDnd.defaultProps = {
  stickerCellIdsByStickerId: {},
};

SectionInputWithDnd.propTypes = {
  sectionId: string.isRequired,
  sectionIndex: number.isRequired,
  moveSection: func.isRequired,
  moveSticker: func.isRequired,
  sectionDragEnabled: bool.isRequired,
  historyAnchor: func.isRequired,
  createSticker: func.isRequired,
  updateControls: func.isRequired,
  unlinkStickerCell: func.isRequired,
  stickerCellIdsByStickerId: object, // eslint-disable-line react/forbid-prop-types
};

export default SectionInputWithDnd;
