import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
  selectAlbumLoaded,
  selectIsAlbumEditable,
} from '../../selectors/albums';
import useLocale from '../../hooks/localization/useLocale';
import useNotifications from '../../hooks/useNotifications';
import useFirstTime from '../../hooks/useFirstTime';
import useScreenSize from '../../hooks/useScreenSize';

function Notifications() {
  const { t } = useLocale();
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const albumLoaded = useSelector(selectAlbumLoaded);
  const { isMobile } = useScreenSize();
  const {
    params: { key },
  } = useRouteMatch();

  const { createInfo } = useNotifications();

  useFirstTime(() => {
    createInfo(t('notifications.previewMode'), {
      autoHide: false,
      showTimestamp: false,
    });
  }, albumLoaded && !isAlbumEditable);

  useFirstTime(() => {
    createInfo(t('notifications.autoSave'), {
      showTimestamp: false,
    });
  }, albumLoaded && key !== 'onboarding' && isAlbumEditable && !isMobile);

  return null;
}

export default Notifications;
