import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import CommentsList from './CommentsList';
import { selectOrderedComments } from '../../../selectors/workspace';
import ButtonWithTooltip from '../../menu/ButtonWithTooltip';
import useApi from '../../../hooks/useApi';
import { selectAlbumData } from '../../../selectors/albums';
import { createError } from '../../../actions/notifications';
import AdminOnly from '../../generic/AdminOnly';
import { setAlbumData } from '../../../modules/albums';
import useLocale from '../../../hooks/localization/useLocale';

function ReviewPanel() {
  const { t } = useLocale();
  const api = useApi();
  const dispatch = useDispatch();

  const comments = useSelector(selectOrderedComments);
  const albumData = useSelector(selectAlbumData);
  const { lastOrderId, locked } = albumData;

  const tooltip = t('editor.sidebar.review.printApprovalTooltip');

  async function handleSubmitReview() {
    try {
      await api.post(`/orders/${lastOrderId}/submit_review`);
      dispatch(setAlbumData({ ...albumData, locked: false }));
    } catch (err) {
      createError(err.toString());
    }
  }

  async function handlePrintApproval() {
    try {
      await api.post(`/orders/${lastOrderId}/print_approval`);
      dispatch(setAlbumData({ ...albumData, locked: true }));
    } catch (err) {
      createError(err.toString());
    }
  }

  return (
    <div className="review-panel d-flex flex-column px-2 py-3 flex-grow-1">
      <CommentsList comments={comments} />
      <div className="button-container d-flex pt-2 mt-auto">
        <AdminOnly>
          <Button
            size="sm"
            variant="outline-primary"
            className="mr-2 qa-submit-review-btn"
            onClick={handleSubmitReview}
            disabled={!locked}
          >
            {t('editor.sidebar.review.sendFeedback')}
          </Button>
        </AdminOnly>
        <ButtonWithTooltip
          block
          variant="primary"
          className="qa-print-approval-btn"
          tooltip={tooltip}
          placement="top"
          onClick={handlePrintApproval}
          disabled={locked}
        >
          {t('editor.sidebar.review.printApproval')}
        </ButtonWithTooltip>
      </div>
    </div>
  );
}

export default ReviewPanel;
