import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import OrderContent from './OrderContent';
import AppLayout from '../../../components/ui/AppLayout';
import useLocale from '../../../hooks/localization/useLocale';

function OrderDetails() {
  const { t } = useLocale();

  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">{t('showOrder.headline')}</h1>
          <p>{t('showOrder.subline')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <OrderContent />
        </Col>
      </Row>
    </AppLayout>
  );
}

OrderDetails.propTypes = {};

export default OrderDetails;
